import React, { Component, Fragment } from 'react';
import { loadModules } from 'esri-loader';
import { connect } from 'react-redux';
import { _mapViewAvailable } from '../../utils/map';
import { GIS_Base_Functions, _user_auth } from '../services/gis_service';

import Slider from 'calcite-react/Slider';

import './custom-util-toggler.css';

// , border:`1px solid ${this.props.color}`

class CityUtilityCustomToggler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized: false,
      accessLevel: 0,
      latestImagery: this.props.config.latestImagery,
      Water: 'Water',  //HARDCODED
      Storm: 'Storm',  //HARDCODED
      Sewer: 'Sanitary',  //HARDCODED
      Lights: 'Lights',  //HARDCODED
      basemapOpacity: 100,
    };
    this.updateSliderValue = this.updateSliderValue.bind(this);
  }

  updateSliderValue(event) {
    this.setState({ basemapOpacity: parseInt(event.target.value, 10) });
    this.__updateBasemapOpacity();
  }

  componentDidMount() {
    const __isAuth = _user_auth.isAuthorized();
    const { accessLevel } = _user_auth.GetAuthUser();
    this.setState({ isAuthorized: __isAuth, accessLevel });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  __updateBasemapOpacity = () => {
    const mapview = _mapViewAvailable();
    mapview.map.basemap.baseLayers.items[0].opacity =
      this.state.basemapOpacity / 100;
    //console.log(mapview.map);
  };

  __toggleLatestImagery = (e) => {
    switch (e.target.checked) {
      case true:
        this.setAppBasemap('imagery');
        break;
      default:
        const mapview = _mapViewAvailable();
        GIS_Base_Functions.__getBasemap(this.state.latestImagery.defaultStreetBasemapServiceURL).then((streetBasemap) => {
          mapview.map.basemap = streetBasemap;
        });
        break;
    }
    this.__addDefaultBaseLayer(e.target.checked);
  };

  setAppBasemap = (basemapType) => {
    const { serviceURL, defaultStreetBasemapServiceURL } =
      this.state.latestImagery;
    const mapview = _mapViewAvailable();

    loadModules(['esri/Basemap', 'esri/layers/TileLayer']).then(
      ([Basemap, TileLayer]) => {
        const latestBasemap = new Basemap({
          baseLayers: [
            new TileLayer({
              url:
                basemapType === 'imagery'
                  ? serviceURL
                  : defaultStreetBasemapServiceURL,
              copyright: 'City of Reynoldsburg, Ohio',
            }),
          ],
          title: 'Latest Basemap',
          id: 'appbasemap',
        });
        mapview.map.basemap = latestBasemap;
      }
    );
  };

  __addDefaultBaseLayer = (checked) => {
    setTimeout(() => {
      GIS_Base_Functions.__addDefaultBaseLayer(checked);
    }, 10);
  };

  __toggleUtility(e, layerName) {
    const mapview = _mapViewAvailable();
    const utilityLayer = mapview.map.layers.items.find(
      (l) => l.id === '1703a0495d2-util'   //HARDCODED
    );
    if (!utilityLayer.visible) {
      utilityLayer.visible = e.target.checked; // layer root
    }
    utilityLayer.sublayers.items.map((l) => {
      if (l.title.toUpperCase() === layerName.toUpperCase()) {
        l.visible = e.target.checked; // layer root
        const _recursiveFunc = (layers) => {
          layers.map((ly) => {
            if (ly.sublayers) {
              _recursiveFunc(ly.sublayers.items);
            } else {
              ly.visible = e.target.checked;
            }
          });
        };
        _recursiveFunc(l.sublayers.items);
      } // before
      else {
        // after
        const _recursiveFuncUncheck = (layers) => {
          layers.map((ly) => {
            if (ly.sublayers) {
              _recursiveFuncUncheck(ly.sublayers.items);
            } else {
              ly.visible = false;
            }
          });
        };
        switch (l.title) {
          case 'Atlas Grid':
            break;
          case 'Sanitary':  //hardcoded
            const is_sewer_checked =
              document.getElementById('sanitary').checked;  //hardcoded
            if (!is_sewer_checked) {
              l.visible = false;
              _recursiveFuncUncheck(l.sublayers.items);
            }
            break;
          case 'Storm':  //hardcoded
            const is_storm_checked = document.getElementById('storm').checked;  //hardcoded
            if (!is_storm_checked) {
              l.visible = false;
              _recursiveFuncUncheck(l.sublayers.items);
            }
            break;
          case 'Water':  //hardcoded
            const is_water_checked = document.getElementById('water').checked;  //hardcoded
            if (!is_water_checked) {
              l.visible = false;
              _recursiveFuncUncheck(l.sublayers.items);
            }

            break;
            case 'Lights':  //hardcoded
              const is_lights_checked = document.getElementById('lights').checked;  //hardcoded
              if (!is_lights_checked) {
                l.visible = false;
                _recursiveFuncUncheck(l.sublayers.items);
              }  
              break;
            default:
              break;
          }
      }
    });
  }

  __renderTogglerUI() {
    const { title } = this.state.latestImagery;
    if (this.state.isAuthorized && this.state.accessLevel > 1) {
      return (
        <div
          className="utility-toggler-wrapper"
          id="utility-toggler-wrapper"
          style={{ color: this.props.color }}
        >
          <ul
            className="categorychecklist form-no-clear"
            style={{ padding: '0 10px' }}
          >
            <li className="utility-category">
              <label className="selectit">
                <input
                  id="water"  //hardcoded
                  value="blog"
                  type="checkbox"
                  onChange={(e) => this.__toggleUtility(e, this.state.Water)}
                  className="js-wpv-filter-trigger"
                  name="utility-category"
                />
                Water
              </label>
            </li>
            <li className="utility-category">
              <label className="selectit">
                <input
                  id="storm"  //hardcoded
                  value="blog"
                  type="checkbox"
                  onChange={(e) => this.__toggleUtility(e, this.state.Storm)}
                  className="js-wpv-filter-trigger"
                  name="utility-category"
                />
                Storm
              </label>
            </li>
            <li className="utility-category">
              <label className="selectit">
                <input
                  id="sanitary"  //hardcoded
                  value="blog"
                  type="checkbox"
                  onChange={(e) => this.__toggleUtility(e, this.state.Sewer)}
                  className="js-wpv-filter-trigger"
                  name="utility-category"
                />
                Sanitary
              </label>
            </li>
            <li className="utility-category">
              <label className="selectit">
                <input
                  id="lights"  //hardcoded
                  value="blog"
                  type="checkbox"
                  onChange={(e) => this.__toggleUtility(e, this.state.Lights)}
                  className="js-wpv-filter-trigger"
                  name="utility-category"
                />
                Lights
              </label>
            </li>

            <li className="utility-category">
              <label className="selectit">
                <input
                  value="imagery"
                  type="checkbox"
                  onChange={(e) => this.__toggleLatestImagery(e)}
                  className="js-wpv-filter-trigger"
                  name="utility-category"
                />
                {title}
              </label>
            </li>

            <li className="utility-category">
              <div
                style={{
                  position: 'relative',
                  margin: '0 20px',
                  width: '115px',
                }}
              >
                <div style={{ position: 'absolute', top: '-23px' }}>
                  <Slider
                    value={this.state.basemapOpacity}
                    onChange={this.updateSliderValue}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return <Fragment>{this.__renderTogglerUI()}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
export default connect(mapStateToProps)(CityUtilityCustomToggler);
