import React, { Fragment } from 'react';
import Carousel from 'nuka-carousel';
import './attachments-carousel.css';

const Attachments = (props) => {
  const __request__proxy__url = `${props.proxyUrl}?`;
  const imgURL =
//    __request__proxy__url +   // attachment proxy
    props.attachmentsBaseUrl +
    '/' +
    props.ObjectID +
    '/attachments/';

  const slides = props.attachments.map((img, index) => (
    <a key={index} href={imgURL + img.id} target="_blank" title="Open image">
      <img
        src={imgURL + img.id}
        alt={img.name}
        /* onClick={() => handleImageClick()} */
        /* style={{
            height: heightMode === "current" ? 100 * (index + 1) : 400
          }} */
        width="100%"
      />
    </a>
  ));

  return (
    <Fragment>
      <Carousel autoplay={true} autoplayInterval={3000}>
        {slides}
      </Carousel>
    </Fragment>
  );
};

export default Attachments;
