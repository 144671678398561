import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import MapIdentifyWithNoResult from './mapIdentifyWithNoResult';
import MapIdentifyWithResults from './mapIdentifyWithResults';
import IdentifySkeleton from './IdentifySkeleton';

const IDENTIFY_SETTINGS = '__gisIdentify__Settings';
const __IDENTIFYSETTINGS = '__identifySettings';

var isIdentifying = true;

const requestOptions = {
  query: {
    f: 'json',
  },
  responseType: 'json',
};

class MapIdentifyLoader extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      isIdentifying: true,
      selectedLayers: [],
      isHideNullOrEmptyFields: true,
      identifyLayers: [],
      userSavedLayers: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const __selected__layers__array = [];
    const _identifySettings = localStorage.getItem(__IDENTIFYSETTINGS);
    if (_identifySettings) {
      const __settings = JSON.parse(_identifySettings);
      if (__settings.Layers.length > 0) {
        __settings.Layers.map((savedLayer) => {
          this.props.appOptions.applicationLayers.map((configLayer) => {
            if (configLayer.title === savedLayer) {
              __selected__layers__array.push(
                this.props.view.map.findLayerById(configLayer.id)
              );
            }
          });
        });

        if (this._isMounted) {
          this.setState({
            userSavedLayers: __selected__layers__array,
          });
        }
      }
    }

    this.___identifyLayers();
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    };
  }
  __getIdentifyParams() {
    const _promise = new Promise((resolve, reject) => {
      loadModules([
        'esri/tasks/IdentifyTask',
        'esri/rest/support/IdentifyParameters',  //tasks to rest DMM 09/22/2022
        'dojo/_base/array',
        'dojo/promise/all',
      ]).then(([IdentifyTask, IdentifyParameters, arrayUtils, all]) => {
        // perform identify
        const _identifyParams = [];
        const identifiedLayers = [];

        const mapLayers =
          this.state.userSavedLayers.length > 0
            ? this.state.userSavedLayers
            : this.props.view.map.layers.items;
        mapLayers.map((layer) => {
          switch (layer.id) {
            case 'userGraphics':
            case 'publicNotification':
            case 'streetViewLocationGlayer':
            case '1703a318545-contours':    //HARDCODED
            case 'bufferGraphicsLayer':
              break;
            default:
              let IDs = [];
              if (layer.url && layer.visible) {
                //console.log(layer);
                //const _layerURL = layer.url.indexOf('FeatureServer') > -1 ? layer.url.replace('FeatureServer', 'MapServer') : layer.url;
                //identifiedLayers.push({url: _layerURL, name: layer.title, id: layer.id});
                identifiedLayers.push({
                  url: layer.url,
                  name: layer.title,
                  id: layer.id,
                });
                const _traverseLayerItems = (items) => {
                  if (items.length > 0) {
                    items.map((l) => {
                      if (l.sublayers) {
                        // layer has sublayers
                        _traverseLayerItems(l.sublayers.items);
                      } else {
                        // reached the end
                        if (l.visible) {
                          switch (l.title.toUpperCase()) {
                            case 'FLOW ARROW': // remove Flow Arrow from being identified
                              break;
                            default:
                              IDs.push(l.id);
                              break;
                          }
                        }
                      }
                    });
                  }
                };

                _traverseLayerItems(layer.sublayers.items);

                const idParams = new IdentifyParameters();
                idParams.tolerance = this.props.appOptions.mapIdentifyTolerance; // 3;
                idParams.layerIds = IDs;
                idParams.width = this.props.view.width;
                idParams.height = this.props.view.height;
                idParams.geometry = this.props.location;
                idParams.mapExtent = this.props.view.extent;
                idParams.returnGeometry = true;
                idParams.returnFieldName = false;
                idParams.spatialReference = this.props.view.spatialReference;
                idParams.layerOption = 'visible'; // Possible Values:"top"|"visible"|"all"
                _identifyParams.push(idParams);
              }
              break;
          }
        });
        const params = {
          identifyLayers: identifiedLayers,
          identifyParams: _identifyParams,
        };
        resolve(params);
      });
    });
    return _promise;
  }

  featureHasAttachmentEnabled(sourceLayer) {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      return esriRequest(`${sourceLayer}?f=pjson`).then((res) => {
        return res.data.hasAttachments;
      });
    });
  }

  __queryAttachments(featureLayerUrl, ObjectID) {
    return loadModules(['esri/request']).then(([esriRequest]) => {
      const _queryAttachmentsURL = `${featureLayerUrl}/${ObjectID}/attachments`;  //attachments
      return esriRequest(_queryAttachmentsURL, requestOptions);
    });
  }

  __getAttachments(featureLayerUrl, ObjectID) {
    const _promise = new Promise((resolve, reject) => {
      this.featureHasAttachmentEnabled(featureLayerUrl)
        .then((isAttachmentEnabled) => {
          // console.log("Has attachments :: ",isAttachmentEnabled);
          if (isAttachmentEnabled) {
            const _url = featureLayerUrl.replace('MapServer', 'FeatureServer');
            this.__queryAttachments(_url, ObjectID)
              .then((res) => {
                // console.log(res);
                let ___attachments = [];
//                if (res.data.attachmentGroups.length > 0) {
                  if (res.data.attachmentInfos.length > 0) {
                    ___attachments =
                      res.data.attachmentInfos;
                  }
//                }
                resolve(___attachments);
              })
              .catch((err) => {
                console.log(':: Error fetching attachments... :: ');
                reject(err);
              });
          } else {
            const _attachments = [];
            resolve(_attachments);
          }
        })
        .catch((error) => {
          console.log(':: Error checking attachments... :: ');
          reject(error);
        });
    });

    return _promise;
  }

  ___identifyLayers() {
    loadModules([
      'esri/tasks/IdentifyTask',
      'esri/rest/support/IdentifyParameters',  //tasks to rest DMM 09/22/2022
      'dojo/_base/array',
      'dojo/promise/all',
    ]).then(([IdentifyTask, IdentifyParameters, arrayUtils, all]) => {
      // perform identify
      this.__getIdentifyParams()
        .then((params) => {
          const IdentifyTaskList = arrayUtils.map(
            params.identifyLayers,
            (layer, index) => {
              const task = new IdentifyTask(layer.url);
              return task.execute(params.identifyParams[index]);
            }
          );
          all(IdentifyTaskList).then((res) => {
            const allResults = [];
            let AllResultsWithSourceLayers = [];

            for (let index = 0; index < res.length; index++) {
              const _results = res[index].results;
              if (_results.length > 0) {
                allResults.push({
                  layer: params.identifyLayers[index],
                  results: res[index].results,
                });
              }
            }

            for (let r = 0; r < allResults.length; r++) {
              const res1 = allResults[r];
              if (res1.results.length > 0) {
                res1.results.map((f) => {
                  f.feature.attributes.sourceLayer = `${res1.layer.url}/${f.layerId}`;
                  AllResultsWithSourceLayers.push(f);
                });
              }
            }
            //console.log(__features);
            if (AllResultsWithSourceLayers.length > 0) {
              // set result to view popup
              const resultFeatures = () => {
                const promises = AllResultsWithSourceLayers.map(
                  async (result) => {
                    const feature = result.feature;
                    feature.attributes.layerName = result.layerName;
                    // query attachment here
                    let ObjectID =
                      feature.attributes.OBJECTID ||
                      feature.attributes['ObjectID'] ||
                      feature.attributes['Object ID'];
                    feature.attributes.attachments =
                      await this.__getAttachments(
                        feature.attributes.sourceLayer,
                        ObjectID
                      );

                    return feature;
                  }
                );
                return Promise.all(promises);
              };

              resultFeatures().then((__features) => {
                // reverse results to order as point, line, polygon
                __features = __features.reverse();
                if (this._isMounted) {
                  this.props.view.popup.features = __features;
                  ReactDOM.render(
                    <MapIdentifyWithResults
                      view={this.props.view}
                      appOptions={this.props.appOptions}
                      location={this.props.location}
                    />,
                    document.getElementById('right-bar-wrapper')
                  );
                }
              });
            } else {
              // no result found
              ReactDOM.render(
                <MapIdentifyWithNoResult />,
                document.getElementById('right-bar-wrapper')
              );
            }
            document.getElementById('map-view-container').style.cursor = 'help';
          });
        })
        .catch((err) => console.log(err));
    });
  }

  render() {
    return (
      <Fragment>
        <IdentifySkeleton />
      </Fragment>
    );
  }
}

export default MapIdentifyLoader;
