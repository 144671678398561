// React imports
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { mapLoaded } from '../redux/reducers/map';
import { startAuth, logout } from '../redux/reducers/auth';

// Component imports
import TopNav from 'calcite-react/TopNav';
import TopNavBrand from 'calcite-react/TopNav/TopNavBrand';
import TopNavTitle from 'calcite-react/TopNav/TopNavTitle';
import TopNavList from 'calcite-react/TopNav/TopNavList';
//import TopNavLink from 'calcite-react/TopNav/TopNavLink';

import LeftNav from './navigation/LeftNav';
import RightNav from './navigation/RightNav';
//import { FooterAerialImagery } from './navigation/FooterAerialImagery';

import Map from './esri/map/Map';
import LoadScreen from './LoadScreen';
import UserAccount from './UserAccount';
//import logo from "../styles/images/Esri-React-Logo.svg";
//import logo from '../styles/images/webgis4logo.png';

// Styled Components
import styled from 'styled-components';
//import { $CombinedState } from 'redux';

import Draggable from 'react-draggable';

import { _mapViewAvailable } from '../utils/map';
import GStreetViewWrapper from './GStreetViewWrapper';
//import EditModal from './reports/EditModal';
import CityUtilityCustomToggler from './togglers/CityUtilityCustomToggler';
import { setgraphics } from '../redux/reducers/graphics';

//import '../styles/draggable-report.css';
//import logo from "../styles/images/webgis4logo.png";

const USER_THEME_KEY = 'user-local-theme';

var headerBackgroundColor = '#efe9e0';
var headerTitleTextColor = '#000000';
//var headerNavLinksColor = '#808080';

var controlledPosition = {
  x: 0,
  y: 0,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const MapWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;
`;

const Logo = styled(TopNavBrand)`
  justify-content: center;
  & img {
    height: 60px;
  }
`;

const Nav = styled(TopNav)`
  && {
    z-index: 5;
  }
`;

const NavList = styled(TopNavList)`
  text-align: left;
`;

const DRAWINGS_KEY = 'arcgis-local-drawings';

// Component definition
const Main = (props) => {
  const [state, setState] = useState({
    deltaPosition: {
      x: 0,
      y: 0,
    },
    isMapLoading: false,
  });
  const auth = useSelector((state) => state.auth);
  const config = useSelector((state) => state.config);
  //console.log(config);
//  const userGraphics = useSelector((state) => state.graphics.value);

  const _userGraphics = () => {
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const existingGraphics = localStorage.getItem(DRAWINGS_KEY);
      if (existingGraphics) {
        const _userSavedGraphics = JSON.parse(existingGraphics);
        if (_userSavedGraphics.length > 0) {
          let _graphics_array = [];
          _userSavedGraphics.map((g) => {
            const __gr = Graphic.fromJSON(g);
            _graphics_array.push(__gr);
          });
          dispatch(setgraphics(_graphics_array));
        }
      }
    });
  };

  useEffect(() => {
    _userGraphics();
  }, []);

  const userTheme = localStorage.getItem(USER_THEME_KEY);
  if (userTheme) {
    const _theme = JSON.parse(userTheme);
    headerBackgroundColor = _theme.headerBackgroundColor;
    headerTitleTextColor = _theme.headerTitleTextColor;
//    headerNavLinksColor = _theme.headerNavLinksColor;
  }

  //const appLogo = '../styles/images/webgis4logo.png'; // config.appLogo; //from

  const isMapLoaded = useSelector((state) => state.map.loaded);
  const dispatch = useDispatch();

  // Sign in button click event
  const signIn = () => {
    const { clientId, sessionId, popup } = config;
    dispatch(
      startAuth({
        clientId,
        sessionId,
        popup,
        signInRequest: true,
      })
    );
  };

  // Sign out button click event
  const signOut = () => {
    dispatch(logout(config.sessionId));
  };

  const __toggleImagry = () => {
    const __panel = document.getElementById('imagery-panel');
    if (__panel.classList.contains('hidden')) {
      __panel.classList.remove('hidden');
    } else {
      __panel.classList.add('hidden');
    }
  };

//  const __onDragStart = () => {
//    //console.log('starting drag...');
//  };

  const __onDrag = (e, position) => {
    controlledPosition.x = position.x;
    controlledPosition.y = position.y;
  };
  const onGStreetViewClosed = () => {
    const view = _mapViewAvailable();
    //view.ui.empty("top-right");
    view.ui.remove('streetViewWidget');
    // reset the pegman position
    controlledPosition.x = 0;
    controlledPosition.y = 0;
    document.getElementById('googleStreetViewBtn').style.transform =
      'translate(0, 0)';
  };

  const __onDragEnd = (res) => {
    const view = _mapViewAvailable();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    var _screenLocation = isMobile
      ? { x: res.changedTouches[0].clientX, y: res.changedTouches[0].clientY }
      : { x: res.x - res.offsetX, y: res.y - res.offsetY };
    const _locationPoint = view.toMap(_screenLocation);

    loadModules([
      'esri/geometry/support/webMercatorUtils',
      'esri/geometry/SpatialReference',
      'esri/tasks/GeometryService',
      'esri/rest/support/ProjectParameters',  //tasks to rest DMM 9/22/2022
    ]).then(
      ([
        webMercatorUtils,
        SpatialReference,
        GeometryService,
        ProjectParameters,
      ]) => {
        var geomSer = new GeometryService(config.geometryService);
        var params = new ProjectParameters({
          geometries: [_locationPoint],
          outSpatialReference: new SpatialReference({
            wkid: 102100,
            wkt: 'toJSON()',
          }),
        });

        geomSer.project(params).then((res) => {
          const _streetViewPanoramaOptions = {
            position: { lat: res[0].latitude, lng: res[0].longitude },
            pov: { heading: 0, pitch: 0 },
            zoom: 1,
          };

          if (isMobile) {
            const redirectUrl = `https://maps.google.com/maps?q=&layer=c&cbll=${res[0].latitude},${res[0].longitude}&cbp=11,0,0,0,0`;
            window.open(redirectUrl, '_blank');
          } else {
            const _streetViewDiv = document.getElementById('streetViewWidget');
            if (_streetViewDiv) {
              _streetViewDiv.remove();
            }
            var streetViewWidget = document.createElement('div');
            streetViewWidget.id = 'streetViewWidget';
            streetViewWidget.className =
              'esri-widget esri-component street-view-widget-wrapper';
            const view = _mapViewAvailable();
            view.ui.add(streetViewWidget, 'top-right');
            ReactDOM.render(
              <GStreetViewWrapper
                streetViewPanoramaOptions={_streetViewPanoramaOptions}
                onclose={onGStreetViewClosed}
                googleAPIKey={config.googleAPIKey}
              />,
              streetViewWidget
            );
          }
        });
      }
    );
  };

  const __toggleFeaturesIdentifyAction = (e) => {
    const btnIdentify = document.getElementById('mapIdentifyBtn');
    if (btnIdentify.classList.contains('map-identify-active')) {
      btnIdentify.classList.remove('map-identify-active');
      document.getElementById('map-view-container').style.cursor = 'default';
    } else {
      btnIdentify.classList.add('map-identify-active');
      document.getElementById('map-view-container').style.cursor = 'help';
    }
  };

  return (
    <Container>
      <LoadScreen isLoading={!isMapLoaded} />
      <Nav
        className="app-header"
        style={{ backgroundColor: headerBackgroundColor }}
      >
        {/* <Logo href="#" src={`${process.env.PUBLIC_URL}/assets/logo/webgis4logo.png`} /> */}
        <Logo
          href="#"
          src={`${process.env.PUBLIC_URL}/assets/logo/reylogo.png`}
        />
        <TopNavTitle
          href="#"
          className="app-title"
          style={{ color: headerTitleTextColor }}
        >
          GIS v4
        </TopNavTitle>
        <CityUtilityCustomToggler
          mapview={mapLoaded}
          color={headerTitleTextColor}
        />
        <NavList>
          {/* <TopNavLink target="_blank" href={config.documentationUrl} className="app-links" style={{color: headerNavLinksColor}}>
              Docs
            </TopNavLink> */}
        </NavList>
        <a href="https://experience.arcgis.com/experience/4161a2cec8fb43e29122e99095091ade/" className="btn btn-primary">
           Parks Map
        </a>
        <UserAccount
          user={auth.user}
          portal={auth.portal}
          token={auth.token}
          loggedIn={auth.loggedIn}
          signIn={signIn}
          signOut={signOut}
        />
      </Nav>
      <MapWrapper>
        <div className="content">
          <LeftNav mapview={mapLoaded} />
          <main>
            <div id="map-loading-progressbar"></div>
            <Map
              style={{ position: 'absolute' }}
              onMapLoaded={mapLoaded}
              appConfig={config}
            />
            <div
              id="mapIdentifyBtn"
              className="esri-component esri-info esri-widget--button esri-widget"
              title="Identify"
              onClick={(e) => __toggleFeaturesIdentifyAction(e)}
            >
              <span
                aria-hidden="true"
                className="esri-icon esri-icon-notice-round"
              ></span>
            </div>

            <Draggable
              position={controlledPosition}
              handle=".handle"
              onDrag={__onDrag}
              onStop={__onDragEnd}
            >
              <div
                id="googleStreetViewBtn"
                className="esri-component handle"
                title="Google street view. Drag and drop to a street"
              ></div>
            </Draggable>
            <div
              id="mapImageriesBtn"
              className="esri-component esri-widget--button esri-widget"
              onClick={__toggleImagry}
              title="Aerial Imageries"
            ></div>
          </main>
          <RightNav mapview={mapLoaded} />
        </div>
      </MapWrapper>
      {/* <div id="imagery-panel" className="hidden">
        <FooterAerialImagery aerials={config.listImageries} />
      </div> */}
      <div id="footer-alerts-wrapper"></div>
      <div id="parcel-notification" className="hidden"></div>
      {/* <EditModal /> */}
    </Container>
  );
};
export default Main;
