import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import Button, { ButtonGroup } from 'calcite-react/Button';

import SearchIcon from 'calcite-ui-icons-react/SearchIcon';
import LayerHideIcon from 'calcite-ui-icons-react/LayerHideIcon';
import MeasureIcon from 'calcite-ui-icons-react/MeasureIcon';
import PointIcon from 'calcite-ui-icons-react/PointIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import AddInNewIcon from 'calcite-ui-icons-react/AddInNewIcon';
import HideEmptyIcon from 'calcite-ui-icons-react/HideEmptyIcon';
import MagnifyingGlassPlusIcon from 'calcite-ui-icons-react/MagnifyingGlassPlusIcon';
import RecentIcon from 'calcite-ui-icons-react/RecentIcon';
import ChevronLeftIcon from 'calcite-ui-icons-react/ChevronLeftIcon';
import ChevronRightIcon from 'calcite-ui-icons-react/ChevronRightIcon';

import Attachments from './Attachments';
import { RenderAttributes } from './RenderAttributes';

import swal from 'sweetalert';
import { mapIdentifyWithNoResult } from './mapIdentifyWithNoResult';
import LayerFieldSettings from './LayerFieldSettings';
import HydrantFlowTests from '../../reports/HydrantFlowTests';
import WorkHistory from '../../reports/WorkHistory';
import ValveExercise from '../../reports/ValveExercise';
import { _flow_rate_updater } from '../../services/gis_service';
import BufferSelectParcels from '../drawings/BufferSelectParcels';

const __IDENTIFYSETTINGS = '__identifySettings';
var _layerFields = [];

const __stylePanelHeader = {
  position: 'relative',
  height: '45px',
  backgroundColor: '#f5f5f5',
  padding: '15px',
  textAlign: 'left',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderBottom: '1px solid #ddd',
};
const __styleClosePanelBtn = {
  position: 'absolute',
  width: '45px',
  top: '0',
  right: '0',
  height: '45px',
  fontWeight: '100',
  textAlign: 'center',
  cursor: 'pointer',
};
const __styleCloseBtnText = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
};

const __styleLayerSettingsBtn = {
  position: 'absolute',
  width: '45px',
  top: '0',
  right: '50px',
  height: '45px',
  fontWeight: '100',
  textAlign: 'center',
  cursor: 'pointer',
};
const _identifyFooterToolbar = {
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: '#f5f5f5',
  padding: '10px',
  textAlign: 'left',
  borderTop: '1px solid #ddd',
};

const _searchDistanceWrapper = {
  padding: '0 10px',
  maprgin: '0',
  backgroundColor: '#d4d4d4',
  textAlign: 'left',
  paddingTop: '5px',
  paddingRight: '25px',
};

class MapIdentifyWithResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layerName: this.props.view.popup.selectedFeature.attributes.layerName,
      featureCount: this.props.view.popup.featureCount,
      feature: this.props.view.popup.selectedFeature,
      selectedIndex: this.props.view.popup.selectedFeatureIndex,
      WORKORDERURL: '#',
      showNullOrEmptyFields: false,
      isUtility: false,
      location: this.props.location,
    };
  }

  componentDidMount() {
    this._setLocationCoordinates();
    this._highlightSelectedFeature();
    var Identify = localStorage.getItem(__IDENTIFYSETTINGS);
    if (Identify) {
      var _IdentifyObj = JSON.parse(Identify);
      this.setState({
        showNullOrEmptyFields: _IdentifyObj.IsNullOrEmptyFields,
      });
    }
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentDidUpdate() {
    this.renderAttachments();
    this.renderAttributes();
    this._setReportIssue_Link();
  }

  _setLocationCoordinates = () => {
    if (this.state.location) {
      loadModules([
        'esri/geometry/SpatialReference',
        'esri/tasks/GeometryService',
        'esri/rest/support/ProjectParameters',  //tasks to rest DMM 9/22/2022
      ]).then(([SpatialReference, GeometryService, ProjectParameters]) => {
        var geomSer = new GeometryService(
          this.props.appOptions.geometryService
        );
        var params = new ProjectParameters({
          geometries: [this.state.location],
          outSpatialReference: new SpatialReference({
            wkid: 102100,
            wkt: 'toJSON()',
          }),
        });

        geomSer.project(params).then((res) => {
          const location = this.state.location; //{ lat: res[0].latitude, lng: res[0].longitude };
          location.latitude = res[0].latitude;
          location.longitude = res[0].longitude;
          this.setState({
            location: location,
          });
        });
      });
    }
  };
  _checkUtilityLayer = () => {
    const __utilityLayer =
      this.props.view.map.findLayerById('1703a0495d2-util');
    if (__utilityLayer) {
      const index = __utilityLayer.allSublayers.items.findIndex(
        (l) => l.title.toUpperCase() === this.state.layerName.toUpperCase()
      );
      setTimeout(() => {
        //console.log(index);
        this.setState({ isUtility: index > -1 ? true : false });
      }, 30);
    }
  };

  _setReportIssue_Link = () => {
    setTimeout(() => {
      if (this.state.isUtility) {
        const FACILITYID =
          this.state.feature.attributes.FACILITYID ||
          this.state.feature.attributes.FacilityID;
        if (this.state.location) {
          this.setState({
            WORKORDERURL: `https://src.mansfieldcity.com/pg/mgmt/quick.asp?fc=${this.state.layerName}&fID=${FACILITYID}&lat=${this.state.location.latitude}&lng=${this.state.location.longitude}&map1=1`,
          });
        }
      }
    }, 20);
  };

  _updateFlowRate = (rate) => {
    //console.log(rate);
    let f = this.props.view.popup.selectedFeature;
    if (f) {
      Object.keys(f.attributes).map((k) => {
        switch (k.toUpperCase()) {
          case 'FLOW RATE (GPM)':
            f.attributes[k] = `${rate}`;
            break;
        }
      });

      this.setState({
        feature: f,
      });
    }
  };

  _updateValveAttributesUI = (featureUpdatedValues) => {
    let f = this.props.view.popup.selectedFeature;
    if (f) {
      Object.keys(f.attributes).map((k) => {
        switch (k.toUpperCase()) {
          case 'TURNS TO CLOSE':
            f.attributes[k] = `${featureUpdatedValues.turnstoclose}`;
            break;
          case 'OPEN DIRECTION':
            f.attributes[k] = `${featureUpdatedValues.opendirection}`;
            break;
          case 'PROGRAM YEAR':
            f.attributes[k] = `${featureUpdatedValues.programyear}`;
            break;
          case 'LOCATION':
            f.attributes[k] = `${featureUpdatedValues.location}`;
            break;
        }
      });

      this.setState({
        feature: f,
      });
    }
  };

  _highlightSelectedFeature() {
    this._checkUtilityLayer();
    this.props.view.graphics.removeAll();
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const highlightGr = new Graphic({
        geometry: this.state.feature.geometry,
        symbol: this._highlightSymbol(this.state.feature.geometry.type),
      });
      this.props.view.graphics.add(highlightGr);
    });
  }

  _highlightSymbol(geometryType) {
    let _symbol;
    switch (geometryType.toLowerCase()) {
      case 'point':
        _symbol = {
          type: 'simple-marker',
          style: 'square',
          outline: { width: 2, color: [0, 197, 255, 1] },
          size: 20,
          color: [0, 169, 230, 0],
        };
        break;
      case 'polyline':
        _symbol = {
          type: 'simple-line',
          style: 'long-dash-dot',
          cap: 'round',
          join: 'bevel',
          width: 6.25,
          color: [0, 197, 255, 0.69],
        };
        break;
      default:
        // polygon as default
        _symbol = {
          type: 'simple-fill',
          outline: { width: 2, color: [0, 169, 230, 0.65] },
          color: [0, 169, 230, 0.25],
        };
        break;
    }
    return _symbol;
  }

  __goToFieldSettings = () => {
    ReactDOM.render(
      <LayerFieldSettings
        view={this.props.view}
        appOptions={this.props.appOptions}
        attr={this.state.feature.attributes}
        layerName={this.state.layerName}
        location={this.props.location}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };

  __goToWorkHistory = () => {
    ReactDOM.render(
      <WorkHistory
        view={this.props.view}
        appOptions={this.props.appOptions}
        attr={this.state.feature.attributes}
        layerName={this.state.layerName}
        location={this.state.location}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };

  populateField = (fieldName) => {
    _layerFields.push(fieldName);
  };
  renderNext = () => {
    this.props.view.popup.next();
    this.setState({
      layerName: this.props.view.popup.selectedFeature.attributes.layerName,
      feature: this.props.view.popup.selectedFeature,
      selectedIndex: this.props.view.popup.selectedFeatureIndex,
    });
    this.renderAttachments();
    this.renderAttributes();
    this._highlightSelectedFeature();
  };
  renderPrevious = () => {
    this.props.view.popup.previous();
    this.setState({
      layerName: this.props.view.popup.selectedFeature.attributes.layerName,
      feature: this.props.view.popup.selectedFeature,
      selectedIndex: this.props.view.popup.selectedFeatureIndex,
    });
    this.renderAttachments();
    this.renderAttributes();
    this._highlightSelectedFeature();
  };

  zoomTo = () => {
    switch (this.state.feature.geometry.type) {
      case 'point':
        this.props.view.goTo({
          target: this.state.feature.geometry,
          zoom: 20,
        });
        break;
      default:
        this.props.view.goTo({
          target: this.state.feature.geometry,
        });
        break;
    }
  };
  closeWidgetIdentifyWindow = () => {
    this.props.view.graphics.removeAll();
    document.getElementById('right-bar-wrapper').classList.add('hidden');
    /* const btnIdentify = document.getElementById('mapIdentifyBtn');
    if (btnIdentify.classList.contains('map-identify-active')) {
      btnIdentify.classList.remove('map-identify-active');
      document.getElementById('map-view-container').style.cursor = 'default';
    } */
  };

  renderFlowTest = () => {
    const flowTestWrapper = document.getElementById('center-draggable-wrapper');
    if (flowTestWrapper) {
      //flowTestWrapper.remove();
      this.props.view.ui.remove('center-draggable-wrapper');
    }

    const reportDiv = document.createElement('div');
    reportDiv.setAttribute('id', 'center-draggable-wrapper');
    //reportDiv.setAttribute("iconClass", "utility-toggler-div");
    //view.ui.empty("top-right");
    this.props.view.ui.add(reportDiv, {
      position: 'top-right',
      index: 0,
    });
    setTimeout(() => {
      ReactDOM.render(
        <HydrantFlowTests
          mapview={this.props.view}
          feature={this.state.feature}
          config={this.props.appOptions}
          _updateFlowRate={this._updateFlowRate}
        />,
        document.getElementById('center-draggable-wrapper')
      );
    }, 50);
  };
  renderValveExercising = () => {
    const flowTestWrapper = document.getElementById('center-draggable-wrapper');
    if (flowTestWrapper) {
      this.props.view.ui.remove('center-draggable-wrapper');
    }

    const reportDiv = document.createElement('div');
    reportDiv.setAttribute('id', 'center-draggable-wrapper');
    this.props.view.ui.add(reportDiv, {
      position: 'top-right',
      index: 0,
    });
    setTimeout(() => {
      ReactDOM.render(
        <ValveExercise
          mapview={this.props.view}
          feature={this.state.feature}
          config={this.props.appOptions}
          _updateValveAttributesUI={this._updateValveAttributesUI}
        />,
        document.getElementById('center-draggable-wrapper')
      );
    }, 50);
  };

  renderAttributes() {
    const attr = this.state.feature.attributes;
    return Object.keys(attr).map((key) => {
      return (
        <RenderAttributes
          key={key}
          fieldName={key}
          fieldValue={attr[key]}
          showNullOrEmptyFields={this.state.showNullOrEmptyFields}
          layerName={this.state.layerName}
          populateField={this.populateField}
          appOptions={this.props.appOptions}
        />
      );
    });
  }

  changeActiveStatus = () => {
    swal({
      text: `You are about to change the status of this ${this.state.layerName}. is this correct?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        let feature = this.state.feature;
        const { attributes } = feature;
        var obj = {},
          status;

        switch (this.state.layerName.toUpperCase()) {
          case this.props.appOptions.managedFlayerConfig.hydrantLayerName.toUpperCase():
            const oldHStatus =
              attributes.ACTIVEINACTIVESTATUS ||
              attributes['Active/Inactive Status'];
            status = oldHStatus === 'Active' ? 'Inactive' : 'Active';
            obj = {
              layerName: 'hydrant',
              facilityid: attributes.FACILITYID || attributes.FacilityID,
              status: status,
              layerUrl: this.props.appOptions.managedFlayerConfig.hydrantFlayer,
            };
            break;
          case this.props.appOptions.managedFlayerConfig.valveLayerName.toUpperCase():
            const oldVStatus =
              attributes.OPERABLE_STATUS || attributes['Operable Status'];
            status = oldVStatus === 'Yes' ? 'No' : 'Yes';
            obj = {
              layerName: 'system valve',
              facilityid: attributes.FACILITYID || attributes.FacilityID,
              status: status,
              layerUrl: this.props.appOptions.managedFlayerConfig.valveFlayer,
            };
            break;
        }
        _flow_rate_updater
          .changeActiveStatus(obj, this.props.appOptions)
          .then((res) => {
            if (res) {
              if (res.updateFeatureResults.length > 0) {
                Object.keys(feature.attributes).forEach((name) => {
                  switch (name.toUpperCase()) {
                    case 'ACTIVE/INACTIVE STATUS':
                      feature.attributes[name] = status;
                      break;
                    case 'OPERABLE STATUS':
                      feature.attributes[name] = status;
                      break;
                  }
                });
                this.setState({ feature: feature });
                swal('Status changed successfully');
              } else {
                swal(
                  'Enable to update status at this time. Please try again later'
                );
              }
            }
          });
      }
    });
  };

  renderAttachments() {
    const ObjectID =
      this.state.feature.attributes.OBJECTID ||
      this.state.feature.attributes['ObjectID'] ||
      this.state.feature.attributes['Object ID'];
    const attachments = this.state.feature.attributes.attachments;
    var sourceLayer = this.state.feature.attributes.sourceLayer;
    const attachmentsBaseUrl = sourceLayer.replace(
      'MapServer',
      'FeatureServer'
    );

    switch (attachments.length) {
      case 0:
        break;
      case 1:
        const __request__proxy__url = `${this.props.appOptions.proxyUrl}?`;
        return (
          <a
            href={
//              __request__proxy__url +   // attachment proxy
              attachmentsBaseUrl +
              '/' +
              ObjectID +
              '/attachments/' +
              attachments[0].id
            }
            target="_blank"
            title = "Open image"
          >
            <img
              src={
//                __request__proxy__url +   // attachment proxy
                attachmentsBaseUrl +
                '/' +
                ObjectID +
                '/attachments/' +
                attachments[0].id
              }
              width="100%"
              alt={attachments[0].name}
            />
          </a>
        );
        break;
      default:
        return (
          <Attachments
            attachments={attachments}
            attachmentsBaseUrl={attachmentsBaseUrl}
            ObjectID={ObjectID}
            proxyUrl={this.props.appOptions.proxyUrl}
          />
        );
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <div id="identifyResults">
          <div className="panel-header" style={__stylePanelHeader}>
            <span id="left-panel-header-title">{this.state.layerName}</span>
            <div
              id="btnLayerSettings"
              style={__styleLayerSettingsBtn}
              onClick={this.__goToFieldSettings}
            >
              <div style={__styleCloseBtnText} title="Show/Hide fields">
                <HideEmptyIcon size={16} />
              </div>
            </div>
            <div
              id="btnCloseRightPanel"
              style={__styleClosePanelBtn}
              onClick={this.closeWidgetIdentifyWindow}
            >
              <div style={__styleCloseBtnText} title="Close">
                X
              </div>
            </div>
            <div className="features-navigation">
              <span>
                {this.props.view.popup.selectedFeatureIndex + 1}/
                {this.state.featureCount}
              </span>
            </div>
            <div
              className="btn-feature-nav btn-feature-left"
              title="Previous"
              onClick={this.renderPrevious}
              style={{
                visibility:
                  this.state.featureCount > 1 && this.state.selectedIndex > 0
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <ChevronLeftIcon />
            </div>
            <div
              className="btn-feature-nav btn-feature-right"
              title="Next"
              onClick={this.renderNext}
              style={{
                visibility:
                  this.state.featureCount > 1 &&
                  this.state.selectedIndex < this.state.featureCount - 1
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <ChevronRightIcon />
            </div>
          </div>
          <div
            id="layerInfoContentDisplay"
            className="identify-content-scroller"
          >
            <div>
              <div id="layer-attachment-wrapper">
                {this.renderAttachments()}
                {/* <div className="skeleton-qht7bjhjdt3" style={{display: this.state.isLoadingAttachments === true ? 'block' : 'none'}}></div>
                                    <div style={{display: this.state.isLoadingAttachments === false ? 'block' : 'none'}}>
                                        {
                                            this.renderAttachments()
                                        }
                                    </div> */}
              </div>
              <div
                id="layer-content-wrapper"
                style={{ margin: '10px', marginTop: '0' }}
              >
                <table className="table">
                  <tbody>
                    {/* <tr style={{display:this.state.layerName === "Outfall" ? 'table-row':'none'}}>
                                            <td style={{textAlign:'center'}} colSpan="2">
                                                <Button fullWidth green onClick={() => console.log('clicked')}>Inspection Report</Button>
                                            </td>
                                        </tr> */}
                    {/* <tr style={{display:this.state.layerName === "Main" ? 'table-row':'none'}}>
                                            <td style={{textAlign:'center'}} colSpan="2">
                                                <Button fullWidth red className="btn-danger" onClick={() => console.log('clicked')}>Mark as Broken</Button>
                                            </td>
                                        </tr> */}
                    <tr
                      style={{
                        display:
                          this.state.layerName === 'Hydrant' ||
                          this.state.layerName === 'System Valve'
                            ? 'table-row'
                            : 'none',
                      }}
                    >
                      <td style={{ textAlign: 'center' }} colSpan="2">
                        <Button fullWidth red onClick={this.changeActiveStatus}>
                          Change Status
                        </Button>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display:
                          this.state.layerName === 'System Valve'
                            ? 'table-row'
                            : 'none',
                      }}
                    >
                      <td style={{ textAlign: 'center' }} colSpan="2">
                        <Button fullWidth onClick={this.renderValveExercising}>
                          Valve Exercise
                        </Button>
                      </td>
                    </tr>
                    <tr
                      style={{
                        display:
                          this.state.layerName === 'Hydrant'
                            ? 'table-row'
                            : 'none',
                      }}
                    >
                      <td colSpan="2" style={{ textAlign: 'center' }}>
                        <Button fullWidth onClick={this.renderFlowTest}>
                          Flow Tests
                        </Button>
                      </td>
                      {/* <td style={{textAlign:'center'}}>
                                                <Button fullWidth onClick={() => console.log('clicked')}>Hydrant Flushing</Button>
                                            </td> */}
                    </tr>

                    {this.renderAttributes()}
                    <tr
                      style={{
                        display:
                          this.state.layerName === 'Parcels'
                            ? 'table-row'
                            : 'none',
                      }}
                    >
                      <td colSpan="2" style={_searchDistanceWrapper}>
                        <div
                          style={{
                            marginLeft: '8px',
                          }}
                        >
                          Apply a search distance
                        </div>
                        <BufferSelectParcels graphic={this.state.feature} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div id="layerFooterDisplay" style={_identifyFooterToolbar}>
            <ButtonGroup>
              <Button small onClick={this.zoomTo} title="Zoom to">
                <MagnifyingGlassPlusIcon />
              </Button>

{/*              <Button
                small
                red
                style={{
                  visibility: this.state.isUtility ? 'visible' : 'hidden',
                }}
                href={this.state.WORKORDERURL}
                target="_blank"
              >
                Create Work Order
              </Button>
              <Button
                small
                green
                style={{
                  visibility: this.state.isUtility ? 'visible' : 'hidden',
                }}
                onClick={this.__goToWorkHistory}
                title="Work history"
              >
                <RecentIcon />
              </Button>  */}
              
              {/* <Button small onClick={() => console.log('clicked')} title="Edit attributes">
                              <PencilIcon />
                            </Button> */}
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MapIdentifyWithResults;
