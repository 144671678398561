import React, { Component, Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';
import { RenderAttributes } from './RenderAttributes';

var _layerFields = [];
class LayerAttributes extends Component {
    constructor(props){
        super(props)
        this.state = {
            layerName: this.props.layerName,
            attr: this.props.attr,
            layerFields: []
        }
    }

    componentDidMount() {
        _layerFields = [];
        // check layer settings
    }

    

    render() {
        return (
            <Fragment>
                
            
            </Fragment>
        )
    }
}

export default LayerAttributes;
