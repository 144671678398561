import Axios from 'axios';
import { loadModules } from 'esri-loader';
import { _mapViewAvailable } from '../../utils/map';
import { CrudOperations } from './CRUDoperations.service';

const __USERHIDDENFIELDS = '__gis__Fields__Manager';
const _AUTH_STORE_TOKEN = '_AUTH_STORE_TOKEN';
const DRAWINGS_KEY = 'arcgis-local-drawings';

var __USER__NAME = '',
  ACCESS__LEVEL = 0;

  Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const _gis_service = Axios.create({
  baseURL: 'https://gis.reynoldsburg.gov/gisapi/api', //'https://localhost:44368/api', // 'https://gis.mansfieldcity.com/gisapi/api', // `${window.location.protocol}//${window.location.host}/gisapi/api`,
});

export const _userapi = {
    apitoken:"https://rms.reynoldsburg.gov/appapi/token",
    apiUser:"https://rms.reynoldsburg.gov/appapi/api/user/"  
  }; 

export const _flow_rate_updater = {
  updateHydrantFlowRate: (fid, serviceURL) => {
    const __promise = new Promise((resolve, reject) => {
      loadModules(['esri/layers/FeatureLayer']).then(async ([FeatureLayer]) => {
        let __rate = 0;
        // get the latest date
        CrudOperations.GetByFID('HydrantFlowTest', fid).then((_flowTests) => {
          if (_flowTests.data) {
            if (_flowTests.data.length > 0) {
              // get the flow from the latested date
              const _latestFlowObj = _flowTests.data.sort((a, b) => {
                return (
                  new Date(b.testdatetime).getTime() -
                  new Date(a.testdatetime).getTime()
                );
              })[0];

              __rate = _latestFlowObj.flow;

              let fLayer = new FeatureLayer(serviceURL);
              var query = fLayer.createQuery();
              query.where = `FACILITYID = '${fid}'`;
              query.outFields = ['OBJECTID', 'FLOW'];
              fLayer.queryFeatures(query).then((res) => {
                if (res.features.length > 0) {
                  const feature = res.features[0];
                  Object.keys(feature.attributes).forEach((name) => {
                    switch (name) {
                      case 'FLOW':
                        feature.attributes[name] = __rate;
                        break;
                    }
                  });
                  const edits = {
                    updateFeatures: [feature],
                  };
                  fLayer
                    .applyEdits(edits)
                    .then(() => resolve(feature.attributes.FLOW))
                    .catch((err) => reject(err));
                }
              });
            }
          }
        });
      });
    });
    return __promise;
  },
  updateSystemValve: (fid, serviceURL) => {
    const __promise = new Promise((resolve, reject) => {
      loadModules(['esri/layers/FeatureLayer', 'esri/config']).then(
        ([FeatureLayer, esriConfig]) => {
          esriConfig.request.interceptors.push({
            urls: /FeatureServer\/\d+$/,
            after: function (response) {
              response.data.supportedQueryFormats = 'JSON';
            },
          });

          let featureDefaultValues = {
            opendirection: 'Unknown',
            turnstoclose: 'Unknown',
            programyear: 'Unassigned',
            location: 'N/A',
          };
          // get the latest date
          CrudOperations.GetByFID('WaterValveExercising', fid).then(
            (_exercises) => {
              if (_exercises.data) {
                if (_exercises.data.length > 0) {
                  // get the flow from the latested date
                  const _latestExerciseObj = _exercises.data.sort((a, b) => {
                    return (
                      new Date(b.excercisingdatetime).getTime() -
                      new Date(a.excercisingdatetime).getTime()
                    );
                  })[0];
                  featureDefaultValues = {
                    opendirection: _latestExerciseObj.opendirection,
                    turnstoclose: _latestExerciseObj.turnstoclose,
                    programyear: _latestExerciseObj.programyear,
                    location: _latestExerciseObj.location,
                  };

                  let fLayer = new FeatureLayer(serviceURL);
                  var query = fLayer.createQuery();
                  query.where = `FACILITYID = '${fid}'`;
                  query.outFields = [
                    'OBJECTID',
                    'TURNSTOCLOSE',
                    'OPEN_DIR',
                    'VALVE_EX_PROG',
                    'LOCATION',
                  ];
                  fLayer.queryFeatures(query).then((res) => {
                    if (res.features.length > 0) {
                      const feature = res.features[0];
                      Object.keys(feature.attributes).forEach((name) => {
                        switch (name.toUpperCase()) {
                          case 'TURNSTOCLOSE':
                            feature.attributes[name] =
                              featureDefaultValues.turnstoclose;
                            break;
                          case 'OPEN_DIR':
                            feature.attributes[name] =
                              featureDefaultValues.opendirection;
                            break;
                          case 'VALVE_EX_PROG':
                            feature.attributes[name] =
                              featureDefaultValues.programyear;
                            break;
                          case 'LOCATION':
                            feature.attributes[name] =
                              featureDefaultValues.location;
                            break;
                        }
                      });
                      const edits = {
                        updateFeatures: [feature],
                      };
                      fLayer
                        .applyEdits(edits)
                        .then(() => resolve(featureDefaultValues))
                        .catch((err) => reject(err));
                    }
                  });
                }
              }
            }
          );
        }
      );
    });
    return __promise;
  },
  changeActiveStatus: (featureObj, config) => {
    return loadModules([
      'esri/layers/FeatureLayer',
      'esri/core/urlUtils',
      'esri/config',
    ]).then(([FeatureLayer, urlUtils, esriConfig]) => {
      // set the proxy
      urlUtils.addProxyRule({
        proxyUrl: config.proxyUrl,
        urlPrefix: config.SecuredServicesUrlPrefix,
      });

      esriConfig.request.interceptors.push({
        urls: /FeatureServer\/\d+$/,
        after: function (response) {
          response.data.supportedQueryFormats = 'JSON';
        },
      });

      const { layerUrl, facilityid, status } = featureObj;

      const { HydrantOperableField, ValveOperableField } =
        config.managedFlayerConfig;

      let fLayer = new FeatureLayer(layerUrl);
      var query = fLayer.createQuery();
      query.where = `FACILITYID = '${facilityid}'`;
      query.outFields = ['*'];
      return fLayer.queryFeatures(query).then((res) => {
        if (res.features.length > 0) {
          const feature = res.features[0];
          Object.keys(feature.attributes).forEach((name) => {
            switch (name.toUpperCase()) {
              case HydrantOperableField:
                feature.attributes[name] = status;
                break;
              case ValveOperableField:
                feature.attributes[name] = status;
                break;
              /* case 'COMMENT':
                  feature.attributes[name] = 'Service restored';
                  break; */
            }
          });
          const edits = {
            updateFeatures: [feature],
          };
          return fLayer.applyEdits(edits);
        } else {
          return null;
        }
      });
    });
  },
};

export const getUserSavedFields = (layerName) => {
  const _hiddenFields = localStorage.getItem(__USERHIDDENFIELDS);
  var layer_savedFields = [];
  if (_hiddenFields) {
    const __userFieldsArrayObj = JSON.parse(_hiddenFields);
    // return fields array for matching layername
    __userFieldsArrayObj.map((l) => {
      if (l.layer.toUpperCase() === layerName.toUpperCase()) {
        layer_savedFields = l.fields;
      }
    });
  }
  return layer_savedFields;
};

export const _user_auth = {


  signin: (email, pw) => {
    const _api_url = `${window.location.protocol}//${window.location.host}/api`;
    const __promise = new Promise((resolve, reject) => {
      // implement real auth
      const signinObj = {
        email: email,
        password: pw,
      };
      _gis_service
        .post('Users/ClassicAuth', signinObj)
        .then((res) => {
          if (res.data === '') {
            resolve(false);
          } else {
            const _userToken = localStorage.getItem(_AUTH_STORE_TOKEN);
            if (_userToken) {
              localStorage.removeItem(_AUTH_STORE_TOKEN);
            }
            const { accessLevel, uid, email } = res.data;
            __USER__NAME = email;
            ACCESS__LEVEL = accessLevel;
            localStorage.setItem(
              _AUTH_STORE_TOKEN,
              JSON.stringify({ token: uid, accessLevel, email })
            );
            resolve(true);
          }
        })
        .catch((err) => console.log('Error fetching user auth :: ', err));
    });
    return __promise;
  },

  signin2: (email, pw) => {
    const __promise = new Promise((resolve, reject) => {
      // implement real auth
      const data = 'username=' + email + '&password=' + pw + '&grant_type=password';
      _gis_service
        .post(_userapi.apitoken, data)
        .then((res) => {
          if (res.data === '') {
            resolve(false);
          } else {
            const _userToken = localStorage.getItem(_AUTH_STORE_TOKEN);
            if (_userToken) {
              localStorage.removeItem(_AUTH_STORE_TOKEN);
            }
//            const { accessLevel, uid, email } = res.data;
            __USER__NAME = email;
            const accessLevel = 4;
            ACCESS__LEVEL = 4;
            const uid = 1;
            localStorage.setItem(
              _AUTH_STORE_TOKEN,
              JSON.stringify({ token: uid, accessLevel, email })
            );
            resolve(true);
          }
        })
        .catch((err) => {
          console.log('Error fetching user auth :: ', err);
          resolve(false);
        });
    });
    return __promise;
  },

  signin3: (email, pw) => {
    const that = this;
    const __promise = new Promise((resolve, reject) => {
      // implement real auth
      const data = 'username=' + email + '&password=' + pw + '&grant_type=password';
      _gis_service
        .post(_userapi.apitoken, data, {headers:{'Content-Type':'application/x-www-urlencoded'}})
        .then((token: any) => {
          if (token.status === 200) {
            const _token = token.data.access_token;
            localStorage.setItem('user_token', _token);
            _user_auth.getUserClaims().then(res => {
              const _userToken = localStorage.getItem(_AUTH_STORE_TOKEN);
              if (_userToken) {
                localStorage.removeItem(_AUTH_STORE_TOKEN);
              } //            const { accessLevel, uid, email } = res.data;
              __USER__NAME = res.data.Email;
              ACCESS__LEVEL = res.data.AccessLevel
              const accessLevel = res.data.AccessLevel;
              const email = res.data.Email;
              const uid = 1;
              localStorage.setItem(_AUTH_STORE_TOKEN, JSON.stringify({
                token: uid,
                accessLevel,
                email
              }));
              resolve(true);
            });
          } else {
            resolve(null);
          }
        }, (err) => {
          resolve(false);
        })
        .catch((err) => {
          console.log('Error getting user auth :: ', err);
          resolve(false);
        });
    });

  return __promise;
  },

  getUserClaims() {
    _gis_service.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('user_token');
    return _gis_service.get(_userapi.apiUser+"getUserClaims");
  },
  
  signout: () => {
    const _userToken = localStorage.getItem(_AUTH_STORE_TOKEN);
    if (_userToken) {
      localStorage.removeItem(_AUTH_STORE_TOKEN);
    }
  },

/*  getUserToken(email, pw) {   /* Here for reference - not used in the code 
    const that = this;
    const _promise = new Promise((resolve, reject) => {
      const data = 'username=' + email + '&password=' + pw + '&grant_type=password';
      const headers = new Headers();
      headers.append('Content-Type', 'application/x-www-urlencoded');
      const options = new RequestOptions({headers: headers});
      that._http.post(that.apitoken, data, options)
            .subscribe((token: any) => {
              // console.log('The API Token: ', token);
              if (token.status === 200) {
                const _token = JSON.parse(token['_body']).access_token;
                localStorage.setItem('user_token', _token);
                that.getUserClaims().subscribe(res => {
                  const userClaim = JSON.parse(res['_body']);
                  const _user = {
                    IsUser: true,
                    FirstName: userClaim.UFName,
                    LastName: userClaim.ULName,
                    Email: userClaim.Email,
                    AccessLevel: userClaim.AccessLevel
                  };
                  const _al = userClaim.AccessLevel;
                  that.emit({user: _user});
                  that.emitAL(_al);
                  resolve(_user);
                });
              } else {
                resolve(null);
              }
            }, (err) => {
              resolve(err);
            });
    });  */
  
  GetAuthUser: () => {
    return {
      email: 'GIS-support@reynoldsburg.gov',
      accessLevel: 2,
    };
/*    return {
      email: __USER__NAME,
      accessLevel: ACCESS__LEVEL,
    }; */
  },

  isAuthorized: () => {
    __USER__NAME = 'GIS-support@reynoldsburg.gov';
    ACCESS__LEVEL = 2;
    return true;
/*    let __isAuth = false;
    const _userToken = localStorage.getItem(_AUTH_STORE_TOKEN);
    if (_userToken) {
      const _authObj = JSON.parse(_userToken);
      __USER__NAME = _authObj.email;
      ACCESS__LEVEL = _authObj.accessLevel;
      __isAuth = true;
    } else {
      __isAuth = false;
    }
    return __isAuth;  DMM 10/11/2022 */
  },

  saveToken: (userTokenObj) => {
    const _auth_token = localStorage.getItem(_AUTH_STORE_TOKEN);
    if (_auth_token) {
      localStorage.removeItem(_AUTH_STORE_TOKEN);
    }
    localStorage.setItem(_AUTH_STORE_TOKEN, JSON.stringify(userTokenObj));
  },
};

export const GIS_Base_Functions = {
  GetWorkOrderHistory: (fid) => {
    return _gis_service.get(`WorkOrders/${fid}`);
  },
  GetInternetSpeed: () => {
    var internetConnectionSpeed = 0;

    //console.log('The Navigator :: ', navigator);
    var connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    // Check for browser support
    if (!!connection) {
      // Get the connection type
      var type = connection.type || connection.effectiveType;
      //console.log('Internet connection Type :: ', type);
      // Get the connection speed in megabits per second (Mbps)
      var speed = connection.downlink || connection.bandwidth;
      internetConnectionSpeed = speed;
      //console.log(
      //  'Internet connection Speed in Mbps :: ',
      //  internetConnectionSpeed
      //);
    }

    return internetConnectionSpeed;
  },
  __addDefaultBaseLayer: (isVisible) => {
    var internetConnectionSpeed = GIS_Base_Functions.GetInternetSpeed();
    if (internetConnectionSpeed >= 5) {
      const mapview = _mapViewAvailable();
      const baseLayers = mapview.map.layers.items.filter((l) => {
        return l.id === '1703a318545-base';  // HARDCODED
      });

      if (baseLayers !== null) {
        const _rootLayer = baseLayers[0];
        if (!_rootLayer.visible) {
          _rootLayer.visible = isVisible; // layer root
        }
        _rootLayer.sublayers.items.map((l) => {
          switch (l.title.toUpperCase()) {    // TURN ON LISTED BASE LAYERS AUTOMATICALLY WHEN IMAGERY IS SELECTED HARDCODED
            case 'STREETS':
            //case 'ADDRESSES':
            case 'PARCELS':
              l.visible = isVisible; // layer root
              const _recursiveFunc = (layers) => {
                layers.map((ly) => {
                  if (ly.sublayers) {
                    _recursiveFunc(ly.sublayers.items);
                  } else {
                    ly.visible = isVisible;
                  }
                });
              };
              if (l.sublayers) {
                _recursiveFunc(l.sublayers.items);
              }
              break;
          }
        });
      }
    }
  },

  __getBasemap: (__Basemap) => {
    return loadModules(['esri/Basemap', 'esri/layers/TileLayer']).then(
      ([Basemap, TileLayer]) => {
        const _streetBasemapLayer = new TileLayer({
          url: __Basemap, // 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer', //appOptions.streetBasemapService,  //'https://tiles.arcgis.com/tiles/MEvzxYGCHO108ZxL/arcgis/rest/services/BASEMAP/VectorTileServer'
          copyright: 'City of Reynoldsburg, Ohio',
        });

        const streetBasemap = new Basemap({
          baseLayers: [_streetBasemapLayer],
          title: 'streets basemap',
          id: 'appbasemap',
        });
        return streetBasemap;
      }
    );
  },

  GetStreetBasemap: () => {
    return GIS_Base_Functions.__getBasemap();
  },
};

export const userGraphicService = {
  updateGraphicsStorage: (graphicsLayer) => {
    console.log(graphicsLayer.graphics.items.length);
    if (graphicsLayer.graphics.items.length > 0) {
      loadModules(['esri/Graphic']).then(([Graphic]) => {
        // store items
        if (localStorage.getItem(DRAWINGS_KEY) !== undefined) {
          localStorage.removeItem(DRAWINGS_KEY);
        }
        const rawGraphics = [];
        graphicsLayer.graphics.items.map((graphic) => {
          const _gr = new Graphic({
            geometry: graphic.geometry,
            symbol: graphic.symbol,
            attributes: graphic.attributes,
          });
          rawGraphics.push(_gr.toJSON());
        });
        localStorage.setItem(DRAWINGS_KEY, JSON.stringify(rawGraphics));
      });
    } else {
      if (localStorage.getItem(DRAWINGS_KEY)) {
        localStorage.removeItem(DRAWINGS_KEY);
      }
    }
  },
  deleteGraphic: (graphic, graphicsLayer) => {
    //graphicsLayer.remove(graphic);
    console.log(graphicsLayer);
  },
};
