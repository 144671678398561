import React, { Component, Fragment } from 'react';

import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';
import TextField from 'calcite-react/TextField';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Checkbox from 'calcite-react/Checkbox';
import { ParcelsReport } from '../../services/notifications';

class BufferSelectParcels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphic: this.props.graphic,
      bufferDistance: 100,
      bufferAndSelectParcels: true,
    };
  }
  componentDidMount() {}
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  CreateBuffer = () => {
    ParcelsReport.GenerateBuffer(
      this.state.graphic.geometry,
      this.state.bufferDistance,
      this.state.bufferAndSelectParcels
    );
  };

  render() {
    return (
      <Fragment>
        <FormControl fullWidth>
          <TextField
            fullWidth
            value={this.state.bufferDistance}
            type="number"
            onChange={(e) => this.setState({ bufferDistance: e.target.value })}
            leftAdornment={
              <input
                type="checkbox"
                checked={this.state.bufferAndSelectParcels}
                onChange={() =>
                  this.setState({
                    bufferAndSelectParcels: !this.state.bufferAndSelectParcels,
                  })
                }
              />
            }
            rightAdornment={<Button onClick={this.CreateBuffer}>Buffer</Button>}
          />
          <FormHelperText>Buffer distance in feet</FormHelperText>
        </FormControl>
      </Fragment>
    );
  }
}

export default BufferSelectParcels;
