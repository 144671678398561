import React, { Component, Fragment } from 'react';
import { loadModules } from 'esri-loader';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';
import Panel, { PanelTitle, PanelText } from 'calcite-react/Panel';
import TextField from 'calcite-react/TextField';
import Tabs, {
  TabNav,
  TabTitle,
  TabContents,
  TabSection,
} from 'calcite-react/Tabs';
import Button, { ButtonGroup } from 'calcite-react/Button';
import { SketchPicker, CompactPicker, AlphaPicker } from 'react-color';

import { v4 as uuidv4 } from 'uuid';

import './sketching.css';

const DRAWINGS_KEY = 'arcgis-local-drawings';

class Sketching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphic: null,
      drawingShape: '',
      isEditGraphic: false,
      isRectangleGraphic: false,
      isCircleGraphic: false,
      isPointGraphic: false,
      pointColor: '#a00b0bff',
      size: 20,
      width: 2,
      text: 'Sample Text',
      TextSymbol: {
        type: 'text',
        text: 'Sample Text',
        font: { size: 20 },
        color: { r: 159, g: 5, b: 0, a: 1 },
      },
      SimpleMarkerSymbol: {
        type: 'simple-marker',
        style: 'cross',
        outline: { width: 2, color: [160, 11, 11, 1] }, // #cbed92ff
        size: 20,
      },
      SimpleLineSymbol: {
        type: 'simple-line',
        width: 2,
        color: [253, 7, 7, 1],
      }, //#fd0707ff
      SimpleFillSymbol: {
        type: 'simple-fill',
        outline: { width: 2, color: [176, 13, 13, 1] },
        color: [233, 101, 101, 0.25],
      },
      opacity: 1,
      pointMarker: {
        type: 'simple-marker',
        style: 'cross',
        outline: { width: 2, color: [160, 11, 11, 1] },
        size: 20,
      },
      graphicName: 'My Graphic',
      graphicDescription: 'my graphic description',

      userGLayer: null,
      sketchViewModel: null,
      selectedColor: '#A00B0B',
      selectedPolygonColor: { r: 159, g: 5, b: 0, a: 1 },
      selectedTextColor: { r: 159, g: 5, b: 0, a: 1 },
      activeTabIndex: 0,
    };
    this.onTabChange = this.onTabChange.bind(this);
  }
  onTabChange(index) {
    this.setState({ activeTabIndex: index });
  }

  componentDidMount() {
    loadModules([
      'esri/widgets/Sketch/SketchViewModel',
      'esri/layers/GraphicsLayer',
    ]).then(([SketchViewModel, GraphicsLayer]) => {
      const userGLayer = this.props.mapview.map.findLayerById('userGraphics');

      this.props.mapview.when(() => {
        const sketchViewModel = new SketchViewModel({
          layer: userGLayer,
          view: this.props.mapview,
          pointSymbol: this.state.SimpleMarkerSymbol,
          polylineSymbol: this.state.SimpleLineSymbol,
          polygonSymbol: this.state.SimpleFillSymbol,
          creationMode: 'single',
          snappingOptions: {
            // autocasts as SnappingOptions()
            enabled: true, // snapping will be on by default
            // feature snapping will be enabled on the GraphicsLayer
            featureSources: [{ layer: userGLayer }],
          },
        });

        sketchViewModel.on('create', (e) => {
          switch (e.state.toLowerCase()) {
            case 'active':
              switch (e.tool.toLowerCase()) {
                case 'point':
                  e.graphic.symbol = this.state.SimpleMarkerSymbol;
                  break;
                case 'polyline':
                  e.graphic.symbol = this.state.SimpleLineSymbol;
                  break;
                case 'polygon':
                case 'rectangle':
                case 'circle':
                  e.graphic.symbol = this.state.SimpleFillSymbol;
                  break;
              }

              break;
            case 'complete':
              //console.log(e);
              this.updateSketching(e.graphic);
              this.setActiveButton();
              this._saveUserDrawingsItems();
              this.setState({ drawingShape: '' });
              break;

            case 'cancel':
              break;
          }
        });

        sketchViewModel.on('update', (evt) => {
          //console.log(evt.graphics[0]);
          switch (evt.state.toLowerCase()) {
            case 'start':
              // check if updating text
              this.setState({
                drawingShape: evt.graphics[0].attributes.isTextGraphic
                  ? 'text'
                  : evt.graphics[0].geometry.type,
                graphicName: evt.graphics[0].attributes.name,
                graphicDescription: evt.graphics[0].attributes.description,
                isEditGraphic: true,
                isGraphicUpdate: true,
                //isPointGraphic: true,
                isGraphicInfo: true,
              });
              break;

            case 'active':
              switch (evt.graphics[0].geometry.type) {
                case 'point':
                  if (evt.graphics[0].attributes.isTextGraphic) {
                    var symbol = {
                      type: 'text',
                      text: this.state.TextSymbol.text,
                      color: this.state.TextSymbol.color,
                      xoffset: 3,
                      yoffset: 3,
                      font: {
                        size: this.state.TextSymbol.font.size,
                        //family: this._textSymbol.font.family,
                        //weight: this._textSymbol.font.weight
                      },
                    };
                    var sketchViewModel = this.state.sketchViewModel;
                    sketchViewModel.pointSymbol = symbol;
                    this.setState({
                      sketchViewModel,
                      TextSymbol: {
                        type: 'text',
                        text: symbol.text,
                        font: { size: symbol.font.size },
                        color: this.state.selectedTextColor,
                      },
                    });
                  } else {
                    var SimpleMarkerSymbol = this.state.SimpleMarkerSymbol;
                    SimpleMarkerSymbol = evt.graphics[0].symbol;
                    this.setState({ SimpleMarkerSymbol });
                  }

                  break;
                case 'polyline':
                  var SimpleLineSymbol = this.state.SimpleLineSymbol;
                  SimpleLineSymbol = evt.graphics[0].symbol;
                  this.setState({ SimpleLineSymbol });
                  break;
                case 'polygon':
                case 'rectangle':
                case 'circle':
                  var SimpleFillSymbol = this.state.SimpleFillSymbol;
                  SimpleFillSymbol = evt.graphics[0].symbol;
                  this.setState({ SimpleFillSymbol });
                  break;
                default:
                  break;
              }
              break;

            case 'complete':
              // console.log(evt);
              //attributes uppdate
              evt.graphics[0].attributes.name = this.state.graphicName;
              evt.graphics[0].attributes.description =
                this.state.graphicDescription;

              console.log(evt.graphics[0]);
              // symbol update
              switch (evt.graphics[0].geometry.type) {
                case 'point':
                  if (evt.graphics[0].attributes.isTextGraphic) {
                    var symbol = {
                      type: 'text',
                      text: this.state.TextSymbol.text,
                      color: this.state.TextSymbol.color,
                      xoffset: 3,
                      yoffset: 3,
                      font: {
                        size: this.state.TextSymbol.font.size,
                        //family: this._textSymbol.font.family,
                        //weight: this._textSymbol.font.weight
                      },
                    };
                    evt.graphics[0].symbol = symbol;
                  } else {
                    evt.graphics[0].symbol = this.state.SimpleMarkerSymbol;
                  }

                  break;
                case 'polyline':
                  evt.graphics[0].symbol = this.state.SimpleLineSymbol;
                  break;
                case 'polygon':
                case 'rectangle':
                case 'circle':
                  evt.graphics[0].symbol = this.state.SimpleFillSymbol;
                  break;

                default:
                  break;
              }
              this._saveUserDrawingsItems();
              this.setState({
                drawingShape: '',
                isEditGraphic: false,
                isGraphicUpdate: false,
                isPointGraphic: false,
                isGraphicInfo: false,
              });
              break;
          }
        });

        sketchViewModel.on('delete', (e) => {
          this._saveUserDrawingsItems();
        });

        this.setState({ sketchViewModel, userGLayer });
        this.__initDrawButtonsClick();
      });
    });
  }

  /* shouldComponentUpdate(nextProps, nextState) {
    for (let i in nextState) {
      if (nextState[i] !== this.state[i]) {
        return true;
      }
    }
    return false;
  } */

  updateSketching(graphic) {
    const __attributes = {
      id: uuidv4(),
      name: this.state.graphicName,
      description: this.state.graphicDescription,
      isTextGraphic: this.state.drawingShape === 'text' ? true : false,
    };
    graphic.attributes = __attributes;
    this.setState({
      graphic: graphic,
      isEditGraphic: false,
      isGraphicUpdate: false,
      userGLayer: this.props.mapview.map.findLayerById('userGraphics'),
    });
  }
  handleColorChangeComplete = (shapeType, color) => {
    var symbol,
      _sketch = this.state.sketchViewModel;
    switch (shapeType) {
      case 'point':
        symbol = this.state.SimpleMarkerSymbol;
        symbol.outline.color = [
          color.rgb.r,
          color.rgb.g,
          color.rgb.b,
          color.rgb.a,
        ];
        _sketch.pointSymbol = symbol;
        this.setState({
          SimpleMarkerSymbol: symbol,
        });
        break;

      case 'polyline':
        symbol = this.state.SimpleLineSymbol;
        symbol.color = [color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a];
        _sketch.polylineSymbol = symbol;
        this.setState({
          SimpleLineSymbol: symbol,
        });
        break;
    }

    this.setState({
      selectedColor: color.hex,
      sketchViewModel: _sketch,
    });
  };

  handlePolygonColorChange = (shape, value) => {
    var symbol = this.state.SimpleFillSymbol;
    var _sketch = this.state.sketchViewModel;

    switch (shape) {
      case 'outline-width':
        symbol.outline.width = value;
        break;

      case 'outline-color':
        symbol.outline.color = [
          value.rgb.r,
          value.rgb.g,
          value.rgb.b,
          value.rgb.a,
        ];
        break;
      case 'fill-color':
        symbol.color = [value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a];
        break;
      case 'fill-opacity':
        symbol.color = [value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a];
        break;
    }

    _sketch.polygonSymbol = symbol;
    this.setState({
      selectedPolygonColor: value.rgb,
      sketchViewModel: _sketch,
    });
  };

  handleTextChange = (shape, value) => {
    var symbol = {
      type: 'text',
      text: this.state.TextSymbol.text,
      color: this.state.TextSymbol.color,
      xoffset: 3,
      yoffset: 3,
      font: {
        size: this.state.TextSymbol.font.size,
        //family: this._textSymbol.font.family,
        //weight: this._textSymbol.font.weight
      },
    };
    var _sketch = this.state.sketchViewModel;

    switch (shape) {
      case 'text-value':
        symbol.text = value;
        break;

      case 'text-size':
        symbol.font.size = value;
        break;
      case 'text-color':
        symbol.color = [value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a];
        break;
    }

    _sketch.pointSymbol = symbol;
    this.setState({
      selectedTextColor: value.rgb,
      sketchViewModel: _sketch,
      TextSymbol: {
        type: 'text',
        text: symbol.text,
        font: { size: symbol.font.size },
        color: this.state.selectedTextColor,
      },
    });
  };

  handleSizeOrWidthChange = (shapeType, value) => {
    var symbol;
    var _sketch = this.state.sketchViewModel;
    switch (shapeType) {
      case 'point':
        symbol = this.state.SimpleMarkerSymbol;
        symbol.size = value;
        _sketch.pointSymbol = symbol;
        break;

      case 'polyline':
        symbol = this.state.SimpleLineSymbol;
        symbol.width = value;
        _sketch.polylineSymbol = symbol;
        break;
    }

    this.setState({
      sketchViewModel: _sketch,
    });
  };

  handleOpacityChange = (e) => {
    console.log(e);
  };

  __initDrawButtonsClick() {
    const drawButtons = document.getElementsByClassName('esri-sketch__button');
    for (let index = 0; index < drawButtons.length; index++) {
      const BtnElement = drawButtons[index];
      BtnElement.addEventListener('click', (e) => {
        this.__setUpClickHandler(e);
      });
    }
  }

  __cancelDrawingAction = () => {
    this.state.sketchViewModel.cancel();
    document.getElementById('btnDraw').click();
  };

  __setUpClickHandler(btnElement) {
    this.setState({
      drawingShape: '',
    });
    switch (btnElement.target.title.toLowerCase()) {
      case 'draw a point':
        if (this.state.sketchViewModel) {
          var symbol = this.state.SimpleMarkerSymbol;
          var _sketch = this.state.sketchViewModel;
          _sketch.pointSymbol = symbol;
          this.setState({
            sketchViewModel: _sketch,
            drawingShape: 'point',
          });
        } else {
          this.setState({
            drawingShape: 'point',
          });
        }
        this.state.sketchViewModel.create('point');
        this.setActiveButton(btnElement.target);
        break;
      case 'draw a polyline':
        if (this.state.sketchViewModel) {
          var _sketch = this.state.sketchViewModel;
          var symbol = this.state.SimpleLineSymbol;

          _sketch.polylineSymbol = symbol;

          this.setState({
            sketchViewModel: _sketch,
            drawingShape: 'polyline',
          });
        } else {
          this.setState({
            drawingShape: 'polyline',
          });
        }
        this.state.sketchViewModel.create('polyline');
        this.setActiveButton(btnElement.target);
        break;
      case 'draw a polygon':
        this.setState({
          drawingShape: 'polygon',
        });
        this.state.sketchViewModel.create('polygon');
        this.setActiveButton(btnElement.target);
        break;
      case 'draw a rectangle':
        // show rectangle input
        this.setState({
          drawingShape: 'rectangle',
        });
        this.state.sketchViewModel.create('rectangle');
        this.setActiveButton(btnElement.target);
        break;
      case 'draw a circle':
        // show circle input
        this.setState({
          drawingShape: 'circle',
        });
        this.state.sketchViewModel.create('circle');
        this.setActiveButton(btnElement.target);
        break;
      case 'add text':
        if (this.state.sketchViewModel) {
          var symbol = {
            type: 'text',
            text: this.state.TextSymbol.text,
            color: this.state.TextSymbol.color,
            xoffset: 3,
            yoffset: 3,
            font: {
              size: this.state.TextSymbol.font.size,
            },
          };
          var _sketch = this.state.sketchViewModel;
          _sketch.pointSymbol = symbol;
          this.setState({
            sketchViewModel: _sketch,
            drawingShape: 'text',
          });
        } else {
          this.setState({
            drawingShape: 'text',
          });
        }

        this.state.sketchViewModel.create('point');
        this.setActiveButton(btnElement.target);
        break;
      case 'draw an arrow':
        this.setActiveButton(btnElement.target);
        break;
      case 'undo':
        this.setState({
          drawingShape: '',
        });
        this.setActiveButton();
        this.state.sketchViewModel.undo();
        break;
      case 'redo':
        this.setState({
          drawingShape: '',
        });
        this.setActiveButton();
        this.state.sketchViewModel.redo();
        break;
      case 'draw settings':
        console.log('Alright...');
        // this.state.sketchViewModel.redo();
        break;
    }
  }

  setActiveButton(selectedButton) {
    // focus the view to activate keyboard shortcuts for sketching
    this.props.mapview.focus();
    const elements = document.getElementsByClassName(
      'esri-sketch__button-active'
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('esri-sketch__button-active');
    }
    if (selectedButton) {
      selectedButton.classList.add('esri-sketch__button-active');
    }
  }

  _saveUserDrawingsItems() {
    const userGLayer = this.props.mapview.map.findLayerById('userGraphics');
    const userDrawings = userGLayer.graphics.items;

    if (userDrawings.length > 0) {
      loadModules(['esri/Graphic']).then(([Graphic]) => {
        if (localStorage.getItem(DRAWINGS_KEY) !== undefined) {
          localStorage.removeItem(DRAWINGS_KEY);
        }
        const rawGraphics = [];
        userGLayer.graphics.items.map((graphic) => {
          const _gr = new Graphic({
            geometry: graphic.geometry,
            symbol: graphic.symbol,
            attributes: graphic.attributes,
          });
          rawGraphics.push(_gr.toJSON());
        });
        localStorage.setItem(DRAWINGS_KEY, JSON.stringify(rawGraphics));
      });
    } else {
      if (localStorage.getItem(DRAWINGS_KEY)) {
        localStorage.removeItem(DRAWINGS_KEY);
      }
    }

    this.props.setUserGraphics(userDrawings);
  }

  __deleteGraphic() {
    // console.log(this.state.userGLayer);
    this.state.sketchViewModel.delete();
    this.state.sketchViewModel.complete();
  }

  _saveGraphicUpdate() {
    this.state.sketchViewModel.complete();
  }

  render() {
    return (
      <Fragment>
        <div className="sketch-component" id="sketch-tools">
          <div className="esri-sketch__panel">
            <div className="esri-sketch__section esri-sketch__tool-section">
              <button
                aria-label="Draw a point"
                className="esri-sketch__button esri-icon-map-pin"
                title="Draw a point"
              ></button>
              <button
                aria-label="Draw a polyline"
                className="esri-sketch__button esri-icon-polyline"
                title="Draw a polyline"
              ></button>
              <button
                aria-label="Draw a polygon"
                className="esri-sketch__button esri-icon-polygon"
                title="Draw a polygon"
              ></button>
              <button
                aria-label="Draw a rectangle"
                className="esri-sketch__button esri-icon-checkbox-unchecked"
                title="Draw a rectangle"
              ></button>
              <button
                aria-label="Draw a circle"
                className="esri-sketch__button esri-icon-radio-unchecked"
                title="Draw a circle"
              ></button>
              <button
                aria-label="Add text"
                className="esri-sketch__button esri-icon-comment"
                title="Add text"
              ></button>
            </div>
            <div className="esri-sketch__section esri-sketch__tool-section">
              <button
                aria-label="Undo"
                className="esri-sketch__button esri-icon-undo"
                title="Undo"
              ></button>
              <button
                aria-label="Redo"
                className="esri-sketch__button esri-icon-redo"
                title="Redo"
              ></button>
            </div>
            {/* <div className="esri-sketch__section esri-sketch__tool-section">
              <button
                aria-label="Draw settings"
                className="esri-sketch__button esri-icon-settings"
                title="Draw settings"
              ></button>
            </div> */}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div className="map-draw-tool-content-scroller1">
            <div
              className="esri-sketch__panel esri-sketch__info-panel"
              style={{ display: this.state.isEditGraphic ? 'block' : 'none' }}
            >
              <div className="esri-sketch__section esri-sketch__info-section esri-sketch__info-count-section">
                <div
                  className="esri-sketch__feature-count-badge"
                  aria-label="1 Feature"
                >
                  <span className="esri-sketch__feature-count-number text-info">
                    1 Feature
                  </span>
                </div>
              </div>
              <div className="esri-sketch__section esri-sketch__info-section">
                <button
                  aria-label="Delete feature"
                  className="esri-sketch__button esri-icon-trash"
                  title="Delete feature"
                  onClick={() => this.__deleteGraphic()}
                ></button>
              </div>
            </div>

            <div
              className="sketch-component sketch-component-wrapper sketch-custom-measurements hidden"
              id="sketch-tools-length"
              style={{
                display:
                  this.state.drawingShape === 'rectangle' ? 'block' : 'none',
              }}
            >
              {/* Sketching a rectangle with input width and height> */}
              {/* <FormControlLabel>Width:</FormControlLabel>
              <FormControl success>
                <TextField
                  type="number"
                  fullWidth
                  defaultValue="0"
                  label="Width:"
                />
                <FormHelperText>provide width in ft</FormHelperText>
              </FormControl>

              <FormControlLabel>Height:</FormControlLabel>
              <FormControl success>
                <TextField
                  type="number"
                  fullWidth
                  defaultValue="0"
                  label="Height:"
                />
                <FormHelperText>provide height in ft</FormHelperText>
              </FormControl> */}
            </div>
            <div
              className="sketch-component sketch-component-wrapper sketch-custom-measurements hidden"
              id="sketch-tools-radius"
              style={{
                display:
                  this.state.drawingShape === 'circle' ? 'block' : 'none',
              }}
            >
              {/* Sketching a circle with input radius > */}
              {/* <FormControlLabel>Radius:</FormControlLabel>
              <FormControl success>
                <TextField
                  type="number"
                  fullWidth
                  defaultValue="0"
                  label="Radius:"
                />
                <FormHelperText>provide radius in ft</FormHelperText>
              </FormControl> */}
            </div>
            <div
              className="sketch-component sketch-component-wrapper hidden"
              id="sketch-info"
              style={{
                display: this.state.drawingShape === '' ? 'none' : 'block',
              }}
            >
              <FormControl style={{ margin: '0' }}>
                <FormControlLabel>Name</FormControlLabel>
                <TextField
                  fullWidth
                  value={this.state.graphicName}
                  onChange={(e) =>
                    this.setState({ graphicName: e.target.value })
                  }
                />
              </FormControl>

              <FormControl style={{ margin: '0' }}>
                <FormControlLabel>Description</FormControlLabel>
                <TextField
                  fullWidth
                  value={this.state.graphicDescription}
                  type="textarea"
                  onChange={(e) =>
                    this.setState({ graphicDescription: e.target.value })
                  }
                />
              </FormControl>
            </div>

            <div
              className="sketch-component sketch-component-wrapper"
              style={{
                display: this.state.drawingShape === 'point' ? 'block' : 'none',
              }}
            >
              <div className="draw-color-tab-wrapper">
                <Tabs
                  onTabChange={this.onTabChange}
                  activeTabIndex={this.state.activeTabIndex}
                >
                  <TabNav>
                    <TabTitle>Point Color</TabTitle>
                    <TabTitle>Point Size</TabTitle>
                  </TabNav>
                  <TabContents>
                    <TabSection>
                      <PanelText>Color:</PanelText>
                      <CompactPicker
                        color={this.state.selectedColor}
                        onChangeComplete={(e) =>
                          this.handleColorChangeComplete('point', e)
                        }
                      />
                    </TabSection>
                    <TabSection>
                      <PanelText>Size:</PanelText>
                      <TextField
                        value={this.state.SimpleMarkerSymbol.size}
                        label="Size"
                        onChange={(e) =>
                          this.handleSizeOrWidthChange('point', e.target.value)
                        }
                      />
                    </TabSection>
                  </TabContents>
                </Tabs>
              </div>
            </div>

            <div
              className="sketch-component sketch-component-wrapper"
              style={{
                display: this.state.drawingShape === 'text' ? 'block' : 'none',
              }}
            >
              <div className="draw-color-tab-wrapper">
                <PanelText>Display Text:</PanelText>
                <TextField
                  fullWidth
                  value={this.state.TextSymbol.text}
                  label="Display text"
                  onChange={(e) =>
                    this.handleTextChange('text-value', e.target.value)
                  }
                />
                <PanelText>Font Size:</PanelText>
                <TextField
                  value={this.state.TextSymbol.font.size}
                  label="Size"
                  onChange={(e) =>
                    this.handleTextChange('text-size', e.target.value)
                  }
                />

                <PanelText>Font Color:</PanelText>
                <CompactPicker
                  color={this.state.selectedTextColor}
                  onChangeComplete={(e) =>
                    this.handleTextChange('text-color', e)
                  }
                />
              </div>
            </div>
            <div
              className="sketch-component sketch-component-wrapper"
              style={{
                display:
                  this.state.drawingShape === 'polyline' ? 'block' : 'none',
              }}
            >
              <div className="draw-color-tab-wrapper">
                <Tabs
                  onTabChange={this.onTabChange}
                  activeTabIndex={this.state.activeTabIndex}
                >
                  <TabNav>
                    <TabTitle>Line Color</TabTitle>
                    <TabTitle>Line Width</TabTitle>
                  </TabNav>
                  <TabContents>
                    <TabSection>
                      <PanelText>Color:</PanelText>
                      <CompactPicker
                        color={this.state.selectedColor}
                        onChangeComplete={(e) =>
                          this.handleColorChangeComplete('polyline', e)
                        }
                      />
                    </TabSection>
                    <TabSection>
                      <PanelText>Width:</PanelText>
                      <TextField
                        value={this.state.SimpleLineSymbol.width}
                        label="Width"
                        onChange={(e) =>
                          this.handleSizeOrWidthChange(
                            'polyline',
                            e.target.value
                          )
                        }
                      />
                    </TabSection>
                  </TabContents>
                </Tabs>
              </div>
            </div>
            <div
              className="sketch-component sketch-component-wrapper"
              style={{
                display:
                  this.state.drawingShape === 'polygon' ||
                  this.state.drawingShape === 'rectangle' ||
                  this.state.drawingShape === 'circle'
                    ? 'block'
                    : 'none',
              }}
            >
              <div className="draw-color-tab-wrapper">
                <Tabs
                  onTabChange={this.onTabChange}
                  activeTabIndex={this.state.activeTabIndex}
                >
                  <TabNav>
                    <TabTitle>Outline</TabTitle>
                    <TabTitle>Fill</TabTitle>
                  </TabNav>
                  <TabContents>
                    <TabSection>
                      <PanelText>Outline Width:</PanelText>
                      <TextField
                        value={this.state.SimpleLineSymbol.width}
                        label="Width"
                        onChange={(e) =>
                          this.handlePolygonColorChange(
                            'outline-width',
                            e.target.value
                          )
                        }
                      />
                      <PanelText>Outline Color:</PanelText>
                      <CompactPicker
                        color={this.state.selectedPolygonColor}
                        onChangeComplete={(e) =>
                          this.handlePolygonColorChange('outline-color', e)
                        }
                      />
                    </TabSection>
                    <TabSection>
                      <PanelText>Fill Color:</PanelText>
                      <CompactPicker
                        color={this.state.selectedPolygonColor}
                        onChangeComplete={(e) =>
                          this.handlePolygonColorChange('fill-color', e)
                        }
                      />
                      <PanelText>Opacity:</PanelText>
                      <AlphaPicker
                        width={235}
                        color={this.state.selectedPolygonColor}
                        onChange={(e) =>
                          this.handlePolygonColorChange('fill-opacity', e)
                        }
                      />
                    </TabSection>
                  </TabContents>
                </Tabs>
              </div>
            </div>

            <div
              style={{
                margin: '15px auto',
                textAlign: 'center',
                display: this.state.drawingShape === '' ? 'none' : 'block',
              }}
            >
              <ButtonGroup>
                <Button onClick={this.__cancelDrawingAction}>Cancel</Button>
                <Button
                  onClick={() => this._saveGraphicUpdate()}
                  style={{
                    display: this.state.isGraphicUpdate ? 'block' : 'none',
                  }}
                >
                  Save graphic updates
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Sketching;
