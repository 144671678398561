import React, { Fragment, useEffect, useState } from 'react';
const __IDENTIFYSETTINGS = '__identifySettings';

const MapIdentifyWithNoResult = () => {
    const [isSavedLayer, setSavedLayer] = useState(false);
    useEffect(() => {
        var Identify = localStorage.getItem(__IDENTIFYSETTINGS);
        if(Identify) {
            var _IdentifyObj = JSON.parse(Identify);
            if(_IdentifyObj.Layers.length > 0) {
                setSavedLayer(true);
            }
        }
    });

    const btnClose = {
        position: 'absolute',
        top: '5px',
        right: '5px',
        width: '40px',
        height: '30px',
        cursor: 'pointer',
        fontSize: '1.8rem',
        fontWeight: '400'
    }
    const _close_window = () => {
        document.getElementById('right-bar-wrapper').classList.add('hidden');
    }
    return (
        <Fragment>
            <div className="esri-widget__content--empty" style={{position: 'relative'}}>
                <div title="close" style={btnClose} onClick={() => _close_window()}>x</div>
                <span aria-hidden="true" className="esri-widget__no-bookmark-icon esri-icon-notice-triangle"></span>
                <h1 className="esri-widget__heading esri-bookmarks__no-bookmarks-heading">No Result found</h1>
                <p className="esri-bookmarks__no-bookmarks-description">
                    There were no result found. Try to identify a feature again
                </p>
                <p className="esri-bookmarks__no-bookmarks-description" 
                    style={{color: 'red', display: isSavedLayer ? 'block' : 'none'}}>
                    You seem to have some layer configuation set to be identified. 
                    Please verify your identify settings
                </p>
            </div>
        </Fragment>
    )
}

export default MapIdentifyWithNoResult;
