import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import { saveAs } from 'file-saver/dist/FileSaver';
import Draggable from 'react-draggable';
import Button, { ButtonGroup } from 'calcite-react/Button';
import DownloadToIcon from 'calcite-ui-icons-react/DownloadToIcon';
import PlusSquareIcon from 'calcite-ui-icons-react/PlusSquareIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import DeepLearningProjectIcon from 'calcite-ui-icons-react/DeepLearningProjectIcon';
import AutomationIcon from 'calcite-ui-icons-react/AutomationIcon';
import Loader from 'calcite-react/Loader';
import swal from 'sweetalert';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

//import { _mapViewAvailable } from '../../utils/map';

import '../../styles/draggable-report.css';
//import EditModal from './EditModal';

//import { _flow_rate_updater, _gis_service } from '../services/gis_service';
import { _flow_rate_updater } from '../services/gis_service';
import AddNewValveExercise from './AddNewValveExercise';
import EditValveExercise from './EditValveExercise';
import { CrudOperations } from '../services/CRUDoperations.service';

const modalContainer = document.createElement('DIV');

const Entity = 'WaterValveExercising';

class ValveExercise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FACILITYID: this.props.feature.attributes.FacilityID,
      STATUS:
        this.props.feature.attributes.OPERABLE_STATUS ||
        this.props.feature.attributes['Operable Status'],
      WORKORDERURL: '#',
      layerName: this.props.feature.attributes.layerName,
      IsLoading: true,
      results: [],
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.setState({
      IsLoading: true,
    });
    loadModules([
      'esri/geometry/SpatialReference',
      'esri/tasks/GeometryService',
      'esri/rest/support/ProjectParameters',  //tasks to rest DMM 9/22/2022
    ]).then(([SpatialReference, GeometryService, ProjectParameters]) => {
      var geomSer = new GeometryService(this.props.config.geometryService);
      var params = new ProjectParameters({
        geometries: [this.props.feature.geometry],
        outSpatialReference: new SpatialReference({
          wkid: 102100,
          wkt: 'toJSON()',
        }),
      });
      geomSer.project(params).then((res) => {
        const location = { lat: res[0].latitude, lng: res[0].longitude };

        CrudOperations.GetByFID(Entity, this.state.FACILITYID).then(
          (_exercises) => {
            this.setState({
              IsLoading: false,
              results: _exercises.data ? _exercises.data : [],
              WORKORDERURL: `https://src.mansfieldcity.com/pg/mgmt/quick.asp?fc=${this.state.layerName}&fID=${this.state.FACILITYID}&lat=${location.lat}&lng=${location.lng}&map1=1`,
            });
          }
        );
      });
    });
  }

  _getExercises = () => {
    CrudOperations.GetByFID(Entity, this.state.FACILITYID)
      .then((_exercises) => {
        this.setState({
          IsLoading: false,
          results: _exercises.data ? _exercises.data : [],
        });
      })
      .catch((err) => console.log('Error fetching valve exercises'));
  };

  downloadFile = (data, reportName = 'GIS_flowtest_report') => {
    // console.log(data);
    const replacer = (key, value) => (value === null ? 'Not available' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, `${reportName}.csv`);
  };
  __close_report = () => {
    this.props.mapview.ui.remove('center-draggable-wrapper');
  };
  __record_new_flow_test = () => {
    alert('adding new flow test form...');
  };

  _downloadCurrentFlowtests = () => {
    this.downloadFile(
      this.state.results,
      `FID_${this.state.FACILITYID}_exercises`
    );
  };
  _downloadAllFlowtests = async () => {
    this.setState({
      isSubmitting: true,
    });
    CrudOperations.Get(Entity)
      .then((_flowTests) => {
        this.downloadFile(_flowTests.data, `All_exercises`);
        this.setState({
          isSubmitting: false,
        });
      })
      .catch((err) =>
        console.log('Error requesting data from server :: ', err)
      );
  };

  handleEdit = (row) => {
    const { valveFlayer } = this.props.config.managedFlayerConfig;
    ReactDOM.render(
      <EditValveExercise
        exercise={row}
        facilityid={this.state.FACILITYID}
        valveFlayer={valveFlayer}
        updateUI={this._save_exercise_update}
        close={this.closeEditForm}
      />,
      document.body.appendChild(modalContainer)
    );
  };
  handleDelete = (row) => {
    swal({
      text: `You are about to delete this record. is this correct?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then(async (action) => {
      if (action) {
        // delete record and reload data
        const { valveFlayer } = this.props.config.managedFlayerConfig;

        CrudOperations.Delete(Entity, row.watervalveexercisingid)
          .then((res) => {
            _flow_rate_updater
              .updateSystemValve(this.state.FACILITYID, valveFlayer)
              .then((featureUpdatedValues) => {
                if (
                  !document
                    .getElementById('right-bar-wrapper')
                    .classList.contains('hidden')
                ) {
                  this.props._updateValveAttributesUI(featureUpdatedValues);
                }
                this._getExercises();
                swal('Record deleted successfully!');
              });
          })
          .catch((err) =>
            console.log('Error deleting data from Server :: ', err)
          );
      }
    });
  };

  _save_exercise_update = (featureUpdatedValues) => {
    if (
      !document.getElementById('right-bar-wrapper').classList.contains('hidden')
    ) {
      this.props._updateValveAttributesUI(featureUpdatedValues);
    }
    this.closeEditForm();
    this._getExercises();
    swal('edits saved successfully!');
  };

  closeEditForm = () => {
    ReactDOM.unmountComponentAtNode(modalContainer);
  };

  _goToCreateNewFlowTest = () => {
    this.__close_report();
    if (
      document.getElementById('right-bar-wrapper').classList.contains('hidden')
    ) {
      document.getElementById('right-bar-wrapper').classList.remove('hidden');
    }
    ReactDOM.render(
      <AddNewValveExercise
        FACILITYID={this.state.FACILITYID}
        config={this.props.config}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };

  formatDate = (d) => {
    return new Date(d).toLocaleDateString();
  };

  __renderComponent() {
    if (this.state.IsLoading) {
      return (
        <div className="loading-wrapper">
          <Loader text="Fetching flow tests, please wait..." />
        </div>
      );
    } else {
      switch (this.state.results.length) {
        case 0:
          return (
            <Fragment>
              <div className="no-results-found">
                <div className="draggable-content-toolbar">
                  <ButtonGroup>
                    <Button
                      green
                      onClick={this._goToCreateNewFlowTest}
                      icon={<PlusSquareIcon size={16} />}
                    >
                      Record valve exercise
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="main-draggable-content">
                  <div className="esri-widget__content--empty">
                    <span
                      aria-hidden="true"
                      className="esri-widget__no-bookmark-icon esri-icon-notice-triangle"
                    ></span>
                    <h1 className="esri-widget__heading esri-bookmarks__no-bookmarks-heading">
                      No data found
                    </h1>
                    <p className="esri-bookmarks__no-bookmarks-description">
                      This system valve has no exercising record at this time.
                    </p>
                  </div>
                </div>
              </div>
            </Fragment>
          );
          break;

        default:
          const columns = [
            {
              Header: 'ID',
              accessor: 'watervalveexercisingid',
            },
            {
              id: 'excercisingdatetime',
              Header: 'DATE',
              accessor: (row) => {
                return this.formatDate(row.excercisingdatetime);
              },
              //Cell: (row) => {return this.formatDate(row.testdatetime)},
              sortMethod: (a, b) => {
                var a1 = new Date(a).getTime();
                var b1 = new Date(b).getTime();
                if (a1 < b1) return 1;
                else if (a1 > b1) return -1;
                else return 0;
              },
            },
            {
              Header: 'INSPECTOR',
              accessor: 'inspector',
            },
            {
              Header: 'TURNS',
              accessor: 'turnstoclose',
            },
            {
              Header: 'PYEAR',
              accessor: 'programyear',
            },
            {
              Header: 'RESULT',
              accessor: 'result',
            },
            {
              Header: 'COMMENT',
              accessor: 'comment',
            },
            {
              Header: '',
              Cell: (row) => (
                <div>
                  <PencilIcon
                    size={16}
                    onClick={() => this.handleEdit(row.original)}
                  />
                  <span style={{ margin: '0 10px' }}>|</span>
                  <TrashIcon
                    size={16}
                    onClick={() => this.handleDelete(row.original)}
                  />
                </div>
              ),
            },
          ];
          return (
            <Fragment>
              <div className="result-found">
                <div className="draggable-content-toolbar">
                  <ButtonGroup>
                    <Button
                      green
                      onClick={this._goToCreateNewFlowTest}
                      icon={<PlusSquareIcon size={16} />}
                    >
                      Record valve exercise
                    </Button>
                    <Button
                      disabled={this.state.isSubmitting}
                      onClick={this._downloadAllFlowtests}
                      icon={<DownloadToIcon size={16} />}
                    >
                      {this.state.isSubmitting
                        ? 'Downloading.......'
                        : 'Download all exercices'}
                    </Button>

                    <Button
                      onClick={this._downloadCurrentFlowtests}
                      icon={<DownloadToIcon size={16} />}
                    >
                      Download current list
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="main-draggable-content">
                  <ReactTable
                    columns={columns}
                    data={this.state.results}
                    defaultPageSize={5}
                    style={{
                      height: '300px', // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                  ></ReactTable>
                </div>
              </div>
            </Fragment>
          );
          break;
      }
    }
  }

  render() {
    return (
      <Fragment>
        <Draggable handle=".center-draggable-header">
          <div className="center-draggable-wrapper">
            <div className="center-draggable-header">
              <h3>
                <span>
                  <AutomationIcon />
                </span>{' '}
                Exercise for valve: {this.state.FACILITYID}
              </h3>
              <div
                className="btn-close-draggable-wrapper"
                title="close"
                onClick={this.__close_report}
              >
                X
              </div>
            </div>
            <div className="center-draggable-content">
              {/**Render component */}
              {this.__renderComponent()}
            </div>
            <div className="report-footer">
              <div style={{ position: 'relative' }}>
                <Button
                  href={this.state.WORKORDERURL}
                  target="_blank"
                  icon={<DeepLearningProjectIcon size={16} />}
                >
                  Create Work Order
                </Button>
                <div className="active-status-wrapper">
                  System Valve Status: <b>{this.state.STATUS}</b>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </Fragment>
    );
  }
}

export default ValveExercise;
