
import React, { Component, Fragment } from 'react';
import { connect  } from 'react-redux';
import { loadModules } from 'esri-loader';

import List, {
    ListItem,
    ListHeader,
    ListItemTitle,
    ListItemSubtitle
  } from 'calcite-react/List';
import { _mapViewAvailable } from '../../../utils/map';
import { GIS_Base_Functions } from '../../services/gis_service';

const __scrollablePanel = {
    position: 'absolute',
    top: '55px',
    bottom: '25px',
    width: '100%',
    overflow: 'auto',
  };

class Basemaps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeBasemap: 'Street basemap'
        }
    }

    componentDidMount () {
        //console.log(this.props);
    }

    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
        }
    }

    addDefaultBaseLayers = (isImagery) => {
        GIS_Base_Functions.__addDefaultBaseLayer(!isImagery);
    };

    setBasemap = (url, caption) => {
        loadModules(['esri/Basemap', 'esri/layers/TileLayer']).then(
            ([Basemap, TileLayer]) => {

              switch (caption) {
                  case 'Street basemap':
                      const mapview = _mapViewAvailable();
                      GIS_Base_Functions.__getBasemap(this.props.config.streetBasemapService).then((streetBasemap) => {
                        _mapViewAvailable().map.basemap = streetBasemap;
                      });
                      break;
              
                  default:
                    const streetBasemap = new Basemap({
                        baseLayers: [
                          new TileLayer({
                            url,
                            copyright: 'City of Reynoldsburg, Ohio',
                          }),
                        ],
                        title: caption,
                        id: 'appbasemap',
                      });
                      _mapViewAvailable().map.basemap = streetBasemap;
                      break;
              }

              
              this.setState({activeBasemap: caption});
              setTimeout(() => {
                const isStreet = this.state.activeBasemap === 'Street basemap' ? true : false;
                this.addDefaultBaseLayers(isStreet);
              }, 50);
            }
          );
    }

    render() {
        const { listImageries } = this.props.config;
        return (
            <Fragment>
                <div style={__scrollablePanel}>
                    <div>
                    {
                    listImageries.length === 0 && (
                        <div className="esri-widget__content--empty">
                            <span aria-hidden="true" className="esri-widget__no-bookmark-icon esri-icon-sketch-rectangle"></span>
                            <h1 className="esri-widget__heading esri-bookmarks__no-bookmarks-heading">No Imageries</h1>
                            <p className="esri-bookmarks__no-bookmarks-description">
                              Add aerial imageries in the config json to be used as basemaps
                            </p>
                        </div>
                    )
                }
                {
                   listImageries.length > 0 && (  //This is where aerials are listed
                       <>
                            <List>
                                {
                                    listImageries.map((a, i) => (
                                        <ListItem className={this.state.activeBasemap === a.caption ? 'active-basemap' : ''} 
                                            key={i} 
                                            leftNode={<img src={a.thumbnail} width={'100px'} 
                                            onClick={() => this.setBasemap(a.url, a.caption)}
                                            />}>
                                            <ListItemTitle>{a.caption}</ListItemTitle>
                                        </ListItem>
                                    ))
                                }
                                
                            </List>
                       </>
                   ) 
                }
                    </div>
                </div>
                
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      config: state.config
    };
  };
export default connect(mapStateToProps)(Basemaps);
