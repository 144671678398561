import React, { Fragment } from 'react'

export const NoDrawings = () => {
    return (
        <Fragment>
            <div className="esri-widget__content--empty">
                <span aria-hidden="true" className="esri-widget__no-bookmark-icon esri-icon-sketch-rectangle"></span>
                <h1 className="esri-widget__heading esri-bookmarks__no-bookmarks-heading">No Drawings</h1>
                <p className="esri-bookmarks__no-bookmarks-description">
                Add drawings to the map and they will appear here.
                </p>
            </div>
        </Fragment>
    )
}
