import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Loader from 'calcite-react/Loader';

import swal from 'sweetalert';
import MapIdentifyWithResults from '../functional/identify/mapIdentifyWithResults';
import { GIS_Base_Functions } from '../services/gis_service';

import List, {
  ListItem,
  ListHeader,
  ListItemTitle,
  ListItemSubtitle,
} from 'calcite-react/List';

const __stylePanelHeader = {
  position: 'relative',
  height: '45px',
  backgroundColor: '#f5f5f5',
  padding: '15px',
  textAlign: 'left',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderBottom: '1px solid #ddd',
};
const _identifyFooterToolbar = {
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: '#f5f5f5',
  padding: '10px',
  textAlign: 'left',
  borderTop: '1px solid #ddd',
};
const __styleClosePanelBtn = {
  position: 'absolute',
  width: '25px',
  top: '0',
  right: '0',
  height: '45px',
  fontWeight: '100',
  textAlign: 'center',
  cursor: 'pointer',
};
const __styleCloseBtnText = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
};

const _text_style = {
  fontSize: '.8rem',
};

const _text_info = {
  fontSize: '0.7rem',
  fontWeight: '300',
  margin: '0',
};

class WorkHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FACILITYID: this.props.attr.FACILITYID || this.props.attr.FacilityID,
      IsLoading: true,
      IsShowingNoResult: false,
      IsShowingResult: false,
      results: [],
    };
  }
  componentDidMount() {
    GIS_Base_Functions.GetWorkOrderHistory(this.state.FACILITYID)
      .then((res) => {
        this.setState({
          IsLoading: false,
          results: res.data,
        });
      })
      .catch((err) => console.log('Error fetching work history :: ', err));
  }
  _previous = () => {
    ReactDOM.render(
      <MapIdentifyWithResults
        view={this.props.view}
        appOptions={this.props.appOptions}
        location={this.props.location}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };

  closeWidgetIdentifyWindow = () => {
    document.getElementById('right-bar-wrapper').classList.add('hidden');
  };

  _navigateTo = (url) => {
    window.open(url, '_blank');
  };

  __renderWorkHistory() {
    if (this.state.IsLoading) {
      return (
        <div className="loading-wrapper">
          <Loader text="Fetching work history, please wait..." />
        </div>
      );
    } else {
      switch (this.state.results.length) {
        case 0:
          return (
            <div className="no-results-found">
              <div className="esri-widget__content--empty">
                <span
                  aria-hidden="true"
                  className="esri-widget__no-bookmark-icon esri-icon-notice-triangle"
                ></span>
                <h1 className="esri-widget__heading esri-bookmarks__no-bookmarks-heading">
                  No work history found
                </h1>
                <p className="esri-bookmarks__no-bookmarks-description">
                  There is currently no record of work done on this feature at
                  this time.
                </p>
              </div>
            </div>
          );
          break;

        default:
          return (
            <Fragment>
              <div className="result-found">
                <List>
                  {this.state.results.map((work, index) => (
                    <ListItem
                      key={index}
                      onClick={() =>
                        this._navigateTo(
                          `https://src.mansfieldcity.com/pg/mgmt/WorkOrder.asp?R=${work.csrid}&W=${work.woid}`
                        )
                      }
                    >
                      <ListItemTitle>{work.workType}</ListItemTitle>
                      <ListItemSubtitle>Status: {work.status}</ListItemSubtitle>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Fragment>
          );
          break;
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div id="layer-field-settings">
          <div className="panel-header" style={__stylePanelHeader}>
            <span id="left-panel-header-title">
              {this.props.layerName}: {this.state.FACILITYID}
            </span>
            {/* <p style={_text_info}>Viewing work history.</p> */}
            <div
              id="btnCloseRightPanel"
              style={__styleClosePanelBtn}
              onClick={this.closeWidgetIdentifyWindow}
            >
              <div style={__styleCloseBtnText} title="Close">
                X
              </div>
            </div>
          </div>
          <div
            id="layerInfoContentDisplay"
            className="identify-content-scroller"
          >
            {this.__renderWorkHistory()}
          </div>
          <div id="layerFooterDisplay" style={_identifyFooterToolbar}>
            <ButtonGroup>
              {/* <Button disabled={this.state.isSubmitting}
                                onClick={this._save}>
                                {this.state.isSubmitting ? 'Submitting.......' : 'Save'}
                            </Button> */}
              <Button onClick={this._previous}>View attributes</Button>
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WorkHistory;
