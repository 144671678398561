import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import { _mapViewAvailable } from '../../utils/map';
import UtilityToggler from '../togglers/UtilityToggler';
import Sketching from '../sketch/Sketching';

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup,
} from 'calcite-react/ActionBar';

import Button, { ButtonGroup } from 'calcite-react/Button';

import SearchIcon from 'calcite-ui-icons-react/SearchIcon';
import LayerHideIcon from 'calcite-ui-icons-react/LayerHideIcon';
import MeasureIcon from 'calcite-ui-icons-react/MeasureIcon';
import PointIcon from 'calcite-ui-icons-react/PointIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import AddInNewIcon from 'calcite-ui-icons-react/AddInNewIcon';
import HideEmptyIcon from 'calcite-ui-icons-react/HideEmptyIcon';
import MagnifyingGlassPlusIcon from 'calcite-ui-icons-react/MagnifyingGlassPlusIcon';
import RecentIcon from 'calcite-ui-icons-react/RecentIcon';
import EraseIcon from 'calcite-ui-icons-react/EraseIcon';

import swal from 'sweetalert';

import { _user_auth } from '../services/gis_service';
import { setgraphics } from '../../redux/reducers/graphics';
import DrawingTool from '../drawingTool/DrawingTool';

export class RightNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      activeAction: '',
      activeModule: null,
      isAuthorized: false,
    };
    this.onToggleCollapse = this.onToggleCollapse.bind(this);
    this.actionSelected = this.actionSelected.bind(this);
  }

  componentDidMount() {
    const __isAuth = _user_auth.isAuthorized();
    this.setState({ isAuthorized: __isAuth });
  }

  /* shouldComponentUpdate(nextProps, nextState) {
    for (let i in nextProps) {
      if (blacklist.includes(i)) continue;
      if (nextProps[i] !== this.props[i]) {
        return true;
      }
    }
    for (let i in nextState) {
      if (nextState[i] !== this.state[i]) {
        return true;
      }
    }
    return false;
  } */

  onToggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  __closeModule() {
    if (this.state.activeModule.iconClass === 'esri-icon-measure') {
      // remove measurements buttons
      document.getElementById('toolbarDiv').remove();
    }
    this.state.activeModule.destroy();
    this.setState({ activeModule: null });
    this.setState({ activeAction: '' });
  }

  actionSelected(activeAction) {
    // deactivate identify if any
    const btnIdentify = document.getElementById('mapIdentifyBtn');
    if (btnIdentify.classList.contains('map-identify-active')) {
      btnIdentify.classList.remove('map-identify-active');
      document.getElementById('map-view-container').style.cursor = 'auto';
    }

    this.setState({ activeAction });
    switch (activeAction) {
      case 'ADDRESS':
        if (this.state.activeModule && this.state.activeAction === 'ADDRESS') {
          this.__closeModule();
        } else {
          if (this.state.activeModule) {
            this.__destroyExisitingModule();
          }
          this.__createSearchModule();
        }
        break;

      case 'MEASURE':
        if (this.state.activeModule && this.state.activeAction === 'MEASURE') {
          this.__closeModule();
        } else {
          if (this.state.activeModule) {
            this.__destroyExisitingModule();
          }
          this.__createMeasurementsModule();
        }
        break;

      case 'LOCATION':
        if (this.state.activeModule && this.state.activeAction === 'LOCATION') {
          this.__closeModule();
        } else {
          if (this.state.activeModule) {
            this.__destroyExisitingModule();
          }
          this.__createLocationModule();
        }
        break;

      case 'UTLITYTOGGLER':
        if (
          this.state.activeModule &&
          this.state.activeAction === 'UTLITYTOGGLER'
        ) {
          this.__closeCustomModule('utilityTogglerDiv');
        } else {
          if (this.state.activeModule) {
            this.__destroyExisitingModule();
          }
          this.__createUtilityTogglerModule();
        }
        break;

      case 'DRAW':
        if (this.state.activeModule && this.state.activeAction === 'DRAW') {
          this.__closeCustomModule('sketchingDiv');
        } else {
          if (this.state.activeModule) {
            this.__destroyExisitingModule();
          }
          this.__createDrawModule();
        }
        break;
      case 'PRF':
        swal('The PRF will be an optional feature. Coming soon');
        /* if(this.state.activeModule && this.state.activeAction === 'DRAW') {
              this.__closeModule();
            } else{
              if(this.state.activeModule){
                this.__destroyExisitingModule();
              }
              this.__createDrawModule();
            } */
        break;
    }
  }

  __closeCustomModule(domID) {
    document.getElementById(domID).remove();
    this.setState({ activeModule: null });
    this.setState({ activeAction: '' });
  }

  __destroyExisitingModule() {
    if (this.state.activeModule.iconClass === 'esri-icon-measure') {
      // remove measurements buttons
      document.getElementById('toolbarDiv').remove();
    }
    this.setState({ activeModule: null });
    if (document.getElementById('utilityTogglerDiv')) {
      document.getElementById('utilityTogglerDiv').remove();
      return;
    }
    if (document.getElementById('sketchingDiv')) {
      document.getElementById('sketchingDiv').remove();
      return;
    }
    this.state.activeModule.destroy();
  }
  _areaMeasurement(distanceButton, areaButton) {
    this.state.activeModule.activeTool = 'area';
    areaButton.classList.add('active');
    distanceButton.classList.remove('active');
  }

  _distanceMeasurement(distanceButton, areaButton) {
    this.state.activeModule.activeTool = 'distance';
    distanceButton.classList.add('active');
    areaButton.classList.remove('active');
  }

  _clearMeasurements(distanceButton, areaButton) {
    distanceButton.classList.remove('active');
    areaButton.classList.remove('active');
    this.state.activeModule.clear();
  }

  __createMeasurementsModule() {
    loadModules(['esri/widgets/Measurement']).then(([Measurement]) => {
      // create buttons
      const distanceButton = document.createElement('button');
      distanceButton.setAttribute('id', 'distance');
      distanceButton.className =
        'esri-widget--button esri-interactive esri-icon-measure-line active';
      distanceButton.setAttribute('title', 'Distance Measurement Tool');

      const areaButton = document.createElement('button');
      areaButton.setAttribute('id', 'area');
      areaButton.setAttribute('title', 'Area Measurement Tool');
      areaButton.className =
        'esri-widget--button esri-interactive esri-icon-measure-area';

      const clearButton = document.createElement('button');
      clearButton.setAttribute('id', 'clear');
      clearButton.setAttribute('title', 'Clear Measurements');
      clearButton.className =
        'esri-widget--button esri-interactive esri-icon-trash';

      const toolbarDiv = document.createElement('div');
      toolbarDiv.setAttribute('id', 'toolbarDiv');
      toolbarDiv.className = 'esri-component esri-widget';

      toolbarDiv.appendChild(distanceButton);
      toolbarDiv.appendChild(areaButton);
      toolbarDiv.appendChild(clearButton);

      distanceButton.addEventListener('click', () => {
        this._distanceMeasurement(distanceButton, areaButton);
      });
      areaButton.addEventListener('click', () => {
        this._areaMeasurement(distanceButton, areaButton);
      });
      clearButton.addEventListener('click', () => {
        this._clearMeasurements(distanceButton, areaButton);
      });

      const measurementWidget = new Measurement({
        view: _mapViewAvailable(),
        activeTool: 'distance',
        linearUnit: 'feet',
        areaUnit: 'acres',
      });
      this.setState({ activeModule: measurementWidget });
      _mapViewAvailable().ui.add([
        {
          component: toolbarDiv,
          position: 'top-right',
          index: 0,
        },
        {
          component: this.state.activeModule,
          position: 'top-right',
          index: 1,
        },
      ]);
    });
  }
  __createLocationModule() {
    loadModules(['esri/widgets/CoordinateConversion']).then(
      ([CoordinateConversion]) => {
        const ccWidget = new CoordinateConversion({
          view: _mapViewAvailable(),
        });
        this.setState({ activeModule: ccWidget });
        _mapViewAvailable().ui.add(this.state.activeModule, {
          position: 'top-right',
        });
      }
    );
  }

  __createSearchModule() {
    loadModules([
      'esri/widgets/Search',
      'esri/tasks/Locator',
      'esri/layers/FeatureLayer',
    ]).then(([Search, Locator, FeatureLayer]) => {
      // add utility search within search
      //console.log(this.props.config);
      const sources = [
        {
          //locator: new Locator({ url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer" }),
          locator: new Locator({ url: this.props.config.CityAddressGeocoder }),
          singleLineFieldName: 'SingleLine',
          name: 'City Geocoding Service',
          placeholder: 'Search Address',
          maxResults: 20,
          maxSuggestions: 20,
          suggestionsEnabled: true,
          minSuggestCharacters: 0,
        },
        {
          layer: new FeatureLayer({
            url: `${this.props.config.utilitySearch.utilityFeatureServiceBaseUrl}/${this.props.config.utilitySearch.searchableLayers.Hydrant}`,
            outFields: ['FACILITYID'],
          }),
          searchFields: ['FACILITYID'],
          displayField: 'FACILITYID',
          exactMatch: false,
          outFields: ['FACILITYID'],
          name: 'Hydrant',
          placeholder: 'Search hydrant',
          maxResults: 6,
          maxSuggestions: 6,
          suggestionsEnabled: true,
          minSuggestCharacters: 0,
        },
        {
          layer: new FeatureLayer({
            url: `${this.props.config.utilitySearch.utilityFeatureServiceBaseUrl}/${this.props.config.utilitySearch.searchableLayers.WaterMain}`,
            outFields: ['FACILITYID'],
          }),
          searchFields: ['FACILITYID'],
          displayField: 'FACILITYID',
          exactMatch: false,
          outFields: ['FACILITYID'],
          name: 'Water Main',
          placeholder: 'Search Water main',
          maxResults: 6,
          maxSuggestions: 6,
          suggestionsEnabled: true,
          minSuggestCharacters: 0,
        },
        {
          layer: new FeatureLayer({
            url: `${this.props.config.utilitySearch.utilityFeatureServiceBaseUrl}/${this.props.config.utilitySearch.searchableLayers.SewerMain}`,
            outFields: ['FACILITYID'],
          }),
          searchFields: ['FACILITYID'],
          displayField: 'FACILITYID',
          exactMatch: false,
          outFields: ['FACILITYID'],
          name: 'Sanitary Main',  //hardcoded
          placeholder: 'Search sanitary gravity main',
          maxResults: 6,
          maxSuggestions: 6,
          suggestionsEnabled: true,
          minSuggestCharacters: 0,
        },
        {
          layer: new FeatureLayer({
            url: `${this.props.config.utilitySearch.utilityFeatureServiceBaseUrl}/${this.props.config.utilitySearch.searchableLayers.StormMain}`,
            outFields: ['FACILITYID'],
          }),
          searchFields: ['FACILITYID'],
          displayField: 'FACILITYID',
          exactMatch: false,
          outFields: ['FACILITYID'],
          name: 'Storm Main',  //hardcoded
          placeholder: 'Search storm gravity main',
          maxResults: 6,
          maxSuggestions: 6,
          suggestionsEnabled: true,
          minSuggestCharacters: 0,
        },
      ];

      const searchWidget = new Search({
        view: _mapViewAvailable(),
        sources: sources,
        includeDefaultSources: false,
      });

      this.setState({ activeModule: searchWidget });

      // Add the search widget to the top right corner of the view
      _mapViewAvailable().ui.add(this.state.activeModule, {
        position: 'top-right',
      });
    });
  }

  __createDrawModule() {
    const sketchingDiv = document.createElement('div');
    sketchingDiv.setAttribute('id', 'sketchingDiv');
    sketchingDiv.setAttribute('iconClass', 'utility-toggler-div');
    sketchingDiv.className = 'esri-component esri-widget';
    this.setState({ activeModule: sketchingDiv });
    _mapViewAvailable().ui.add(sketchingDiv, 'top-right');
    setTimeout(() => {
      ReactDOM.render(
        <DrawingTool
          view={_mapViewAvailable()}
          setUserGraphics={this.props.setUserGraphics}
        />,
        document.getElementById('sketchingDiv')
      );
    }, 50);
  }

  __createUtilityTogglerModule() {
    const utilityTogglerDiv = document.createElement('div');
    utilityTogglerDiv.setAttribute('id', 'utilityTogglerDiv');
    utilityTogglerDiv.setAttribute('iconClass', 'utility-toggler-div');
    utilityTogglerDiv.className = 'esri-component esri-widget';
    this.setState({ activeModule: utilityTogglerDiv });
    _mapViewAvailable().ui.add(utilityTogglerDiv, 'top-right');
    setTimeout(() => {
      ReactDOM.render(
        <UtilityToggler mapview={_mapViewAvailable()} />,
        document.getElementById('utilityTogglerDiv')
      );
    }, 50);
  }

  _(domID) {
    return document.getElementById(domID);
  }

  OpenWidgetWrapper() {
    this._('right-bar-wrapper').classList.remove('hidden');
  }
  CloseWidgetWrapper = () => {
    this._('right-bar-wrapper').classList.add('hidden');
  };

  _renderUtilityToggler() {
    if (this.state.isAuthorized) {
      return (
        <Action
          icon={<LayerHideIcon />}
          onClick={() => this.actionSelected('UTLITYTOGGLER')}
          active={this.state.activeAction === 'UTLITYTOGGLER'}
        >
          Utility Toggler
        </Action>
      );
    }
  }

  DeleteAllGraphicsButUsers = () => {
    const mapview = _mapViewAvailable();
    const parcelsNotificationGLayer =
      mapview.map.findLayerById('publicNotification');
    const bufferGraphicsLayer = mapview.map.findLayerById(
      'bufferGraphicsLayer'
    );

    parcelsNotificationGLayer.removeAll();
    bufferGraphicsLayer.removeAll();
  };

  render() {
    const docsModalZIndex = { zIndex: 1001 };
    const __stylePanelHeader = {
      position: 'relative',
      backgroundColor: '#f5f5f5',
      padding: '15px',
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      borderBottom: '1px solid #ddd',
    };
    const __styleClosePanelBtn = {
      position: 'absolute',
      width: '25px',
      top: '0',
      right: '0',
      height: '100%',
      fontWeight: '100',
      textAlign: 'center',
      cursor: 'pointer',
    };
    const __styleCloseBtnText = {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    };
    const _panelInfoWindow = {
      position: 'relative',
      boxShadow: '0 0 1px rgba(0,0,0,.3)',
      background: '#fff',
    };
    const __styleLayerSettingsBtn = {
      position: 'absolute',
      width: '25px',
      top: '0',
      right: '30px',
      height: '100%',
      fontWeight: '100',
      textAlign: 'center',
      cursor: 'pointer',
    };
    const _identifyFooterToolbar = {
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      right: '0px',
      backgroundColor: '#f5f5f5',
      padding: '10px',
      textAlign: 'left',
      borderTop: '1px solid #ddd',
    };
    return (
      <Fragment>
        <aside style={_panelInfoWindow}>
          <div
            id="right-bar-wrapper"
            style={{ width: '370px' }}
            className="hidden"
          ></div>
        </aside>
        <aside>
          <ActionBar
            position="end"
            collapsed={this.state.collapsed}
            onToggleCollapse={this.onToggleCollapse}
          >
            <ActionGroup>
              <Action
                icon={<SearchIcon />}
                onClick={() => this.actionSelected('ADDRESS')}
                active={this.state.activeAction === 'ADDRESS'}
              >
                Search Address
              </Action>
              {
                // this._renderUtilityToggler()
              }
              <Action
                icon={<MeasureIcon />}
                onClick={() => this.actionSelected('MEASURE')}
                active={this.state.activeAction === 'MEASURE'}
              >
                Measure
              </Action>

              <Action
                icon={<PointIcon />}
                onClick={() => this.actionSelected('LOCATION')}
                active={this.state.activeAction === 'LOCATION'}
              >
                Location
              </Action>
            </ActionGroup>
            <ActionGroup>
              <Action
                icon={<PencilIcon />}
                onClick={() => this.actionSelected('DRAW')}
                active={this.state.activeAction === 'DRAW'}
                id="btnDraw"
              >
                Draw
              </Action>

              <Action
                icon={<EraseIcon />}
                onClick={this.DeleteAllGraphicsButUsers}
              >
                Erase Graphics
              </Action>
            </ActionGroup>
          </ActionBar>
        </aside>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserGraphics: (gr) => dispatch(setgraphics(gr)),
  };
};
/* const mapDispatchToProps = (dispatch) => ({
    setUserGraphics: (graphics) => dispatch(setgraphics(graphics)),
  }); */
/* const mapDispatchToProps = {
    setUserGraphics: setgraphics,
  }; */

export default connect(mapStateToProps, mapDispatchToProps)(RightNav);
