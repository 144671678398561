import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loadModules } from "esri-loader";
import { _mapView } from '../../utils/map';

import Switch from 'calcite-react/Switch';
import Panel, {
    PanelTitle,
    PanelText
  } from 'calcite-react/Panel';

class Print extends Component {
    constructor(props) {
        super(props)
        this.state = {
            printServiceURL: this.props.config.printService,
            print: null
        }
    }
    componentDidMount() {
        _mapView().then((view) => {
            loadModules(['esri/widgets/Print']).then(([Print]) => {
                const print = new Print({
                    view: view,
                    includeDefaultTemplates: false,
                    printServiceUrl: this.state.printServiceURL,
                    container: 'printWidget',
                    templateOptions: {
                        legendEnabled:false
                    },
                  });
                this.setState({print: print});

                print.on('submit', () => { // disable the export on click
                    /* const _exportBtn = document.querySelector('.esri-print__export-button');
                    if(_exportBtn){
                        _exportBtn.disabled = true;
                        _exportBtn.setAttribute('disabled', true);
                    }
                    console.log('submitting...'); */
                })
                print.on('complete', () => { // disable the export on click
                    //console.log('print completed!');
                })
            })
        })
    }

    componentDidUpdate() {
        if(this.state.print !== null) {
            this.state.print.printServiceUrl = this.state.printServiceURL;
        }
    }

    __setPrintServiceURL (isTrufy) {
        if(isTrufy) {
            this.setState({printServiceURL: this.props.config.printServiceDisclaimer});
        } else{
            this.setState({printServiceURL: this.props.config.printService})
        }  
    } 

    render() {
        return (
            <Fragment>
                <div className="left-panel-content-scroller">
                    <div id="printWidget"></div>
{/*                    <div>
                        <Panel>
                            <PanelText>
                                <Switch onClick={(e) => this.__setPrintServiceURL(e.target.checked) }> <span style={{marginLeft: "10px"}}>Include disclaimer</span></Switch>
                            </PanelText>
                        </Panel>
        </div>   */}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      config: state.config
    };
  };
export default connect(mapStateToProps)(Print);

