import React, { Component, Fragment } from 'react';
import DrawingItem from './DrawingItem';
import Button, { ButtonGroup } from 'calcite-react/Button';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import ViewHideIcon from 'calcite-ui-icons-react/ViewHideIcon';
import ViewVisibleIcon from 'calcite-ui-icons-react/ViewVisibleIcon';
import { connect } from 'react-redux';
import { setgraphics } from '../../../redux/reducers/graphics';
import { NoDrawings } from './NoDrawings';

//const __goBackBtn = {
//  textAlign: 'left',
//  margin: '10px',
//};

const __scrollablePanel = {
  position: 'absolute',
  top: '55px',
  bottom: '55px',
  width: '100%',
  overflow: 'auto',
};
const __footerPanel = {
  position: 'absolute',
  bottom: '10px',
  width: '100%',
};

class UserDrawings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphicsLayer: this.props.graphicsLayer,
      isShowingAllUserGraphics: true,
    };
  }
  componentDidMount() {
    console.log(this.props);
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  hideShowAllGraphics = () => {
    this.setState({
      isShowingAllUserGraphics: !this.state.isShowingAllUserGraphics,
    });
    setTimeout(() => {
      this.state.graphicsLayer.graphics.items.map(
        (graphic) => (graphic.visible = this.state.isShowingAllUserGraphics)
      );
      this.__toggle_cards_visibility();
    }, 50);
  };

  __toggle_cards_visibility = () => {
    let _inputs = document.getElementsByName('graphics-visibility-controller');

    for (let index = 0; index < _inputs.length; index++) {
      const chkbox = _inputs[index];

      chkbox.checked = this.state.isShowingAllUserGraphics;
    }
  };

  deleteAllGraphics = () => {
    this.props.deleteAllGraphics();
  };

  render() {
    return (
      <Fragment>
        <div style={__scrollablePanel}>
          <div>
            {this.props.userGraphics.length > 0 && (
              <>
                {this.props.userGraphics.map((f, index) => (
                  <DrawingItem
                    key={index}
                    drawingObj={f}
                    graphicsLayer={this.props.graphicsLayer}
                    mapview={this.props.mapview}
                    deleteSingleGraphic={this.props.deleteSingleGraphic}
                  />
                ))}
              </>
            )}

            {this.props.userGraphics.length === 0 && <NoDrawings />}
          </div>
        </div>
        <div className="drawings-footer" style={__footerPanel}>
          <ButtonGroup>
            <Button red extraSmall onClick={this.deleteAllGraphics}>
              <TrashIcon /> Delete All
            </Button>
            <Button
              extraSmall
              onClick={this.hideShowAllGraphics}
              style={{
                display: this.state.isShowingAllUserGraphics ? 'block' : 'none',
              }}
            >
              <ViewHideIcon /> Hide All
            </Button>
            <Button
              extraSmall
              onClick={this.hideShowAllGraphics}
              style={{
                display: !this.state.isShowingAllUserGraphics
                  ? 'block'
                  : 'none',
              }}
            >
              <ViewVisibleIcon /> Show All
            </Button>
          </ButtonGroup>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userGraphics: state.graphics.value,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setUserGraphics: (gr) => dispatch(setgraphics(gr)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawings);
