import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import { saveAs } from 'file-saver/dist/FileSaver';
import Draggable from 'react-draggable';
import Button, { ButtonGroup } from 'calcite-react/Button';
import DownloadToIcon from 'calcite-ui-icons-react/DownloadToIcon';
import PlusSquareIcon from 'calcite-ui-icons-react/PlusSquareIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import DeepLearningProjectIcon from 'calcite-ui-icons-react/DeepLearningProjectIcon';
import AutomationIcon from 'calcite-ui-icons-react/AutomationIcon';
import Loader from 'calcite-react/Loader';
import swal from 'sweetalert';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

//import { _mapViewAvailable } from '../../utils/map';

import '../../styles/draggable-report.css';
import EditModal from './EditModal';
import AddNewFlowTest from './AddNewFlowTest';
//import { _flow_rate_updater, _gis_service } from '../services/gis_service';
import { _flow_rate_updater } from '../services/gis_service';
import { CrudOperations } from '../services/CRUDoperations.service';

const Entity = 'HydrantFlowTest';

const modalContainer = document.createElement('DIV');

class HydrantFlowTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FACILITYID: this.props.feature.attributes.FacilityID,
      STATUS: this.props.feature.attributes['Active/Inactive Status'],
      WORKORDERURL: '#',
      layerName: this.props.feature.attributes.layerName,
      IsLoading: true,
      IsShowingNoResult: false,
      IsShowingResult: false,
      results: [],
      isSubmitting: false,
    };
  }

  componentDidMount() {
    loadModules([
      'esri/geometry/SpatialReference',
      'esri/tasks/GeometryService',
      'esri/rest/support/ProjectParameters',  //tasks to rest DMM 9/22/2022
    ]).then(([SpatialReference, GeometryService, ProjectParameters]) => {
      var geomSer = new GeometryService(this.props.config.geometryService);
      var params = new ProjectParameters({
        geometries: [this.props.feature.geometry],
        outSpatialReference: new SpatialReference({
          wkid: 102100,
          wkt: 'toJSON()',
        }),
      });

      geomSer.project(params).then((res) => {
        const location = { lat: res[0].latitude, lng: res[0].longitude };

        CrudOperations.GetByFID(Entity, this.state.FACILITYID)
          .then((res) => {
            // console.log(res);
            this.setState({
              IsLoading: false,
              results: res.data,
              IsShowingNoResult: res.data.length > 0 ? false : true,
              IsShowingResult: res.data.length > 0 ? true : false,
              WORKORDERURL: `https://src.mansfieldcity.com/pg/mgmt/quick.asp?fc=${this.state.layerName}&fID=${this.state.FACILITYID}&lat=${location.lat}&lng=${location.lng}&map1=1`,
            });
          })
          .catch((err) => console.log('Error fetching flow tests :: ', err));
      });
    });
  }

  _getFlowTests = () => {
    CrudOperations.GetByFID(Entity, this.state.FACILITYID)
      .then((res) => {
        this.setState({
          IsLoading: false,
          results: res.data,
          IsShowingNoResult: res.data.length > 0 ? false : true,
          IsShowingResult: res.data.length > 0 ? true : false,
        });
      })
      .catch((err) => console.log('Error fetching flow tests :: ', err));
  };

  downloadFile = (data, reportName = 'GIS_flowtest_report') => {
    // console.log(data);
    const replacer = (key, value) => (value === null ? 'Not available' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, `${reportName}.csv`);
  };
  __close_report = () => {
    this.props.mapview.ui.remove('center-draggable-wrapper');
  };
  __record_new_flow_test = () => {
    alert('adding new flow test form...');
  };

  _downloadCurrentFlowtests = () => {
    this.downloadFile(
      this.state.results,
      `FID_${this.state.FACILITYID}_flowtests`
    );
  };
  _downloadAllFlowtests = () => {
    this.setState({
      isSubmitting: true,
    });
    CrudOperations.Get(Entity)
      .then((_flowTests) => {
        this.downloadFile(_flowTests.data, `All_flowtests`);
        this.setState({
          isSubmitting: false,
        });
      })
      .catch((err) => {
        console.log('Error fetching all flow tests from server :: ', err);
        swal('Error fetching all flow tests from server. Please try again');
      });
  };

  handleEdit = (row) => {
    const { hydrantFlayer } = this.props.config.managedFlayerConfig;
    ReactDOM.render(
      <EditModal
        flowtest={row}
        updateUI={this._save_flowtest_update}
        close={this.closeEditForm}
        hydrantFlayer={hydrantFlayer}
      />,
      document.body.appendChild(modalContainer)
    );
  };
  handleDelete = (row) => {
    swal({
      text: `You are about to delete this record. is this correct?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then(async (action) => {
      if (action) {
        // delete record and reload data
        CrudOperations.Delete(Entity, row.hydrantflowtestid)
          .then((delete_resp) => {
            // update the flow test
            const { hydrantFlayer } = this.props.config.managedFlayerConfig;
            _flow_rate_updater
              .updateHydrantFlowRate(this.state.FACILITYID, hydrantFlayer)
              .then((new_flow_rate) => {
                this.props._updateFlowRate(new_flow_rate);
                this._getFlowTests();
                swal('Record deleted successfully!');
              });
          })
          .catch((error) => console.log('Error deleting flow test :: ', error));
      }
    });
  };

  _save_flowtest_update = (flow) => {
    if (
      !document.getElementById('right-bar-wrapper').classList.contains('hidden')
    ) {
      this.props._updateFlowRate(flow);
    }
    this.closeEditForm();
    this._getFlowTests();
    swal('edits saved successfully!');
  };

  closeEditForm = () => {
    ReactDOM.unmountComponentAtNode(modalContainer);
  };

  _goToCreateNewFlowTest = () => {
    this.__close_report();
    if (
      document.getElementById('right-bar-wrapper').classList.contains('hidden')
    ) {
      document.getElementById('right-bar-wrapper').classList.remove('hidden');
    }
    ReactDOM.render(
      <AddNewFlowTest
        FACILITYID={this.state.FACILITYID}
        config={this.props.config}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };

  formatDate = (d) => {
    return new Date(d).toLocaleDateString();
  };

  render() {
    const columns = [
      {
        Header: 'ID',
        accessor: 'hydrantflowtestid',
      },
      {
        id: 'testdatetime',
        Header: 'DATE',
        accessor: (row) => {
          return this.formatDate(row.testdatetime);
        },
        //Cell: (row) => {return this.formatDate(row.testdatetime)},
        sortMethod: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: 'FLOW',
        accessor: 'flow',
      },
      {
        Header: 'SP',
        accessor: 'staticpressure',
      },
      {
        Header: 'RP',
        accessor: 'residualpressure',
      },
      {
        Header: 'COMMENTS',
        accessor: 'comment',
      },
      {
        Header: 'INSPECTOR',
        accessor: 'inspector',
      },
      {
        Header: '',
        Cell: (row) => (
          <div>
            <PencilIcon
              size={16}
              onClick={() => this.handleEdit(row.original)}
            />
            <span style={{ margin: '0 10px' }}>|</span>
            <TrashIcon
              size={16}
              onClick={() => this.handleDelete(row.original)}
            />
          </div>
        ),
      },
    ];
    return (
      <Fragment>
        <Draggable handle=".center-draggable-header">
          <div className="center-draggable-wrapper">
            <div className="center-draggable-header">
              <h3>
                <span>
                  <AutomationIcon />
                </span>{' '}
                Flow test for hydrant: {this.state.FACILITYID}
              </h3>
              <div
                className="btn-close-draggable-wrapper"
                title="close"
                onClick={this.__close_report}
              >
                X
              </div>
            </div>
            <div className="center-draggable-content">
              <div
                className="loading-wrapper"
                style={{ display: this.state.IsLoading ? 'block' : 'none' }}
              >
                <Loader text="Fetching flow tests, please wait..." />
              </div>
              <div
                className="no-results-found"
                style={{
                  display: this.state.IsShowingNoResult ? 'block' : 'none',
                }}
              >
                <div className="draggable-content-toolbar">
                  <ButtonGroup>
                    <Button
                      green
                      onClick={this._goToCreateNewFlowTest}
                      icon={<PlusSquareIcon size={16} />}
                    >
                      Add Flow test
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="main-draggable-content">
                  <div className="esri-widget__content--empty">
                    <span
                      aria-hidden="true"
                      className="esri-widget__no-bookmark-icon esri-icon-notice-triangle"
                    ></span>
                    <h1 className="esri-widget__heading esri-bookmarks__no-bookmarks-heading">
                      No data found
                    </h1>
                    <p className="esri-bookmarks__no-bookmarks-description">
                      This hydrant has no flow test data at this time.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="result-found"
                style={{
                  display: this.state.IsShowingResult ? 'block' : 'none',
                }}
              >
                <div className="draggable-content-toolbar">
                  <ButtonGroup>
                    <Button
                      green
                      onClick={this._goToCreateNewFlowTest}
                      icon={<PlusSquareIcon size={16} />}
                    >
                      Add Flow test
                    </Button>
                    <Button
                      disabled={this.state.isSubmitting}
                      onClick={this._downloadAllFlowtests}
                      icon={<DownloadToIcon size={16} />}
                    >
                      {this.state.isSubmitting
                        ? 'Downloading.......'
                        : 'Download all flow tests'}
                    </Button>

                    <Button
                      onClick={this._downloadCurrentFlowtests}
                      icon={<DownloadToIcon size={16} />}
                    >
                      Download current list
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="main-draggable-content">
                  <ReactTable
                    columns={columns}
                    data={this.state.results}
                    defaultPageSize={5}
                    style={{
                      height: '300px', // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                  ></ReactTable>
                </div>
              </div>
            </div>
            <div className="report-footer">
              <div style={{ position: 'relative' }}>
                <Button
                  href={this.state.WORKORDERURL}
                  target="_blank"
                  onClick={() => console.log('clicked')}
                  icon={<DeepLearningProjectIcon size={16} />}
                >
                  Create Work Order
                </Button>
                <div className="active-status-wrapper">
                  Hydrant Status: <b>{this.state.STATUS}</b>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </Fragment>
    );
  }
}

export default HydrantFlowTests;
