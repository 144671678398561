// React imports
import React, { useContext } from 'react';
import { loadModules } from 'esri-loader';
// ESRI ArcGIS API
import { loadMap } from '../../../utils/map';
// Styled Components
import styled from 'styled-components';

// import { GlobalContext } from '../../../redux/context/GlobalState';

const DRAWINGS_KEY = 'arcgis-local-drawings';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

// Component
/* const areEqual = (prevProps, nextProps) => {
  console.log('PrevProps :: ', prevProps);
  console.log('nextProps :: ', nextProps);
  for (let i in nextState) {
    if (nextState[i] !== this.state[i]) {
      return true;
    }
  }
  return false;
}; */

const Map = React.memo((props) => {
  //const { addGraphic } = useContext(GlobalContext);
  // set an ID for the map to attach to
  const containerID = 'map-view-container';
  // load map with config properties, street basemap, Layers
  loadMap(containerID, props.appConfig).then((view) => {
    // call the map loaded event when we get the map view back
    props.onMapLoaded();

    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const existingGraphics = localStorage.getItem(DRAWINGS_KEY);
      if (existingGraphics !== undefined && existingGraphics !== null) {
        const _userSavedGraphics = JSON.parse(existingGraphics);
        if (_userSavedGraphics.length > 0) {
          const userGLayer = view.map.findLayerById('userGraphics');
          _userSavedGraphics.map((g) => {
            const __gr = Graphic.fromJSON(g);
            //_graphics_array.push(__gr);
            if (userGLayer !== null) {
              userGLayer.graphics.push(__gr);
              // add graphic to global graphic array
              //addGraphic(__gr);
            }
          });
        }
      }
    });
  });

  // Compnent template
  return <Container id={containerID}></Container>;
});

export default Map;
