import { loadModules } from "esri-loader";
import React, { useEffect } from "react";

const DRAWINGS_KEY = "arcgis-local-drawings";

const options = { version: "4.22", css: false };

const DrawingTool = ({ view, setUserGraphics }) => {
  const __dispatchEvent = (evt) => {
    //console.log(evt);
    if (evt.state === "complete") {
      const graphicsLayer = view.map.findLayerById("userGraphics");
      const { graphics } = graphicsLayer;
      __savePersistentGraphics(graphics.items);
    }
  };

  const __savePersistentGraphics = (graphics) => {
    if (localStorage.getItem(DRAWINGS_KEY) !== undefined) {
      localStorage.removeItem(DRAWINGS_KEY);
    }
    if (graphics.length > 0) {
      localStorage.setItem(DRAWINGS_KEY, JSON.stringify(graphics));
    }
  };

  const initSketchViewModel = () => {
    loadModules(
      ["esri/widgets/Sketch/SketchViewModel", "esri/widgets/Expand"],
      options
    ).then(([SketchViewModel, Expand]) => {
      const stylerExpand = new Expand({
        view: view,
        content: document.getElementById("propPanel"),
        expanded: true,
        expandIconClass: "esri-icon-edit",
        expandTooltip: "Open Styler",
      });

      const graphicsLayer = view.map.findLayerById("userGraphics");
      //setGraphicsLayer(graphicsLayer);

      const sketchVM = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
        container: "propPanel",
      });

      // dispatch events
      sketchVM.on("create", __dispatchEvent);
      sketchVM.on("delete", __dispatchEvent);
      sketchVM.on("update", __dispatchEvent);

      view.when(() => {
        // Configure the UI to use the default property values from our SketchViewModel
        setDefaultCreateOptions(sketchVM);
        setDefaultUpdateOptions(sketchVM);
        setDefaultPointSymbol(sketchVM);
        setDefaultPolylineSymbol(sketchVM);
        setDefaultPolygonSymbol(sketchVM);
      });

      //view.ui.add(stylerExpand, "top-right");
      initActionButtons(sketchVM);
    });
  };

  const initActionButtons = (sketchVM) => {
    // Connecting the calcite actions with their corresponding SketchViewModel tools
    const pointBtn = document.getElementById("pointBtn");
    const polylineBtn = document.getElementById("polylineBtn");
    const polygonBtn = document.getElementById("polygonBtn");
    const circleBtn = document.getElementById("circleBtn");
    const rectangleBtn = document.getElementById("rectangleBtn");
    const clearBtn = document.getElementById("clearBtn");
    const selectBtn = document.getElementById("selectBtn");

    pointBtn.onclick = () => {
      sketchVM.create("point");
    };
    polylineBtn.onclick = () => {
      sketchVM.create("polyline");
    };
    polygonBtn.onclick = () => {
      sketchVM.create("polygon");
    };
    circleBtn.onclick = () => {
      sketchVM.create("circle");
    };
    rectangleBtn.onclick = () => {
      sketchVM.create("rectangle");
    };
    clearBtn.onclick = () => {
      sketchVM.layer.removeAll();
    };
    selectBtn.onclick = () => {
      sketchVM.cancel();
    };
  };

  useEffect(() => {
    initSketchViewModel();
  }, []);

  // Calcite UI logic
  // Auto-populate UI with default SketchViewModel properties set.
  // If no default values are set, UI will be set accordingly.
  const setDefaultCreateOptions = (sketchVM) => {
    const options = sketchVM.defaultCreateOptions;
    const modeSelect = document.getElementById("mode-select");

    // set default mode in the select element if defined
    if (options?.mode) {
      setDefaultOption(modeSelect, options.mode);
    }

    // handles mode select changes
    modeSelect.addEventListener("calciteSelectChange", () => {
      sketchVM.defaultCreateOptions["mode"] = modeSelect.selectedOption.value;
    });
  };

  const setDefaultUpdateOptions = (sketchVM) => {
    const options = sketchVM.defaultUpdateOptions;
    const rotationSwitch = document.getElementById("rotationSwitch");
    const scaleSwitch = document.getElementById("scaleSwitch");
    const multipleSelectionSwitch = document.getElementById(
      "multipleSelectionSwitch"
    );
    const aspectRatioSwitch = document.getElementById("aspectRatioSwitch");

    // set the UI elements to the default property values
    rotationSwitch.switched = options.enableRotation;
    scaleSwitch.switched = options.enableScaling;
    multipleSelectionSwitch.switched = options.multipleSelectionEnabled;
    aspectRatioSwitch.switched = options.preserveAspectRatio;

    // event listeners for UI interactions
    rotationSwitch.addEventListener("calciteSwitchChange", (evt) => {
      sketchVM.defaultUpdateOptions.enableRotation = evt.target.switched;
    });
    scaleSwitch.addEventListener("calciteSwitchChange", (evt) => {
      sketchVM.defaultUpdateOptions.enableScaling = evt.target.switched;
    });
    multipleSelectionSwitch.addEventListener("calciteSwitchChange", (evt) => {
      sketchVM.defaultUpdateOptions.multipleSelectionEnabled =
        evt.target.switched;
    });
    aspectRatioSwitch.addEventListener("calciteSwitchChange", (evt) => {
      sketchVM.defaultUpdateOptions.preserveAspectRatio = evt.target.switched;
    });
  };

  const setDefaultPointSymbol = (sketchVM) => {
    const pointSymbol = sketchVM.pointSymbol;
    const pointStyleSelect = document.getElementById("point-style-select");
    const pointSymbolOutlineBtn = document.getElementById("point-outline-btn");
    const pointSizeInput = document.getElementById("point-size-input");
    const pointXOffsetInput = document.getElementById("point-xoffset-input");
    const pointYOffsetInput = document.getElementById("point-yoffset-input");
    const pointAngleInput = document.getElementById("point-angle-input");
    const pointColorInput = document.getElementById("point-color-input");
    const slsWidthInput = document.getElementById("point-sls-width-input");
    const slsColorInput = document.getElementById("point-sls-color-input");

    pointSizeInput.value = pointSymbol.size;
    pointXOffsetInput.value = pointSymbol.xoffset;
    pointYOffsetInput.value = pointSymbol.yoffset;
    pointAngleInput.value = pointSymbol.angle;
    slsWidthInput.value = pointSymbol.outline.width;

    // set default style in the select element
    setDefaultOption(pointStyleSelect, pointSymbol.style);

    pointSizeInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.size = parseInt(evt.target.value);
    });
    pointXOffsetInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.xoffset = parseInt(evt.target.value);
    });
    pointYOffsetInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.yoffset = parseInt(evt.target.value);
    });
    pointAngleInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.angle = parseInt(evt.target.value);
    });
    pointStyleSelect.addEventListener("calciteSelectChange", () => {
      pointSymbol.style = pointStyleSelect.selectedOption.value;
    });
    pointColorInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.color = evt.target.value;
    });
    pointSymbolOutlineBtn.onclick = () => {
      openModal("point-outline-modal");
    };
    // point outline modal event listeners
    slsWidthInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.outline.width = parseInt(evt.target.value);
    });
    slsColorInput.addEventListener("calciteInputInput", (evt) => {
      pointSymbol.outline.color = evt.target.value;
    });
  };

  const setDefaultPolylineSymbol = (sketchVM) => {
    const lineSymbol = sketchVM.polylineSymbol;
    const lineStyleSelect = document.getElementById("line-style-select");
    const lineWidthInput = document.getElementById("line-width-input");
    const lineColorInput = document.getElementById("line-color-input");

    lineWidthInput.value = lineSymbol.width;

    // set default style in the select element
    setDefaultOption(lineStyleSelect, lineSymbol.style);

    lineStyleSelect.addEventListener("calciteSelectChange", () => {
      lineSymbol.style = lineStyleSelect.selectedOption.value;
    });
    lineWidthInput.addEventListener("calciteInputInput", (evt) => {
      lineSymbol.width = parseInt(evt.target.value);
    });
    lineColorInput.addEventListener("calciteInputInput", (evt) => {
      lineSymbol.color = evt.target.value;
    });
  };

  const hexToRgba = (hex, opacity) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    return null;
  };

  const setDefaultPolygonSymbol = (sketchVM) => {
    const polygonSymbol = sketchVM.polygonSymbol;
    const polygonStyleSelect = document.getElementById("polygon-style-select");
    const polygonSymbolOutlineBtn = document.getElementById(
      "polygon-outline-btn"
    );
    const polygonColorInput = document.getElementById("polygon-color-input");
    const slsStyleSelect = document.getElementById("polygon-sls-style-select");
    const slsWidthInput = document.getElementById("polygon-sls-width-input");
    const slsColorInput = document.getElementById("polygon-sls-color-input");
    //const slsOpacityInput = document.getElementById("polygon-opacity-input");
    slsWidthInput.value = polygonSymbol.outline.width;
    // set default style in the select element
    setDefaultOption(polygonStyleSelect, polygonSymbol.style);
    setDefaultOption(slsStyleSelect, polygonSymbol.outline.style);

    polygonStyleSelect.addEventListener("calciteSelectChange", () => {
      polygonSymbol.style = polygonStyleSelect.selectedOption.value;
    });
    polygonColorInput.addEventListener("calciteInputInput", (evt) => {
      //console.log(evt.target.value);
      polygonSymbol.color = evt.target.value; // "rgba(30, 43, 204, 0.17)"; //
    });
    polygonSymbolOutlineBtn.onclick = () => {
      openModal("polygon-outline-modal");
    };
    // polygon outline modal event listeners
    slsStyleSelect.addEventListener("calciteSelectChange", () => {
      polygonSymbol.outline.style = slsStyleSelect.selectedOption.value;
    });
    slsWidthInput.addEventListener("calciteInputInput", (evt) => {
      polygonSymbol.outline.width = parseInt(evt.target.value);
    });
    slsColorInput.addEventListener("calciteInputInput", (evt) => {
      polygonSymbol.outline.color = evt.target.value;
    });
    /* slsOpacityInput.addEventListener("calciteSliderChange", (evt) => {
      console.log(evt.target.value);
    }); */
  };

  // function to auto-populate calcite select components
  const setDefaultOption = (selectElement, value) => {
    for (let i = 0; i < selectElement.children.length; i++) {
      let option = selectElement.children[i];
      if (option.value === value) {
        option.selected = true;
      }
    }
  };

  // displays the appropriate modals
  const openModal = (id) => {
    document.getElementById(id).active = true;
  };

  return (
    <>
      <calcite-panel heading="Map Drawing" id="propPanel">
        <calcite-block id="headingBlock">
          <calcite-action
            icon="cursor"
            title="Select graphic"
            scale="s"
            slot="control"
            id="selectBtn"
          ></calcite-action>
          <calcite-action
            icon="pin"
            title="Draw a point"
            scale="s"
            slot="control"
            id="pointBtn"
          ></calcite-action>
          <calcite-action
            icon="line"
            title="Draw a polyline"
            scale="s"
            slot="control"
            id="polylineBtn"
          ></calcite-action>
          <calcite-action
            icon="polygon"
            title="Draw a polygon"
            scale="s"
            slot="control"
            id="polygonBtn"
          ></calcite-action>
          <calcite-action
            icon="rectangle"
            title="Draw a rectangle"
            scale="s"
            slot="control"
            id="rectangleBtn"
          ></calcite-action>
          <calcite-action
            icon="circle"
            title="Draw a circle"
            scale="s"
            slot="control"
            id="circleBtn"
          ></calcite-action>
          <calcite-action
            icon="trash"
            title="Clear graphics"
            scale="s"
            slot="control"
            id="clearBtn"
          ></calcite-action>
        </calcite-block>
        <calcite-accordion selection-mode="single">
          {/* defaultCreateOptions */}
          <calcite-accordion-item item-subtitle="defaultCreateOptions">
            <calcite-label>
              mode
              <calcite-select scale="s" id="mode-select">
                <calcite-option value="" selected disabled>
                  choose mode
                </calcite-option>
                <calcite-option value="click">click</calcite-option>
                <calcite-option value="freehand">freehand</calcite-option>
                <calcite-option value="hybrid">hybrid</calcite-option>
              </calcite-select>
            </calcite-label>
          </calcite-accordion-item>

          {/* defaulteUpdateOptions */}
          <calcite-accordion-item item-subtitle="defaultUpdateOptions">
            <calcite-label>
              enableRotation
              <calcite-label layout="inline">
                True
                <calcite-switch
                  scale="s"
                  dir="rtl"
                  id="rotationSwitch"
                ></calcite-switch>
                False
              </calcite-label>
            </calcite-label>
            <calcite-label>
              enableScaling
              <calcite-label layout="inline">
                True
                <calcite-switch
                  scale="s"
                  dir="rtl"
                  id="scaleSwitch"
                ></calcite-switch>
                False
              </calcite-label>
            </calcite-label>
            <calcite-label>
              preserveAspectRatio
              <calcite-label layout="inline">
                True
                <calcite-switch
                  scale="s"
                  dir="rtl"
                  id="aspectRatioSwitch"
                ></calcite-switch>
                False
              </calcite-label>
            </calcite-label>
            <calcite-label>
              multipleSelectionEnabled
              <calcite-label layout="inline">
                True
                <calcite-switch
                  scale="s"
                  dir="rtl"
                  id="multipleSelectionSwitch"
                ></calcite-switch>
                False
              </calcite-label>
            </calcite-label>
          </calcite-accordion-item>

          {/* pointSymbol */}
          <calcite-accordion-item item-subtitle="pointSymbol">
            <div className="scrollSection">
              <calcite-label>
                style
                <calcite-select scale="s" id="point-style-select">
                  <calcite-option value="circle" selected>
                    circle
                  </calcite-option>
                  <calcite-option value="cross">cross</calcite-option>
                  <calcite-option value="diamond">diamond</calcite-option>
                  <calcite-option value="square">square</calcite-option>
                  <calcite-option value="x">x</calcite-option>
                </calcite-select>
              </calcite-label>
              <calcite-label>
                color
                <calcite-input
                  placeholder="Placeholder"
                  type="color"
                  scale="s"
                  id="point-color-input"
                ></calcite-input>
              </calcite-label>
              <calcite-label>
                outline
                <calcite-button id="point-outline-btn" scale="s">
                  Set Outline
                </calcite-button>
              </calcite-label>
              <calcite-label>
                size
                <calcite-input
                  id="point-size-input"
                  placeholder="Placeholder"
                  type="number"
                  scale="s"
                  min="0"
                ></calcite-input>
              </calcite-label>
              <calcite-label>
                xoffset
                <calcite-input
                  id="point-xoffset-input"
                  placeholder="Placeholder"
                  type="number"
                  scale="s"
                ></calcite-input>
              </calcite-label>
              <calcite-label>
                yoffset
                <calcite-input
                  id="point-yoffset-input"
                  placeholder="Placeholder"
                  type="number"
                  scale="s"
                ></calcite-input>
              </calcite-label>
              <calcite-label>
                angle
                <calcite-input
                  id="point-angle-input"
                  placeholder="Placeholder"
                  type="number"
                  scale="s"
                  min="-360"
                  max="360"
                ></calcite-input>
              </calcite-label>
            </div>
          </calcite-accordion-item>

          {/* polygonSymbol */}
          <calcite-accordion-item item-subtitle="polygonSymbol">
            <calcite-label>
              style
              <calcite-select scale="s" id="polygon-style-select">
                <calcite-option disabled selected id="blankOption">
                  choose a style
                </calcite-option>
                <calcite-option value="backward-diagonal">
                  backward-diagonal
                </calcite-option>
                <calcite-option value="cross">cross</calcite-option>
                <calcite-option value="diagonal-cross">
                  diagonal-cross
                </calcite-option>
                <calcite-option value="forward-diagonal">
                  forward-diagonal
                </calcite-option>
                <calcite-option value="horizontal">horizontal</calcite-option>
                <calcite-option value="none">none</calcite-option>
                <calcite-option value="vertical">vertical</calcite-option>
                {/* <calcite-option value="solid">solid</calcite-option> */}
              </calcite-select>
            </calcite-label>
            <calcite-label>
              outline
              <calcite-button id="polygon-outline-btn" scale="s">
                Set Outline
              </calcite-button>
            </calcite-label>
            <calcite-label>
              color
              <calcite-input
                placeholder="Placeholder"
                type="color"
                scale="s"
                id="polygon-color-input"
                format="rgb"
              ></calcite-input>
            </calcite-label>
            {/* <calcite-label>
              Fill transparency
              <calcite-slider
                label-handles
                min-value="0"
                scale="m"
                page-step="1"
                id="polygon-opacity-input"
              ></calcite-slider>
            </calcite-label> */}
          </calcite-accordion-item>

          {/* polylineSymbol */}
          <calcite-accordion-item item-subtitle="polylineSymbol">
            <calcite-label>
              style
              <calcite-select scale="s" id="line-style-select">
                <calcite-option disabled selected id="blankOption">
                  choose a style
                </calcite-option>
                <calcite-option value="dash">dash</calcite-option>
                <calcite-option value="dash-dot">dash-dot</calcite-option>
                <calcite-option value="dot">dot</calcite-option>
                <calcite-option value="long-dash">long-dash</calcite-option>
                <calcite-option value="long-dash-dot">
                  long-dash-dot
                </calcite-option>
                <calcite-option value="long-dash-dot-dot">
                  long-dash-dot-dot
                </calcite-option>
                <calcite-option value="none">none</calcite-option>
                <calcite-option value="short-dash">short-dash</calcite-option>
                <calcite-option value="short-dash-dot">
                  short-dash-dot
                </calcite-option>
                <calcite-option value="short-dash-dot-dot">
                  short-dash-dot-dot
                </calcite-option>
                <calcite-option value="short-dot">short-dot</calcite-option>
                <calcite-option value="solid">solid</calcite-option>
              </calcite-select>
            </calcite-label>
            <calcite-label>
              width
              <calcite-input
                min="0"
                placeholder="Placeholder"
                type="number"
                scale="s"
                id="line-width-input"
              ></calcite-input>
            </calcite-label>
            <calcite-label>
              color
              <calcite-input
                placeholder="Placeholder"
                type="color"
                scale="s"
                id="line-color-input"
                format="rgb"
              ></calcite-input>
            </calcite-label>
          </calcite-accordion-item>
        </calcite-accordion>
      </calcite-panel>

      {/* modal point outline */}
      <calcite-modal id="point-outline-modal" width="300">
        <h3 slot="header">SimpleLineSymbol</h3>
        <div slot="content">
          <calcite-label>
            width
            <calcite-input
              min="0"
              placeholder="Placeholder"
              type="number"
              scale="s"
              id="point-sls-width-input"
            ></calcite-input>
          </calcite-label>
          <calcite-label>
            color
            <calcite-input
              placeholder="Placeholder"
              type="color"
              scale="s"
              id="point-sls-color-input"
              format="rgb"
            ></calcite-input>
          </calcite-label>
        </div>
      </calcite-modal>

      {/* modal polygon outline */}
      <calcite-modal id="polygon-outline-modal" width="300">
        <h3 slot="header">SimpleLineSymbol</h3>
        <div slot="content">
          <calcite-label>
            style
            <calcite-select scale="s" id="polygon-sls-style-select">
              <calcite-option disabled selected id="blankOption">
                choose a style
              </calcite-option>
              <calcite-option value="dash">dash</calcite-option>
              <calcite-option value="dash-dot">dash-dot</calcite-option>
              <calcite-option value="dot">dot</calcite-option>
              <calcite-option value="long-dash">long-dash</calcite-option>
              <calcite-option value="long-dash-dot">
                long-dash-dot
              </calcite-option>
              <calcite-option value="long-dash-dot-dot">
                long-dash-dot-dot
              </calcite-option>
              <calcite-option value="none">none</calcite-option>
              <calcite-option value="short-dash">short-dash</calcite-option>
              <calcite-option value="short-dash-dot">
                short-dash-dot
              </calcite-option>
              <calcite-option value="short-dash-dot-dot">
                short-dash-dot-dot
              </calcite-option>
              <calcite-option value="short-dot">short-dot</calcite-option>
              <calcite-option value="solid">solid</calcite-option>
            </calcite-select>
          </calcite-label>
          <calcite-label>
            width
            <calcite-input
              min="0"
              placeholder="Placeholder"
              type="number"
              scale="s"
              id="polygon-sls-width-input"
            ></calcite-input>
          </calcite-label>
          <calcite-label>
            color
            <calcite-input
              placeholder="Placeholder"
              type="color"
              scale="s"
              id="polygon-sls-color-input"
              format="rgb"
            ></calcite-input>
          </calcite-label>
        </div>
      </calcite-modal>
    </>
  );
};

export default DrawingTool;
