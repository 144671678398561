import React, { Fragment } from 'react';
import { getUserSavedFields } from '../../services/gis_service';

import './attributes-renderer.css';

export const RenderAttributes = (props) => {
  const savedFieldsArray = getUserSavedFields(props.layerName);
  const _text_style = {
    fontSize: '.8rem',
  };

  const __formatText = (text) => {
    return text;
  };

  const __getUrl = (url, value) => {
    if (
      value === '' ||
      value === 'Null' ||
      value === 'null' ||
      value === '<Null>' ||
      value === null
    ) {
      return value;
    } else {
      return (
        <a href={url} target="_blank">
          {value}
        </a>
      );
    }
  };
//  const __getMultipleUrl = (urls) => {
//    return (
//      <ul className="identify-table-ul">
//        {urls.map((link, index) => {
//          return (
//            <li key={index} className="identify-table-li">
//             <a href={link.url} target="_blank">
//              {link.value}
//           </a>
//            </li>
//          );
//        })}
//      </ul>
//    );
//  };

  const __IsFieldHidden = (fieldName) => {
    return savedFieldsArray.indexOf(fieldName) > -1 ? true : false;
  };

  const _renderRow = () => {
    switch (props.fieldName.toUpperCase()) {
      case 'HTTPS_PHOTOS':  //FOR HSTS to ingore this bad field of information - replaced by GD_PHOTO_PATH
      case 'SHAPE.STLENGTH()':
      case 'SHAPE':
      case 'OBJECTID':
      case 'OBJECT ID':
      case 'LAYERNAME':
      case 'SOURCELAYER':
      case 'ATTACHMENTS':
      case 'ESRIGNSS_RECEIVER':
      case 'ESRIGNSS_H_RMS':
      case 'ESRIGNSS_V_RMS':
      case 'ESRIGNSS_LATITUDE':
      case 'ESRIGNSS_LONGITUDE':
      case 'ESRIGNSS_ALTITUDE':
      case 'ESRIGNSS_PDOP':
      case 'ESRIGNSS_HDOP':
      case 'ESRIGNSS_VDOP':
      case 'ESRIGNSS_FIXTYPE':
      case 'ESRIGNSS_CORRECTIONAGE':
      case 'ESRIGNSS_STATIONID':
      case 'ESRIGNSS_NUMSATS':
      case 'ESRIGNSS_FIXDATETIME':
      case 'ESRIGNSS_AVG_H_RMS':
      case 'ESRIGNSS_AVG_V_RMS':
      case 'ESRIGNSS_AVG_POSITIONS':
      case 'ESRIGNSS_H_STDDEV':
      case 'GLOBALID':
      case 'CREATED_USER':
      case 'CREATED_DATE':
      case 'LAST_EDITED_USER':
      case 'LAST_EDITED_DATE':
      case 'ESRIGNSS_POSITIONSOURCETYPE':
      case 'ESRIGNSS_DIRECTION':
      case 'ESRIGNSS_SPEED':
      case 'ESRISNSR_AZIMUTH':
        break;
/*      case 'PARCELID':
      case 'PID':
      case 'PARID':
        const RG = new RegExp('-', 'g');
        const pid = props.fieldValue.replace(RG, '');
        const _urlParcels = `${props.appOptions.countyParcelInfoUrl}${pid}`;
        if (__IsFieldHidden(props.fieldName)) {
        } else {
          if (props.showNullOrEmptyFields) {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">
                  {__getUrl(_urlParcels, props.fieldValue)}
                </td>
              </tr>
            );
          } else {
            if (
              props.fieldValue.trim() === 'Null' ||
              props.fieldValue.trim() === 'null' ||
              props.fieldValue.trim() === '<Null>' ||
              props.fieldValue.trim() === ''
            ) {
            } else {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">
                    {__getUrl(_urlParcels, props.fieldValue)}
                  </td>
                </tr>
              );
            }
          }
        }
        break;  
      case 'CONSTRUCTION PLAN':
        const _urlConstructionPlan = `${props.appOptions.constructionPlanBaseUrl}/${props.fieldValue}`;
        if (__IsFieldHidden(props.fieldName)) {
        } else {
          if (props.showNullOrEmptyFields) {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">
                  {__getUrl(_urlConstructionPlan, props.fieldValue)}
                </td>
              </tr>
            );
          } else {
            if (
              props.fieldValue.trim() === 'Null' ||
              props.fieldValue.trim() === 'null' ||
              props.fieldValue.trim() === '<Null>' ||
              props.fieldValue.trim() === ''
            ) {
            } else {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">
                    {__getUrl(_urlConstructionPlan, props.fieldValue)}
                  </td>
                </tr>
              );
            }
          }
        }
        break;
      case 'PLAT_DRAWING':
        const __platDrawingIDs = props.fieldValue;
        var _urlPlatDrawings = __getUrl(
          `${props.appOptions.easementDocumentBaseUrl}/${props.fieldValue}`,
          props.fieldValue
        );
        if (__platDrawingIDs.indexOf(',') > -1) {
          const _urlPlatDrwaingObjs = [];
          var Ids = __platDrawingIDs.split(',');
          for (let index = 0; index < Ids.length; index++) {
            const id = Ids[index];
            _urlPlatDrwaingObjs.push({
              url: `${props.appOptions.easementDocumentBaseUrl}/${id.trim()}`,
              value: id,
            });
          }
          _urlPlatDrawings = __getMultipleUrl(_urlPlatDrwaingObjs);
        }
        if (__IsFieldHidden(props.fieldName)) {
        } else {
          if (props.showNullOrEmptyFields) {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">{_urlPlatDrawings}</td>
              </tr>
            );
          } else {
            if (
              props.fieldValue.trim() === 'Null' ||
              props.fieldValue.trim() === 'null' ||
              props.fieldValue.trim() === '' ||
              props.fieldValue.trim() === '<Null>'
            ) {
            } else {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">{_urlPlatDrawings}</td>
                </tr>
              );
            }
          }
        }
        break;
      case 'EASEMENT DOCUMENT':
      case 'SUPPORTING DOCUMENT':
        const __easementIDs = props.fieldValue;
        var _urlEasement = __getUrl(
          `${props.appOptions.easementDocumentBaseUrl}/${props.fieldValue}`,
          props.fieldValue
        ); //<a href={_singleIDURL} target='_blank'>{props.fieldValue}</a>;
        if (__easementIDs?.indexOf(',') > -1) {
          const _urlEasementObjs = [];
          var Ids = __easementIDs.split(',');
          for (let index = 0; index < Ids.length; index++) {
            const id = Ids[index];
            _urlEasementObjs.push({
              url: `${props.appOptions.easementDocumentBaseUrl}/${id.trim()}`,
              value: id,
            });
          }

          _urlEasement = __getMultipleUrl(_urlEasementObjs);
        }
        if (__IsFieldHidden(props.fieldName)) {
        } else {
          if (props.showNullOrEmptyFields) {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">{_urlEasement}</td>
              </tr>
            );
            //return <tr style={_text_style}><td className="text-left">{props.fieldName}: </td><td className="text-right">{ __getUrl(_urlEasement, props.fieldValue)}</td></tr>
          } else {
            if (
              props.fieldValue === 'Null' ||
              props.fieldValue === 'null' ||
              props.fieldValue === '' ||
              props.fieldValue === '<Null>'
            ) {
            } else {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">{_urlEasement}</td>
                </tr>
              );
              //return <tr style={_text_style}><td className="text-left">{props.fieldName}: </td><td className="text-right">{ __getUrl(_urlEasement, props.fieldValue)}</td></tr>
            }
          }
        }

        break;*/
      case 'PERMITPLAN':  
      const _urlPermit = `${props.appOptions.permitPlanBaseUrl}/${props.fieldValue}.PDF`;
      if (__IsFieldHidden(props.fieldName)) {
      } else {
        if (props.showNullOrEmptyFields) {
          return (
            <tr style={_text_style}>
              <td className="text-left">{props.fieldName}: </td>
              <td className="text-right">
                {__getUrl(_urlPermit, props.fieldValue)}
              </td>
            </tr>
          );
        } else {
          if (
            props.fieldValue === 'Null' ||
            props.fieldValue === 'null' ||
            props.fieldValue === '<Null>' ||
            props.fieldValue === ''
          ) {
          } else {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">
                  {__getUrl(_urlPermit, props.fieldValue)}
                </td>
              </tr>
            );
          }
        }
      }
      break;

      case 'LASTINSPECTION':
      case 'MAPREF':
        const _mapref1 = props.fieldValue.toUpperCase();
        
        if (_mapref1.charAt(_mapref1.length-4) == "_") {
          var _urlScale =  `${props.appOptions.mapRefBaseUrl}/${props.fieldValue}`
        } else {
          var _urlScale = `${props.appOptions.mapRefBaseUrl}/${props.fieldValue}_001`
        };

        if (__IsFieldHidden(props.fieldName)) {
        } else {
          if (props.showNullOrEmptyFields) {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">
                  {__getUrl(_urlScale, props.fieldValue)}
                </td>
              </tr>
            );
          } else {
            if (
              props.fieldValue === 'Null' ||
              props.fieldValue === 'null' ||
              props.fieldValue === '<Null>' ||
              props.fieldValue === ''
            ) {
            } else {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">
                    {__getUrl(_urlScale, props.fieldValue)}
                  </td>
                </tr>
              );
            }
          }
        }
        break;
        case 'GD_PHOTO_PATH':
        case 'COUNTYLINK':
          const _urlCountyLink = `${props.fieldValue}`;
          if (__IsFieldHidden(props.fieldName)) {
          } else {
            if (props.showNullOrEmptyFields) {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">
                    {__getUrl(_urlCountyLink, 'Link')}
                  </td>
                </tr>
              );
            } else {
              if (
                props.fieldValue.trim() === 'Null' ||
                props.fieldValue.trim() === 'null' ||
                props.fieldValue.trim() === '<Null>' ||
                props.fieldValue.trim() === ''
              ) {
              } else {
                return (
                  <tr style={_text_style}>
                    <td className="text-left">{props.fieldName}: </td>
                    <td className="text-right">
                      {__getUrl(_urlCountyLink, 'Link')}
                    </td>
                  </tr>
                );
              }
            }
          }
          break;
        default:
        if (__IsFieldHidden(props.fieldName)) {
        } else {
          if (props.showNullOrEmptyFields) {
            return (
              <tr style={_text_style}>
                <td className="text-left">{props.fieldName}: </td>
                <td className="text-right">{__formatText(props.fieldValue)}</td>
              </tr>
            );
          } else {
            if (
              props.fieldValue === 'Null' ||
              props.fieldValue === 'null' ||
              props.fieldValue === '<Null>' ||
              props.fieldValue === ''
            ) {
            } else {
              return (
                <tr style={_text_style}>
                  <td className="text-left">{props.fieldName}: </td>
                  <td className="text-right">
                    {__formatText(props.fieldValue)}
                  </td>
                </tr>
              );
            }
          }
        }
        break;
    }
  };
  return <Fragment>{_renderRow()}</Fragment>;
};
