import React, { Component, Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';
import { _flow_rate_updater, _gis_service } from '../services/gis_service';

import TextField from 'calcite-react/TextField';
import Select from 'calcite-react/Select';
import Menu, { MenuTitle, MenuItem } from 'calcite-react/Menu';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';

import './add-form.css';
import './edit-modal.css';

import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CrudOperations } from '../services/CRUDoperations.service';

const Entity = 'WaterValveExercising';

class EditValveExercise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.exercise.excercisingdatetime
        ? new Date(this.props.exercise.excercisingdatetime)
        : '',
      isSubmitting: false,
      inspector: this.props.exercise.inspector
        ? this.props.exercise.inspector
        : 'Choose',
      turnstoclose: this.props.exercise.turnstoclose
        ? this.props.exercise.turnstoclose
        : 'Unknown',
      programyear: this.props.exercise.programyear
        ? this.props.exercise.programyear
        : 'Unassigned',
      location: this.props.exercise.location
        ? this.props.exercise.location
        : 'N/A',
      result: this.props.exercise.result,
      comment: this.props.exercise.comment ? this.props.exercise.comment : '',
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(item, value) {
    this.setState({
      [item]: value,
    });
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  __on_update_save = () => {
    this.setState({
      isSubmitting: true,
    });
    const _updated_exercise = {
      watervalveexercisingid: this.props.exercise.watervalveexercisingid,
      //"facilityid": "string",
      //"opendirection": "string",
      turnstoclose: this.state.turnstoclose,
      programyear: this.state.programyear,
      location: this.state.location,
      //"operable": true,
      excercisingdatetime: new Date(this.state.date),
      inspector: this.state.inspector,
      result: this.state.result,
      comment: this.state.comment,
    };

    CrudOperations.Update(Entity, _updated_exercise)
      .then((_result) => {
        _flow_rate_updater
          .updateSystemValve(this.props.facilityid, this.props.valveFlayer)
          .then((featureUpdatedValues) => {
            if (this.state.result === 'Failed') {
              // notify the Office of faillure
              const { watervalveexercisingid } = _result.data;
              CrudOperations.NotifyFailureExercise(
                Entity,
                watervalveexercisingid
              )
                .then((success) => {
                  this.setState({
                    isSubmitting: false,
                  });
                  this.props.updateUI(featureUpdatedValues);
                })
                .catch((err) => {
                  console.log('Error notifying back office :: ', err);
                  swal(
                    'There was an error Notifying failure test to the office. Please try later'
                  );
                });
            } else {
              this.setState({
                isSubmitting: false,
              });
              this.props.updateUI(featureUpdatedValues);
            }
          });
      })
      .catch((err) => {
        console.log('Error updating exercise :: ', err);
        swal('There was an error updating this exercise ');
      });
  };

  render() {
    const {
      date,
      inspector,
      programyear,
      turnstoclose,
      location,
      comment,
      result,
    } = this.state;
    return (
      <Fragment>
        <div className="modal-backdrop"></div>
        <div className="modal-content-wrapper">
          <div className="modal-header">
            Update exercise info - {this.props.facilityid}
          </div>
          <div className="modal-content">
            <div>
              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>Date</FormControlLabel>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(d) => this.setState({ date: d })}
                    /* showTimeSelect */
                  />
                </FormControl>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="inspector">
                    Inspector
                  </FormControlLabel>
                  <Select
                    id="inspector"
                    name="inspector"
                    selectedValue={inspector}
                    onChange={(val) =>
                      this.handleSelectChange('inspector', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Choose">Choose...</MenuItem>
                    <MenuItem value="ENG">ENG</MenuItem>
                    <MenuItem value="MFD">MFD</MenuItem>
                    <MenuItem value="WTR">WTR</MenuItem>
                  </Select>
                </FormControl>
              </Form>

              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>Program Year</FormControlLabel>
                  <Select
                    id="programyear"
                    name="programyear"
                    selectedValue={programyear}
                    onChange={(val) =>
                      this.handleSelectChange('programyear', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Unassigned">Unassigned</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls">
                  <FormControlLabel>Turns to Close</FormControlLabel>
                  <TextField
                    name="turnstoclose"
                    value={turnstoclose}
                    onChange={this.changeHandler}
                  />
                </FormControl>
              </Form>

              <Form>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="result">Result</FormControlLabel>
                  <Select
                    id="result"
                    name="result"
                    selectedValue={result}
                    onChange={(val) => this.handleSelectChange('result', val)}
                    fullWidth
                  >
                    <MenuItem value="Choose">Choose...</MenuItem>
                    <MenuItem value="Failed">Failed</MenuItem>
                    <MenuItem value="Passed">Passed</MenuItem>
                  </Select>
                </FormControl>

                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="comments">
                    Comment
                  </FormControlLabel>
                  <TextField
                    id="comments"
                    name="comment"
                    type="textarea"
                    fullWidth
                    value={comment}
                    onChange={this.changeHandler}
                  />
                  <FormHelperText>
                    provide a comment if necessary
                  </FormHelperText>
                </FormControl>
              </Form>
            </div>
          </div>
          <div className="modal-footer">
            <ButtonGroup>
              <Button
                disabled={this.state.isSubmitting}
                onClick={this.__on_update_save}
              >
                {this.state.isSubmitting ? 'Submitting.......' : 'Save'}
              </Button>
              <Button onClick={() => this.props.close()}>Cancel</Button>
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditValveExercise;
