//import React, { Component } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import MapIdentifyLoader from '../components/functional/identify/mapIdentifyLoader';
import {
  GIS_Base_Functions,
  _user_auth,
} from '../components/services/gis_service';

//import { ProgressBar } from 'reprogressbars';

const __IDENTIFYSETTINGS = '__identifySettings';

let __appOtions;
const _SendEmailToCity = () => {
  const _emailTo = __appOtions.Emails.BasicEmailsReceiver;
  let link = document.createElement('a');
  link.href = `mailto:${_emailTo}?subject=City of Reynoldsburg Web GIS general info`;
  link.style.display = 'none';
  //document.body.appendChild(link);
  link.click();
  //document.body.removeChild(link);
};

let mapView;
export function loadMap(element, appOptions) {
  __appOtions = appOptions;

  return loadModules(
    [
      'esri/request',
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/MapImageLayer',
      'esri/layers/GraphicsLayer',
      'esri/Basemap',

      // Map bottom right widgets
      'esri/widgets/Home',
      'esri/widgets/Track',
      'esri/widgets/Compass',

      // Map bottom left widget
      'esri/widgets/ScaleBar',
      'esri/core/urlUtils',
      'esri/core/watchUtils',
      'esri/layers/VectorTileLayer',
      'esri/layers/TileLayer',
    ],
    {
      css: true,
    }
  ).then(
    ([
      esriRequest,
      Map,
      MapView,
      MapImageLayer,
      GraphicsLayer,
      Basemap,
      Home,
      Track,
      Compass,
      ScaleBar,
      urlUtils,
      watchUtils,
      VectorTileLayer,
      TileLayer,
    ]) => {
      if (!element) {
        // component or app was likely destroyed
        return;
      }

      // set the proxy
      urlUtils.addProxyRule({
        proxyUrl: appOptions.proxyUrl,
        urlPrefix: appOptions.SecuredServicesUrlPrefix,
      });

      const __isAuthorized = _user_auth.isAuthorized();
      const __AuthorizedUser = _user_auth.GetAuthUser();
      // check auth
      //console.log(__isAuthorise);

      //console.log(layers);
      const userGraphicsLayer = new GraphicsLayer({
        id: 'userGraphics',
        title: 'My Drawings',
        listMode: 'hide',
      });
      const publicNotificationGLayer = new GraphicsLayer({
        id: 'publicNotification',
        title: 'Public Notification',
        listMode: 'hide',
      });
      const bufferGLayer = new GraphicsLayer({
        id: 'bufferGraphicsLayer',
        title: 'Buffer Layer',
        listMode: 'hide',
      });
      const _streetViewGLayer = new GraphicsLayer({
        id: 'streetViewLocationGlayer',
        title: 'Street View',
        listMode: 'hide',
      });

      const _streetBasemapLayer = new TileLayer({
        url: appOptions.streetBasemapService,  //'https://tiles.arcgis.com/tiles/MEvzxYGCHO108ZxL/arcgis/rest/services/BASEMAP/VectorTileServer'
        copyright: 'City of Reynoldsburg, Ohio',
      });

//      const vectorTileLayer = new VectorTileLayer({
//        portalItem: {
//         id: '0eb412a5e7c2413197e33a28de550ca6', //'b3f51cec16ca40f78ed633688f5b790c', // City streets basemap
//        },
//        //opacity: 0.75,
//      });
//      const imageTileLayer = new TileLayer({
//       portalItem: {
//          id: '1b243539f4514b6ba35e7d995890db1d', // World Hillshade
//        },
//      });

      const basemap = new Basemap({
        baseLayers: [_streetBasemapLayer],    //[vectorTileLayer],
        thumbnailUrl: '',
        title: 'City Street Basemap',
        id: 'cityMap',
      });

      // create the Map
      const map = new Map({
        basemap,
        layers: [
          bufferGLayer,
          userGraphicsLayer,
          publicNotificationGLayer,
          _streetViewGLayer,
        ],
      });
      // show the map at the element
      let view = new MapView({
        map,
        highlightOptions: {
          color: [255, 255, 0, 1],
          haloOpacity: 0.9,
          fillOpacity: 0.2,
        },
        popup: {
          autoOpenEnabled: false,
        },
        background: { color: '#f0f0e9' },
        container: element,
        ...appOptions.mapConfig,
      });

      // set view extent

      watchUtils.whenTrue(view, 'stationary', () => {
        if (view.extent) {
          /* view.extent.xmin = 1958356.95;
          view.extent.xmax = 1968570.16;
          view.extent.ymin = 393749.65;
          view.extent.ymax = 400707.66; */
          /* const info = `<br> <span> the view extent changed: </span>
            <br> xmin: ${view.extent.xmin.toFixed(2)}
            xmax: ${view.extent.xmax.toFixed(2)}
            <br> ymin: ${view.extent.ymin.toFixed(2)}
            ymax: ${view.extent.ymax.toFixed(2)}`;
          console.log(info); */
        }
      });

      // add layers
      // check connection speed
      // Some browsers use prefixes so let's cope with them first

//      var internetConnectionSpeed = GIS_Base_Functions.GetInternetSpeed();

      const layers = appOptions.applicationLayers;
      if (layers.length > 0) {
        layers.map((layerObj) => {
          switch (layerObj.id) {
            case '1703a318545-contours':  //HARDCODED
              const contour = new TileLayer({
                url: layerObj.serviceUrl,
                id: layerObj.id,
                title: layerObj.title,
                visible: false,
              });
              map.add(contour);
              break;

            default:
              const layer = new MapImageLayer({
                url: layerObj.serviceUrl,
                id: layerObj.id,
                title: layerObj.title,
                visible: false,
              });

              if (layer.id === '1703a0495d2-util') {    //HARDCODED
                if (__isAuthorized && __AuthorizedUser.accessLevel > 1) {
                  map.add(layer);
                }
              } else {
                map.add(layer);
              }
              break;
          }
        });
      }

      const homeBtn = new Home({
        view: view,
      });
      const scaleBar = new ScaleBar({
        view: view,
      });
      /* const track = new Track({
        view: view,
      }); */

      const compassWidget = new Compass({
        view: view,
        iconClass: 'esri-icon-compass',
      });

      view.ui.add(scaleBar, {
        position: 'bottom-left',
      });
      view.ui.add([homeBtn, 'mapIdentifyBtn', compassWidget], 'top-left');

      view.ui.move(['zoom'], 'bottom-right');
      // view.ui.add(['mapImageriesBtn', 'googleStreetViewBtn'], 'bottom-right');
      view.ui.add(['googleStreetViewBtn'], 'bottom-right');

      view.when(() => {
        const _identifySettings = localStorage.getItem(__IDENTIFYSETTINGS);
        if (_identifySettings) {
          const __settings = JSON.parse(_identifySettings);
          console.log(__settings.ActivateMapOnLoad);
          if (__settings.ActivateMapOnLoad) {
            document.getElementById('mapIdentifyBtn').click();
          }
        } else {  // added by DMM 2/1/2023 to default to ON for "Identify"
          document.getElementById('mapIdentifyBtn').click();
        }
        
        setTimeout(() => {
          const _credits = document.querySelector(
            '.esri-attribution__sources.esri-interactive'
          ); //esri-attribution__sources esri-interactive
          if (_credits) {
            //console.log(_credits);
            _credits.addEventListener('click', _SendEmailToCity);
          }
        }, 20);
      });

      /* watchUtils.whenFalse(view, 'updating', () => {
        //console.log('view is done updating...');
        ReactDOM.unmountComponentAtNode(
          document.getElementById('map-loading-progressbar')
        );
      });
      watchUtils.whenTrue(view, 'updating', () => {
        ReactDOM.render(
          <ProgressBar isLoading={true} className="fixed-progress-bar" />,
          document.getElementById('map-loading-progressbar')
        );
      }); */

      //view.popup.autoOpenEnabled = false;
      //view.map.layers.forEach( layer => layer.popupEnabled = false );
      view.on('click', (evt) => {
        const btnIdentify = document.getElementById('mapIdentifyBtn');
        if (btnIdentify.classList.contains('map-identify-active')) {
          const _panel_window = document.getElementById('right-bar-wrapper');
          if (_panel_window.classList.contains('hidden')) {
            _panel_window.classList.remove('hidden');
          }
          // render the info window
          view.graphics.removeAll();
          document.getElementById('map-view-container').style.cursor = 'wait';
          ReactDOM.render(
            <MapIdentifyLoader
              location={evt.mapPoint}
              view={view}
              appOptions={appOptions}
            />,
            document.getElementById('right-bar-wrapper')
          );
        }

        /* view.popup.fetchFeatures(evt.screenPoint)
          .then((res) => {
            res.allGraphicsPromise
              .then((f) => {
                console.log(f);
              })
            console.log(res);
            view.popup.visible = false;
            console.log(view.popup);
            console.log(`Feature count: ${view.popup.featureCount}`);
            console.log(view.popup.features);


            // show info window
            

          }) */
      });

      mapView = view;

      return view.when(() => {
        // return a reference to the view
        return view;
      });
    }
  );
}

export function _mapView() {
  return new Promise((resolve) => {
    return resolve(mapView);
  });
}
export function _mapViewAvailable() {
  return mapView;
}
