import React, { Fragment } from 'react';
import { loadModules } from 'esri-loader';
import List, {
  ListItem,
  ListHeader,
  ListItemTitle,
  ListItemSubtitle,
} from 'calcite-react/List';

import { _mapViewAvailable } from '../../utils/map';

export const SearchResultsDisplay = (props) => {
  const { foundFieldName, layerId, layerName, value } = props.resultObj;
  const __zoomToFeature = () => {
    _mapViewAvailable().graphics.removeAll();
    _highlightSelectedFeature();
    _mapViewAvailable().goTo({
      target: props.resultObj.feature.geometry,
      zoom: 20,  //  Zoom to found asset from search
    });
  };
  const _highlightSelectedFeature = () => {
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const highlightGr = new Graphic({
        geometry: props.resultObj.feature.geometry,
        symbol: _highlightSymbol(),
        attributes: props.resultObj.feature.attributes,
      });
      _mapViewAvailable().graphics.add(highlightGr);
    });
  };
  const _highlightSymbol = () => {
    let _symbol;
    switch (props.resultObj.feature.geometry.type.toLowerCase()) {
      case 'point':
        _symbol = {
          type: 'simple-marker',
          style: 'square',
          outline: { width: 2, color: [0, 197, 255, 1] },
          size: 20,
          color: [0, 169, 230, 0],
        };
        break;
      case 'polyline':
        _symbol = {
          type: 'simple-line',
          style: 'long-dash-dot',
          cap: 'round',
          join: 'bevel',
          width: 6.25,
          color: [0, 197, 255, 0.69],
        };
        break;
      default:
        // polygon as default
        _symbol = {
          type: 'simple-fill',
          outline: { width: 2, color: [0, 169, 230, 0.65] },
          color: [0, 169, 230, 0.25],
        };
        break;
    }
    return _symbol;
  };

  return (
    <Fragment>
      <ListItem leftNode={props.index + 1} onClick={() => __zoomToFeature()}>
        <ListItemTitle>{value}</ListItemTitle>
        <ListItemSubtitle>
          {layerName} found field name: {foundFieldName}
        </ListItemSubtitle>
      </ListItem>
    </Fragment>
  );
};
