import React, { Component, Fragment } from 'react';
import Loader from 'calcite-react/Loader';
import { loadModules } from 'esri-loader';
import UserDrawings from '../functional/drawings/UserDrawings';
import { NoDrawings } from '../functional/drawings/NoDrawings';

import { connect } from 'react-redux';
import { _mapViewAvailable } from '../../utils/map';
import { userGraphicService } from '../services/gis_service';
import { setgraphics } from '../../redux/reducers/graphics';
import DrawingItem from './drawings/DrawingItem';
import Button, { ButtonGroup } from 'calcite-react/Button';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import ViewHideIcon from 'calcite-ui-icons-react/ViewHideIcon';
import ViewVisibleIcon from 'calcite-ui-icons-react/ViewVisibleIcon';

const DRAWINGS_KEY = 'arcgis-local-drawings';
const _drawingsWrapper = {
  position: 'relative',
};

const __scrollablePanel = {
  position: 'absolute',
  top: '55px',
  bottom: '55px',
  width: '100%',
  overflow: 'auto',
};
const __footerPanel = {
  position: 'absolute',
  bottom: '10px',
  width: '100%',
};

class Drawings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphicsLayer: null,
      isShowingAllUserGraphics: true,
    };
  }

  componentDidMount() {
    const userGLayer = _mapViewAvailable().map.findLayerById('userGraphics');
    //const { userGraphics } = this.props;
    console.log(this.props);
    setTimeout(() => {
      this.setState({ graphicsLayer: userGLayer });
    }, 20);
  }

  deleteAllGraphics = () => {
    const userGLayer = _mapViewAvailable().map.findLayerById('userGraphics');
    if (userGLayer) {
      userGLayer.removeAll();
      // clear from local storage
      userGraphicService.updateGraphicsStorage(userGLayer);
      this.props.setUserGraphics([]);
    }
  };

  deleteSingleGraphic = (graphic) => {
    const userGLayer = _mapViewAvailable().map.findLayerById('userGraphics');
    if (userGLayer) {
      userGLayer.remove(graphic);
      userGraphicService.updateGraphicsStorage(userGLayer);
      setTimeout(() => {
        this.props.setUserGraphics(userGLayer.graphics.items);
      }, 20);
    }
  };

  hideShowAllGraphics = () => {
    this.setState({
      isShowingAllUserGraphics: !this.state.isShowingAllUserGraphics,
    });
    setTimeout(() => {
      const userGLayer = _mapViewAvailable().map.findLayerById('userGraphics');
      userGLayer.graphics.items.map(
        (graphic) => (graphic.visible = this.state.isShowingAllUserGraphics)
      );
      this.__toggle_cards_visibility();
    }, 50);
  };

  __toggle_cards_visibility = () => {
    let _inputs = document.getElementsByName('graphics-visibility-controller');

    for (let index = 0; index < _inputs.length; index++) {
      const chkbox = _inputs[index];

      chkbox.checked = this.state.isShowingAllUserGraphics;
    }
  };

  render() {
    return (
      <Fragment>
        <div style={__scrollablePanel}>
          <div>
            {this.props.userGraphics.length > 0 && (
              <>
                {this.props.userGraphics.map((f, idx) => (
                  <DrawingItem
                    key={idx}
                    index={idx}
                    drawingObj={f}
                    graphicsLayer={this.props.graphicsLayer}
                    mapview={_mapViewAvailable()}
                    deleteSingleGraphic={this.deleteSingleGraphic}
                  />
                ))}
              </>
            )}
            {this.props.userGraphics.length === 0 && <NoDrawings />}
          </div>
        </div>

        {this.props.userGraphics.length > 0 && (
          <div className="drawings-footer" style={__footerPanel}>
            <ButtonGroup>
              <Button red extraSmall onClick={this.deleteAllGraphics}>
                <TrashIcon /> Delete All
              </Button>
              <Button
                extraSmall
                onClick={this.hideShowAllGraphics}
                style={{
                  display: this.state.isShowingAllUserGraphics
                    ? 'block'
                    : 'none',
                }}
              >
                <ViewHideIcon /> Hide All
              </Button>
              <Button
                extraSmall
                onClick={this.hideShowAllGraphics}
                style={{
                  display: !this.state.isShowingAllUserGraphics
                    ? 'block'
                    : 'none',
                }}
              >
                <ViewVisibleIcon /> Show All
              </Button>
            </ButtonGroup>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    userGraphics: state.graphics.value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserGraphics: (gr) => dispatch(setgraphics(gr)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawings);
