import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = [];

export const graphicSlice = createSlice({
    name:"graphics",
    initialState: {value: initialStateValue},
    reducers: {
        setgraphics: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setgraphics } = graphicSlice.actions;

export default graphicSlice.reducer;