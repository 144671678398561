import React, { Fragment, useState } from 'react';
import { loadModules } from 'esri-loader';
import { _mapView } from '../../utils/map';

const BOOKMARK_KEY = 'arcgis-local-bookmarks';
let USERBOOKMARKS = [];

const Bookmarks = (props) => {
  const [state, setState] = useState({
    bookmarks: [],
  });
  const _scrollableWrapper = {
    position: 'absolute',
    top: '55px',
    bottom: '0',
    width: '100%',
    overflow: 'auto',
  };
  _mapView().then((view) => {
    loadModules(['esri/widgets/Bookmarks']).then(([Bookmarks]) => {
      const existingBookmarks = localStorage.getItem(BOOKMARK_KEY);
      if (existingBookmarks) {
        USERBOOKMARKS = JSON.parse(existingBookmarks);
      }
      let bookmarks = new Bookmarks({
        view: view,
        editingEnabled: true,
        container: 'bookmarks',
      });
      if (USERBOOKMARKS.length > 0) {
        bookmarks.bookmarks = USERBOOKMARKS;
      }

      view.when((v) => {
        // watching for new added and deletes
        if (bookmarks.bookmarks !== undefined) {
          bookmarks.bookmarks.on('change', (evt) => {
            saveUserBookMarks(bookmarks);
          });
        }
      });

      bookmarks.on('bookmark-edit', (e) => {
        saveUserBookMarks(bookmarks);
      });
    });
  });

  const saveUserBookMarks = (bookmarks) => {
    const _user_bookmarks = localStorage.getItem(BOOKMARK_KEY);
    if (_user_bookmarks) {
      localStorage.removeItem(BOOKMARK_KEY);
    }
    if (bookmarks.bookmarks.length > 0) {
      const rawBookmarks = bookmarks.bookmarks.map(
        ({ active, extent, name, thumbnail }) => ({
          active,
          extent,
          name,
          thumbnail,
        })
      );
      localStorage.setItem(BOOKMARK_KEY, JSON.stringify(rawBookmarks));
    }
  };

  return (
    <Fragment>
      <div style={_scrollableWrapper}>
        <div id="bookmarks"></div>
      </div>
    </Fragment>
  );
};

export default Bookmarks;
