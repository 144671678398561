import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import Checkbox from 'calcite-react/Checkbox';
import ArrowBoldLeftIcon from 'calcite-ui-icons-react/ArrowBoldLeftIcon';
import Button, { ButtonGroup } from 'calcite-react/Button';

import MapIdentifyWithResults from './mapIdentifyWithResults';
import swal from 'sweetalert';
import LayerSettingsCheckbox from '../LayerSettingsCheckbox';
import { getUserSavedFields } from '../../services/gis_service';

const __stylePanelHeader = {
  position: 'relative',
  height: '45px',
  backgroundColor: '#f5f5f5',
  padding: '15px',
  textAlign: 'left',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderBottom: '1px solid #ddd',
};
const _identifyFooterToolbar = {
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: '#f5f5f5',
  padding: '10px',
  textAlign: 'left',
  borderTop: '1px solid #ddd',
};
const __styleClosePanelBtn = {
  position: 'absolute',
  width: '25px',
  top: '0',
  right: '0',
  height: '45px',
  fontWeight: '100',
  textAlign: 'center',
  cursor: 'pointer',
};
const __styleCloseBtnText = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
};

const _text_style = {
  fontSize: '.8rem',
};

const _text_info = {
  fontSize: '0.7rem',
  fontWeight: '300',
  margin: '0',
};

const _table_style = {
  textAlign: 'left',
  fontWeight: '400',
};

const __USERHIDDENFIELDS = '__gis__Fields__Manager';

export default class LayerFieldSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxes: [],
      checkedItems: new Map(),
      savedFieldsArray: [],
      location: this.props.location,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  }
  componentDidMount() {
    this.getSavedFieldsCheckboxes();
    setTimeout(() => {
      // check saved field
      const __saved__fields = getUserSavedFields(this.props.layerName);
      if (__saved__fields.length > 0) {
        let _checkboxes = document.querySelectorAll(
          '.layer-fields-input-label'
        );
        __saved__fields.map((fieldName) => {
          for (let index = 0; index < _checkboxes.length; index++) {
            const element = _checkboxes[index];
            if (element.innerText === fieldName) {
              element.click();
            }
          }
        });
      }
    }, 30);
  }

  getSavedFieldsCheckboxes = () => {
    let _checkboxes = [];
    Object.keys(this.props.attr).map((key) => {
      switch (key.toUpperCase()) {
        case 'SHAPE':
        case 'OBJECTID':
        case 'OBJECT ID':
        case 'LAYERNAME':
        case 'ATTACHMENTS':
        case 'SOURCELAYER':
        case 'ESRIGNSS_RECEIVER':
        case 'ESRIGNSS_H_RMS':
        case 'ESRIGNSS_V_RMS':
        case 'ESRIGNSS_LATITUDE':
        case 'ESRIGNSS_LONGITUDE':
        case 'ESRIGNSS_ALTITUDE':
        case 'ESRIGNSS_PDOP':
        case 'ESRIGNSS_HDOP':
        case 'ESRIGNSS_VDOP':
        case 'ESRIGNSS_FIXTYPE':
        case 'ESRIGNSS_CORRECTIONAGE':
        case 'ESRIGNSS_STATIONID':
        case 'ESRIGNSS_NUMSATS':
        case 'ESRIGNSS_FIXDATETIME':
        case 'ESRIGNSS_AVG_H_RMS':
        case 'ESRIGNSS_AVG_V_RMS':
        case 'ESRIGNSS_AVG_POSITIONS':
        case 'ESRIGNSS_H_STDDEV':
        case 'GLOBALID':
        case 'CREATED_USER':
        case 'CREATED_DATE':
        case 'LAST_EDITED_USER':
        case 'LAST_EDITED_DATE':
        case 'ESRIGNSS_POSITIONSOURCETYPE':
        case 'ESRIGNSS_DIRECTION':
        case 'ESRIGNSS_SPEED':
        case 'ESRISNSR_AZIMUTH':
        case 'ESRIGNSS_STATIONID':
        case 'ESRIGNSS_NUMSATS':
          break;

        default:
          _checkboxes.push({
            name: key,
            key: key,
            label: key,
          });
          break;
      }
    });
    this.setState({ checkboxes: _checkboxes });
  };

  _previous = () => {
    ReactDOM.render(
      <MapIdentifyWithResults
        view={this.props.view}
        appOptions={this.props.appOptions}
        location={this.props.location}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };
  _save = () => {
    var __layer_fields_to_hide = [];
    let Keys = [...this.state.checkedItems.entries()]
      .filter(({ 1: v }) => v === true)
      .map(([k]) => k);

    const _hiddenFields = localStorage.getItem(__USERHIDDENFIELDS);
    if (_hiddenFields) {
      var existingArrayObj = JSON.parse(_hiddenFields);
      var _newArray = [];
      _newArray = existingArrayObj.filter(
        (l) => l.layer !== this.props.layerName
      ); // remove the existing layer to be replaced with new one

      if (Keys.length > 0) {
        __layer_fields_to_hide.push({
          layer: this.props.layerName,
          fields: Keys,
        });
        _newArray.push({ layer: this.props.layerName, fields: Keys });

        localStorage.removeItem(__USERHIDDENFIELDS);
        localStorage.setItem(__USERHIDDENFIELDS, JSON.stringify(_newArray));
      } else {
        if (_newArray.length === 0) {
          // no fields at all ===> remove item from localstorage
          localStorage.removeItem(__USERHIDDENFIELDS);
        }
      }
      this.__save_success_message();
    } else {
      if (Keys.length > 0) {
        __layer_fields_to_hide.push({
          layer: this.props.layerName,
          fields: Keys,
        });
        localStorage.setItem(
          __USERHIDDENFIELDS,
          JSON.stringify(__layer_fields_to_hide)
        );
        this.__save_success_message();
      } else {
        swal('Please choose fields you wish to hide from the identify window');
      }
    }
  };

  __save_success_message = () => {
    swal({
      text: 'Your preferences have been saved.',
      closeOnClickOutside: false,
      buttons: { cancel: false, confirm: 'OK' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        // dispose the form
        ReactDOM.render(
          <MapIdentifyWithResults
            view={this.props.view}
            appOptions={this.props.appOptions}
          />,
          document.getElementById('right-bar-wrapper')
        );
      }
    });
  };
  _cancel = () => {
    ReactDOM.render(
      <MapIdentifyWithResults
        view={this.props.view}
        appOptions={this.props.appOptions}
      />,
      document.getElementById('right-bar-wrapper')
    );
  };
  closeWidgetIdentifyWindow = () => {
    this.props.view.graphics.removeAll();
    document.getElementById('right-bar-wrapper').classList.add('hidden');
    /* const btnIdentify = document.getElementById('mapIdentifyBtn');
        if(btnIdentify.classList.contains('map-identify-active')){
            btnIdentify.classList.remove('map-identify-active');
            document.getElementById("map-view-container").style.cursor = "default";
        } */
  };
  render() {
    return (
      <Fragment>
        <div id="layer-field-settings">
          <div className="panel-header" style={__stylePanelHeader}>
            <span id="left-panel-header-title">{this.props.layerName}</span>
            <p style={_text_info}>
              Select fields you wish to hide from identify window.
            </p>
            <div
              id="btnCloseRightPanel"
              style={__styleClosePanelBtn}
              onClick={this.closeWidgetIdentifyWindow}
            >
              <div style={__styleCloseBtnText} title="Close">
                X
              </div>
            </div>
          </div>
          <div
            id="layerInfoContentDisplay"
            className="identify-content-scroller"
          >
            <table className="table" style={_table_style}>
              <tbody>
                {this.state.checkboxes.map((item) => (
                  <tr key={item.key}>
                    <td>
                      <label
                        key={item.key}
                        className="layer-fields-input-label"
                      >
                        <LayerSettingsCheckbox
                          name={item.name}
                          checked={this.state.checkedItems.get(item.name)}
                          onChange={this.handleChange}
                        />
                        {item.name}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div id="layerFooterDisplay" style={_identifyFooterToolbar}>
            <ButtonGroup>
              <Button onClick={this._save}>Save</Button>
              <Button onClick={this._cancel}>Return to info</Button>
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}
