import React, { Component, Fragment } from 'react';


class UtilityToggler extends Component {

    constructor(props){
        super();
        this.state = {  //hardcoded
            Telecom: 'Telecome',
            Electric: 'Electric',
            Water: 'Water',
            Storm: 'Storm',
            Sewer: 'Sanitary',
            Lights: 'Lights',
            Contours: 'Contours'
        };
    }

    componentDidMount() {
        //console.log(this.props);
    }

    __toggleContours(e) {
        const mapLayers = this.props.mapview.map.layers.items.filter((l) => {
            return l.id === '1703a318545-contours';  //HARDCODED
        });
        const contourLayer = mapLayers[0];
        contourLayer.visible = e.target.checked;
    }

    __toggleUtility(e, layerName) {
        const utilityLayers = this.props.mapview.map.layers.items.filter((l) => {
            return l.id === '1703a0495d2-util';  //HARDCODED
        });

        const _rootLayer = utilityLayers[0];
        // _rootLayer.visible = e.target.checked;
        if(!_rootLayer.visible) {
            _rootLayer.visible = e.target.checked; // layer root
        }
        
        _rootLayer.sublayers.items.map((l) => {
            if(l.title.toUpperCase() === layerName.toUpperCase()) {
                // console.log(l);
                l.visible = e.target.checked; // layer root
                const _recursiveFunc = (layers) => {
                    layers.map((ly) => {
                        if(ly.sublayers) {
                            _recursiveFunc(ly.sublayers.items);
                        }
                        else {
                            ly.visible = e.target.checked;
                        }
                    })
                };
                _recursiveFunc(l.sublayers.items);
            }
        })
    }

    render() {
        return (
            <Fragment>
                <ul className="categorychecklist form-no-clear" style={{padding: '0 10px'}}>
                    <li className="utility-category">
                        <label className="selectit">
                            <input value="blog" type="checkbox" onChange={(e) => this.__toggleUtility(e, this.state.Water)}
                            className="js-wpv-filter-trigger" 
                            name="utility-category" /> 
                            Water
                        </label>
                    </li>
                    <li className="utility-category">
                        <label className="selectit">
                            <input value="blog" type="checkbox" onChange={(e) => this.__toggleUtility(e, this.state.Storm)}
                            className="js-wpv-filter-trigger" 
                            name="utility-category" /> 
                            Storm
                        </label>
                    </li>
                    <li className="utility-category">
                        <label className="selectit">
                            <input value="blog" type="checkbox" onChange={(e) => this.__toggleUtility(e, this.state.Sewer)}
                            className="js-wpv-filter-trigger" 
                            name="utility-category" /> 
                            Sanitary
                        </label>
                    </li>
                    <li className="utility-category">
                        <label className="selectit">
                            <input value="blog" type="checkbox" onChange={(e) => this.__toggleContours(e)}
                            className="js-wpv-filter-trigger" 
                            name="utility-category" /> 
                            Contours
                        </label>
                    </li>
                </ul>
            </Fragment>
        )
    }
}

export default UtilityToggler;
