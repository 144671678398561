import React, { Component, Fragment } from 'react';
import Button from 'calcite-react/Button';
import Drawer from 'calcite-react/Drawer';
import SideNav, { SideNavTitle, SideNavLink } from 'calcite-react/SideNav';
import { _user_auth } from './services/gis_service';

import Avatar from 'calcite-react/Avatar';

export default class UserAccountBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerDirection: 'right',
      active: false,
      userName: this.props.userName,
    };
    this.showDrawerClicked = this.showDrawerClicked.bind(this);
    this.hideDrawer = this.hideDrawer.bind(this);
  }

  componentDidMount() {
    let userName = this.state.userName;
    if (userName) {
      userName = userName.substring(0, 3).toUpperCase();

      this.setState({ userName });
    }
  }

  showDrawerClicked(drawerDirection) {
    this.setState({
      drawerDirection,
      active: true,
    });
  }

  hideDrawer() {
    this.setState({
      active: false,
    });
  }
  _navigateTo = (url) => {
    window.open(url, '_blank');
  };

  __signout = () => {
    _user_auth.signout();
    setTimeout(() => {
      window.location.reload();
    }, 50);
  };

  render() {
    return (
      <Fragment>
        <Avatar
          style={{ cursor: 'pointer' }}
          onClick={() => this.showDrawerClicked('right')}
        >
          {this.state.userName}
        </Avatar>
        {/* <div>
                    <Button
                        onClick={() => this.showDrawerClicked('right')}
                        >
                        {this.props.userName}
                    </Button>
                </div> */}
        <Drawer
          active={this.state.active}
          right={this.state.drawerDirection === 'right'}
          onRequestClose={this.hideDrawer}
        >
          <SideNav>
            <SideNavTitle>Helpful Links...</SideNavTitle>
            <nav>
              <SideNavLink
                onClick={() =>
                  this._navigateTo(
                    'https://gis.reynoldsburg.gov/assets/docs/webgis_v4.pdf'
                  )
                }
              >
                Documentation
              </SideNavLink>
              <SideNavLink
                onClick={() =>
                  this._navigateTo('https://experience.arcgis.com/experience/4161a2cec8fb43e29122e99095091ade/')
                }
              >
                Parks Interactive Map
              </SideNavLink>
              <SideNavLink
                onClick={() =>
                  this._navigateTo('https://rms.reynoldsburg.gov/')
                }
              >
                RMS
              </SideNavLink>
{/*              <SideNavLink onClick={() => this.__signout()}>
                Signout
              </SideNavLink>  */}
            </nav>
          </SideNav>
        </Drawer>
      </Fragment>
    );
  }
}
