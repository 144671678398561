import React, { Component, Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';
import { _flow_rate_updater, _gis_service } from '../services/gis_service';

import TextField from 'calcite-react/TextField';
import Select from 'calcite-react/Select';
import Menu, { MenuTitle, MenuItem } from 'calcite-react/Menu';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';

import './add-form.css';
import './edit-modal.css';

import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CrudOperations } from '../services/CRUDoperations.service';

const Entity = 'HydrantFlowTest';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.flowtest.testdatetime
        ? new Date(this.props.flowtest.testdatetime)
        : '',
      datePickerFocused: false,
      isSubmitting: false,
      inspector: this.props.flowtest.inspector
        ? this.props.flowtest.inspector
        : 'Choose',
      flow: this.props.flowtest.flow,
      sp: this.props.flowtest.staticpressure,
      rp: this.props.flowtest.residualpressure,
      comments: this.props.flowtest.comment ? this.props.flowtest.comment : '',
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(item, value) {
    this.setState({
      [item]: value,
    });
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDateChange(date) {
    this.setState({
      date,
    });
  }

  onFocusChange({ focused }) {
    this.setState({
      datePickerFocused: focused,
    });
  }

  __on_update_save = () => {
    this.setState({
      isSubmitting: true,
    });
    const __newFlowTest = {
      hydrantflowtestid: this.props.flowtest.hydrantflowtestid,
      inspector: this.state.inspector,
      testdatetime: new Date(this.state.date),
      flow: parseFloat(this.state.flow),
      staticpressure: parseFloat(this.state.sp),
      residualpressure: parseFloat(this.state.rp),
      comment: this.state.comments,
    };

    CrudOperations.Update(Entity, __newFlowTest)
      .then((result) => {
        // update the flow test
        _flow_rate_updater
          .updateHydrantFlowRate(
            this.props.flowtest.facilitykey,
            this.props.hydrantFlayer
          )
          .then((new_flow_rate) => {
            this.setState({
              isSubmitting: false,
            });
            this.props.updateUI(new_flow_rate);
          })
          .catch((fserviceError) => {
            console.log(
              'Error updating the hydrant feature service :: ',
              fserviceError
            );
            swal('Feature service error. Please try again');
          });
      })
      .catch((err) => {
        console.log('Error updating flow test CRUD :: ', err);
        swal('There was an error updating flow test.');
      });
  };

  render() {
    const { date, inspector, flow, sp, rp, comments } = this.state;
    return (
      <Fragment>
        <div className="modal-backdrop"></div>
        <div className="modal-content-wrapper">
          <div className="modal-header">
            Update flow test info - {this.props.flowtest.facilitykey}
          </div>
          <div className="modal-content">
            <div>
              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>Date</FormControlLabel>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(d) => this.setState({ date: d })}
                    /* showTimeSelect */
                  />
                  {/* <TextField name="date" value={date} onChange={this.changeHandler} /> */}
                  {/* <DatePicker
                                        date={this.state.date}
                                        onDateChange={this.onDateChange}
                                        focused={this.state.datePickerFocused}
                                        onFocusChange={this.onFocusChange}
                                    /> */}
                </FormControl>
                <FormControl className="form-controls">
                  <FormControlLabel>Flow (GPM)</FormControlLabel>
                  <TextField
                    name="flow"
                    value={flow}
                    onChange={this.changeHandler}
                  />
                </FormControl>
              </Form>

              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>SP</FormControlLabel>
                  <TextField
                    name="sp"
                    value={sp}
                    onChange={this.changeHandler}
                  />
                </FormControl>
                <FormControl className="form-controls">
                  <FormControlLabel>RP</FormControlLabel>
                  <TextField
                    name="rp"
                    value={rp}
                    onChange={this.changeHandler}
                  />
                </FormControl>
              </Form>

              <Form>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="inspector">
                    Inspector
                  </FormControlLabel>
                  <Select
                    id="inspector"
                    name="inspector"
                    selectedValue={inspector}
                    onChange={(val) =>
                      this.handleSelectChange('inspector', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Choose">Choose...</MenuItem>
                    <MenuItem value="ENG">ENG</MenuItem>
                    <MenuItem value="MFD">MFD</MenuItem>
                    <MenuItem value="WTR">WTR</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="comments">
                    Comment
                  </FormControlLabel>
                  <TextField
                    id="comments"
                    name="comments"
                    type="textarea"
                    fullWidth
                    value={comments}
                    onChange={this.changeHandler}
                  />
                  <FormHelperText>
                    provide a comment if necessary
                  </FormHelperText>
                </FormControl>
              </Form>
            </div>
          </div>
          <div className="modal-footer">
            <ButtonGroup>
              <Button
                disabled={this.state.isSubmitting}
                onClick={this.__on_update_save}
              >
                {this.state.isSubmitting ? 'Submitting.......' : 'Save'}
              </Button>
              <Button onClick={() => this.props.close()}>Cancel</Button>
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditModal;
