import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { _mapView, _mapViewAvailable } from '../../utils/map';
import Button, { ButtonGroup } from 'calcite-react/Button';
import { loadModules } from 'esri-loader';
import Loader from 'calcite-react/Loader';
import swal from 'sweetalert';

class Legend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnText: 'View Flooding Data (FEMA)',
      isLoading: true,
    };
  }

  componentDidMount() {
    //this.initTOC();
    _mapView().then((view) => {
      this.__loadEsriLayerListToc(view);
    });
  }

  __loadEsriLayerListToc = (view) => {
    const that = this;
    loadModules(['esri/widgets/LayerList', 'esri/request']).then(
      ([LayerList, esriRequest]) => {
        const __fema = view.map.findLayerById('fema_flooding');
        if (__fema) {
          this.setState({ btnText: 'Remove Flooding Data (FEMA)' });
        }

        var layerList = new LayerList({
          view: view,
          listItemCreatedFunction: function (event) {
            const item = event.item;

            if (item.children.items.length !== 0) {
              let childrenArr = item.children.items;
              for (let index = 0; index < childrenArr.length; index++) {
                const child = childrenArr[index];
                const _url = child.layer.url.split('MapServer');
                // make a request to the server to retrieve the layer image url
                esriRequest(_url[0] + 'MapServer/legend', {
                  query: {
                    f: 'json',
                  },
                  responseType: 'json',
                }).then((response) => {
                  event.item.layer.opacity = 1;
                  if (item.title != 'Legend') {
                    //let theArray = [];
                    //console.log(child.layer.url);
                    let ul = document.createElement('ul');
                    ul.classList.add('custom-legend-list-images');
                    // build unique url for the legend symbol

                    const _layerIndex = response.data.layers.findIndex(
                      (l) => l.layerName == child.layer.title
                    );
                    const _childLayers = response.data.layers[_layerIndex];
                    if (_childLayers) {
                      if (_childLayers.legend) {
                        for (let i = 0; i < _childLayers.legend.length; i++) {
                          const element = _childLayers.legend[i];
                          //console.log(element.label);
                          let li = document.createElement('li');
                          let imgc = document.createElement('img');
                          imgc.src = `data:${element.contentType};base64, ${element.imageData}`;
                          //li.append(element.label);
                          li.append(imgc);
                          li.append(element.label);
                          ul.append(li);
                        }
                        // assign image to the sublayers in layerlist
                        child.panel = {
                          content: ul,
                          open: false,
                        };
                      }
                    }

                    item.actionsSections = [
                      [
                        {
                          title: 'Increase opacity',
                          className: 'esri-icon-up',
                          id: 'increase-opacity',
                        },
                        {
                          title: 'Decrease opacity',
                          className: 'esri-icon-down',
                          id: 'decrease-opacity',
                        },
                      ],
                    ];
                  }
                });
              }
            }

            item.watch('visible', (e) => {
              const __recursive__func = (layer) => {
                if (layer.parent) {
                  // immediate parent
                  const _parent = layer.parent;
                  if (!_parent.visible) _parent.visible = e;
                  __recursive__func(layer.parent);
                } else {
                  // root node
                  if (!layer.visible) layer.visible = e;
                }
              };
              __recursive__func(item);

              // sync with topbar toggler
              switch (item.title) {
                case 'Water':  //HARDCODED
                case 'Storm':  //HARDCODED
                case 'Sanitary':  //HARDCODED
                case 'Lights':  //HARDCODED
                    that.__CheckOrUnchekTopToggler(item.title.toLowerCase(), e);
                  break;

                default:
                  break;
              }
            });

            /* if (item.layer.type != 'group') {
              // don't show legend twice
              item.panel = {
                content: 'legend',
                open: false,
              };
            } */
          },
          container: 'lagendWrapper',
        });

        layerList.on('trigger-action', (evt) => {
          //console.log(evt);
          switch (evt.action.id) {
            case 'increase-opacity':
              if (evt.item.layer.opacity < 1) {
                evt.item.layer.opacity += 0.25;
              }
              break;
            case 'decrease-opacity':
              if (evt.item.layer.opacity > 0) {
                evt.item.layer.opacity -= 0.25;
              }
              break;

            default:
              break;
          }
        });
      }
    );
  };

  initTOC = () => {
    const layerInfos = [];
    const __layers = _mapViewAvailable().map.layers.items;
    __layers.map((l) => {
      switch (l.type) {
        case 'graphics':
          break;

        default:
          layerInfos.push({
            layer: l,
            title: l.title,
            slider: true,
          });
          break;
      }
    });

    console.log(layerInfos);

    /* require(['agsjs/dijit/TOC'], () => {
      var tocLegend = new agsjs.dijit.TOC(
        {
          map: _mapViewAvailable().map,
          layerInfos,
          mapView: _mapViewAvailable(),
        },
        'lagendWrapper'
      );

      tocLegend.startup();
    }); */
  };

  __synchronizeUtilityToggler = (layerGroupNameID, items) => {
    let visibilityArray = [];

    items.map((v) => {
      visibilityArray.push(v.visible);
    });

    visibilityArray.indexOf(false) > -1
      ? this.__CheckOrUnchekTopToggler(layerGroupNameID, false)
      : this.__CheckOrUnchekTopToggler(layerGroupNameID, true);
  };

  __CheckOrUnchekTopToggler = (layerNameDomId, isChecked) => {
    const domEl = document.getElementById(layerNameDomId);
    domEl.checked = isChecked;
  };

  __toggleTopTogglerVisibility = (items, isChecked) => {
    items.map((v) => {
      v.visible = isChecked;
    });
  };

  loadFEMAData = (e) => {
    const __fema = _mapViewAvailable().map.findLayerById('fema_flooding');
    if (__fema) {
      //swal('FEMA data have been already added. Please check in the legend');
      _mapViewAvailable().map.remove(__fema);
      this.setState({ btnText: 'View Flooding Data (FEMA)' });
    } else {
      // console.log(config);
      loadModules(['esri/layers/MapImageLayer']).then(([MapImageLayer]) => {
        const layer = new MapImageLayer({
          url: this.props.config.FEMA_GIS_MAPSERVIVE,
          id: 'fema_flooding',
          title: 'Flooding Data (FEMA)',
          visible: false,
          opacity: 0.5,
          sublayers: [
            { id: 3, title: 'FIRM Panels' },
            { id: 14, title: 'Cross-Sections' },
            { id: 27, title: 'Flood Hazard Boundaries' },
            { id: 28, title: 'Flood Hazard Zones' },
          ],
        });
        _mapViewAvailable().map.add(layer, 1);
        this.setState({ btnText: 'Remove Flooding Data (FEMA)' });
      });
    }
  };

  render() {
    const _marginBottom = {
      position: 'absolute',
      top: '55px',
      bottom: '55px',
      width: '100%',
      overflow: 'auto',
    };
    const _btnFloodingOnDemand = {
      position: 'absolute',
      bottom: '10px',
      left: '10px',
      right: '10px',
    };
    return (
      <Fragment>
        <div style={_marginBottom}>
          <div id="lagendWrapper"></div>
        </div>
        <div style={_btnFloodingOnDemand}>
          <Button fullWidth onClick={this.loadFEMAData}>
            {this.state.btnText}
          </Button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
export default connect(mapStateToProps)(Legend);
