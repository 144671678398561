
import React, { Component } from 'react';
import GoogleStreetview from './GoogleStreetview';

const _header = {
    display:'block',
    position:'relative',
    backgroundColor: '#009688',
    color: '#fff',
    padding: '10px'
}
const _contentWrapper = {

}
const _btnClose = {
    position: 'absolute',
    width: '40px',
    height: '38px',
    top:' 0',
    right: '0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}
class GStreetView extends Component {
    constructor(){
        super()
        this.state = {
            isLoading: true,
            _location: null
        }
    }

    componentDidMount = () => {
        //console.log('component mounted...', this.props);
    }
    
    __closePanel = () => {
        this.props.onclose();
    }

    render() {
        return (
            <div>
                <div style={_header}>
                    <h3 style={{margin:'0'}}>Street View</h3>
                    <div id="btn-close-street-view" style={_btnClose} onClick={this.__closePanel} title="Close">X</div>
                </div>
                <div style={_contentWrapper}>
                    <div id="gmap-view">
                        <GoogleStreetview streetViewPanoramaOptions={this.props.streetViewPanoramaOptions} apiKey={this.props.googleAPIKey} />
                    </div>
                </div>

            </div>
        )
    }
    
};

export default GStreetView;

