import React, { Component, Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';
import TextField from 'calcite-react/TextField';
import Checkbox from 'calcite-react/Checkbox';

import './login.css';
import { _user_auth } from '../services/gis_service';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',
      remember_me: false,
      isSubmitting: false,
      isIncorectSignin: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    //this._isMounted = true;
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  _signIn = () => {
    const { login, password, remember_me } = this.state;
    if (login === '' || password === '') {
    } else {
      this.setState({ isSubmitting: true });
      _user_auth.signin3(login, password).then((isAuthorise) => {  /* DMM 10/3/2022 signin, signin2, signin3 */
        if (isAuthorise) {
          const { history } = this.props;
          if (history) {
            history.push('/main');
          }
        } else {
          // incorect credentials
          this.setState({ isIncorectSignin: false });
        }

        this.setState({ isSubmitting: false });
      });
      // call sigin api
      //console.log(this.state);
    }
  };

  render() {
    const { login, password, remember_me, isIncorectSignin } = this.state;
    return (
      <Fragment>
        <div className="login-form">
          <div className="login">
            <h1>Login to Web GIS</h1>

            <Form>
              <FormControl>
                <FormControlLabel htmlFor="email">Email</FormControlLabel>
                <TextField
                  id="email"
                  name="login"
                  value={login}
                  fullWidth
                  onChange={this.changeHandler}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel htmlFor="password">Password</FormControlLabel>
                <TextField
                  id="pw"
                  name="password"
                  type="password"
                  value={password}
                  fullWidth
                  onChange={this.changeHandler}
                />
              </FormControl>
              {/* <FormControl style={{marginBottom: "5px"}}>
                                <Checkbox onChange={() => this.setState({remember_me: !remember_me})}> Remember me on this computer</Checkbox>
                            </FormControl> */}

              <p
                className="esri-bookmarks__no-bookmarks-description"
                style={{
                  margin: '0',
                  color: 'red',
                  display: isIncorectSignin ? 'block' : 'none',
                }}
              >
                Invalid login attempt.
              </p>

              <ButtonGroup style={{ marginTop: '15px' }}>
                <Button
                  disabled={this.state.isSubmitting}
                  onClick={this._signIn}   /* */
                >
                  {this.state.isSubmitting ? 'Submitting.......' : 'Login'}
                </Button>
                <Button href="/">Return</Button>
              </ButtonGroup>
            </Form>
          </div>

          <div className="login-help">
            <p>
              Forgot your password?
              <a href="https://wo.reynoldsburg.gov/account/forget-password">
                Click here to reset it
              </a>
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
