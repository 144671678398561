/**
 * Performs CRUD operations from GIS backend service
 *
 */

import Axios from 'axios';

// SRC API http request config
export const _gis_backend_baseurl = Axios.create({
  baseURL: 'https://gis.reynoldsburg.gov/api', //'https://gis.mansfieldcity.com/gisapi/api', //  `${window.location.protocol}//${window.location.host}/gisapi/api`, //
});

export const CrudOperations = {
  Create: (Entity, EntityObj) => {
    return _gis_backend_baseurl.post(`/${Entity}`, EntityObj);
  },
  Get: (Entity) => {
    return _gis_backend_baseurl.get(`/${Entity}`);
  },
  GetByID: (Entity, EntityObjID) => {
    return _gis_backend_baseurl.get(`/${Entity}/${EntityObjID}`);
  },
  GetByFID: (Entity, EntityObjID) => {
    return _gis_backend_baseurl.get(`/${Entity}/GetByFID/${EntityObjID}`);
  },
  NotifyFailureExercise: (Entity, EntityObjID) => {
    return _gis_backend_baseurl.get(
      `/${Entity}/NotifyFailureExercise/${EntityObjID}`
    );
  },
  Delete: (Entity, EntityObjID) => {
    return _gis_backend_baseurl.delete(`/${Entity}/${EntityObjID}`);
  },
  Update: (Entity, EntityObj) => {
    return _gis_backend_baseurl.put(`/${Entity}`, EntityObj);
  },
};

export const CurrencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const GlobalFormatter = {
  FormatUSD: (price) => {
    return CurrencyFormater.format(price);
  },
};
