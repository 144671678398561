import React, { Component, Fragment } from 'react';

import ActionBar, {
  Action,
  ActionGroup,
  BottomActionGroup,
} from 'calcite-react/ActionBar';

import Modal from 'calcite-react/Modal';
//import Modal from 'calcite-react';
//import Button, { ButtonGroup } from 'calcite-react/Button';
import Button from 'calcite-react/Button';

import LegendIcon from 'calcite-ui-icons-react/LegendIcon';
//import LayersIcon from 'calcite-ui-icons-react/LayersIcon';

import BookmarkIcon from 'calcite-ui-icons-react/BookmarkIcon';
import BasemapIcon from 'calcite-ui-icons-react/BasemapIcon';
//import CursorMarqueeIcon from 'calcite-ui-icons-react/CursorMarqueeIcon';
import PrintIcon from 'calcite-ui-icons-react/PrintIcon';
import CompareIcon from 'calcite-ui-icons-react/CompareIcon';
import MagnifyingGlassIcon from 'calcite-ui-icons-react/MagnifyingGlassIcon';
import BellIcon from 'calcite-ui-icons-react/BellIcon';
import GearIcon from 'calcite-ui-icons-react/GearIcon';

import InformationIcon from 'calcite-ui-icons-react/InformationIcon';

import * as Features from '../functional/FeauturesLoader';
import { _user_auth } from '../services/gis_service';

export class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      activeAction: '',
      selectedComponent: '',
      componentTitle: '',
      infoModalOpen: false,
      isAuthorized: false,
      accessLevel: 0,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.onToggleCollapse = this.onToggleCollapse.bind(this);
    this.actionSelected = this.actionSelected.bind(this);
  }

  componentDidMount() {
    const __isAuth = _user_auth.isAuthorized();  
    const { accessLevel } = _user_auth.GetAuthUser(); 
    this.setState({ isAuthorized: __isAuth, accessLevel });
  }

  _(domID) {
    return document.getElementById(domID);
  }
  onToggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  __is_left__widget_panel_collapsed() {
    return this._('left-bar-wrapper').classList.contains('hidden');
  }

  OpenWidgetWrapper() {
    this._('left-bar-wrapper').classList.remove('hidden');
  }
  CloseWidgetWrapper = () => {
    this._('left-bar-wrapper').classList.add('hidden');
    this.setState({ activeAction: '' });
    this.setState({ selectedComponent: '' });

    const _mapCompareWidget = this._('btn-clear-map-compare');
    if (_mapCompareWidget) {
      _mapCompareWidget.click();
    }
  };

  actionSelected(activeAction) {
    if (this.__is_left__widget_panel_collapsed()) {
      this.OpenWidgetWrapper();
    }
    if (this.state.selectedComponent === activeAction) {
    } else {
      this.setState({
        selectedComponent: activeAction,
        componentTitle: activeAction,
        activeAction,
      });
    }
  }

  renderSelectedFeature(selectedFeature) {
    if (!selectedFeature) return '';

    const Feature = Features[selectedFeature];

    return <Feature renderSelectedFeature={this.__renderComponent} />;
  }

  __renderComponent(selectedComponent) {
    return this.renderSelectedFeature(selectedComponent);
  }

  __getHeaderTitle = () => {
    // replace camel case component name with space
    return this.state.componentTitle
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1');
  };

  openModal() {
    this.setState({
      infoModalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      infoModalOpen: false,
    });
  }

  _renderAssetSearchFeatures() {
    if (this.state.isAuthorized && this.state.accessLevel > 1) {
      return (
        <Action
          icon={<MagnifyingGlassIcon />}
          onClick={() => this.actionSelected('AssetSearch')}
          active={this.state.activeAction === 'AssetSearch'}
        >
          Asset Search
        </Action>
      );
    }
  }

  _renderAlertFeature() {
    if (this.state.isAuthorized && this.state.accessLevel > 1) {
      return (
        <Action
          icon={<BellIcon />}
          onClick={() => this.actionSelected('Alerts')}
          active={this.state.activeAction === 'Alerts'}
        >
          Alerts
        </Action>
      );
    }
  }

  render() {
    const docsModalZIndex = { zIndex: 1001 };
    const __stylePanelHeader = {
      position: 'relative',
      backgroundColor: '#e0e0e0',
      padding: '15px',
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      borderBottom: '1px solid #f3f3f3',
    };
    const __styleClosePanelBtn = {
      position: 'absolute',
      width: '50px',
      top: '0',
      right: '0',
      height: '100%',
      fontWeight: '100',
      textAlign: 'center',
      cursor: 'pointer',
    };
    const __styleCloseBtnText = {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    };
//    const _sectionNavScroll = {
//      position: 'absolute',
//      top: '0',
//      bottom: '0',
//      width: '100%',
//      overflow: 'auto',
//    };

//    const _sectionNavContent = {
//      position: 'relative',
//   };
    return (
      <Fragment>
        <aside>
          <ActionBar
            dark
            position="start"
            collapsed={this.state.collapsed}
            onToggleCollapse={this.onToggleCollapse}
          >
            <ActionGroup>
              <Action
                onClick={() => this.actionSelected('Legend')}
                active={this.state.activeAction === 'Legend'}
                icon={<LegendIcon />}
              >
                Legend
              </Action>
            </ActionGroup>

            <ActionGroup>
              <Action
                icon={<BookmarkIcon />}
                onClick={() => this.actionSelected('Bookmarks')}
                active={this.state.activeAction === 'Bookmarks'}
              >
                Bookmarks
              </Action>
              <Action
                icon={<BasemapIcon />}
                onClick={() => this.actionSelected('Basemaps')}
                active={this.state.activeAction === 'Basemaps'}
              >
                Basemaps
              </Action>

              {/* <Action
                icon={<CursorMarqueeIcon />}
                onClick={() => this.actionSelected('Drawings')}
                active={this.state.activeAction === 'Drawings'}
                indicator
              >
                Drawings
              </Action> */}
              <Action
                icon={<PrintIcon />}
                onClick={() => this.actionSelected('Print')}
                active={this.state.activeAction === 'Print'}
              >
                Print
              </Action>
            </ActionGroup>

            <ActionGroup>
              <Action
                icon={<CompareIcon />}
                onClick={() => this.actionSelected('MapCompare')}
                active={this.state.activeAction === 'MapCompare'}
              >
                Map Compare
              </Action>

              {this._renderAssetSearchFeatures()}
{/*              {this._renderAlertFeature()}  */}
            </ActionGroup>

            <ActionGroup>
              <Action
                icon={<GearIcon />}
                onClick={() => this.actionSelected('Settings')}
                active={this.state.activeAction === 'Settings'}
              >
                Settings
              </Action>
            </ActionGroup>

            <BottomActionGroup>
              <Action icon={<InformationIcon />} onClick={this.openModal}>
                Disclaimer
              </Action>
            </BottomActionGroup>
          </ActionBar>
        </aside>

        <aside style={{ position: 'relative', backgroundColor: '#f3f3f3' }}>
          <div
            id="left-bar-wrapper"
            style={{ width: '300px' }}
            className="hidden"
          >
            <div className="panel-header" style={__stylePanelHeader}>
              <span id="left-panel-header-title">
                {this.__getHeaderTitle()}
              </span>
              <div
                id="btnCloseRightPanel"
                title="close"
                style={__styleClosePanelBtn}
                onClick={this.CloseWidgetWrapper}
              >
                <div style={__styleCloseBtnText}>X</div>
              </div>
            </div>
            <div>{this.__renderComponent(this.state.selectedComponent)}</div>
          </div>
        </aside>

        <div>
          <Modal
            open={this.state.infoModalOpen}
            onRequestClose={this.closeModal}
            appElement={document.body}
            overlayStyle={docsModalZIndex}
            title="Legal Disclaimer"
            actions={[
              <Button key="ok" onClick={this.closeModal}>
                OK
              </Button>,
            ]}
          >
            <p>
              The user agrees to the following terms and conditions associated
              with the use of any data from this website. The data contained on
              this website are for the most part acquired from the County GIS
              auditor site and are made available as a public service and for
              reference purposes only.
            </p>
            <p>
              The data are provided on an “AS IS” basis without any warranty,
              whether implied or expressed. While our office utilizes its best
              efforts to provide accurate data and mapping information, accuracy
              and completeness are not guaranteed. The information and data are
              not intended to serve as a substitute for the services provided by
              the county auditor.
            </p>
            <p>
              The user affirms that the use of this data will be strictly
              limited to governmental purposes. The user affirms that the data
              for the City or County will not be released or provided to any
              other entity without the prior written approval of the City.
            </p>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default LeftNav;
