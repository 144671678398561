import React, { Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';

import SearchIcon from 'calcite-ui-icons-react/SearchIcon';
import LayerHideIcon from 'calcite-ui-icons-react/LayerHideIcon';
import MeasureIcon from 'calcite-ui-icons-react/MeasureIcon';
import PointIcon from 'calcite-ui-icons-react/PointIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import AddInNewIcon from 'calcite-ui-icons-react/AddInNewIcon';
import HideEmptyIcon from 'calcite-ui-icons-react/HideEmptyIcon';
import MagnifyingGlassPlusIcon from 'calcite-ui-icons-react/MagnifyingGlassPlusIcon';
import RecentIcon from 'calcite-ui-icons-react/RecentIcon';

import Attachments from './Attachments';
import LayerAttributes from './LayerAttributes';

const IdentifySkeleton = () => {
    const __stylePanelHeader = {
        position: 'relative',
        backgroundColor: '#f5f5f5',
        padding: '15px',
        textAlign: 'left',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        borderBottom: "1px solid #ddd"
    }
    const __styleClosePanelBtn = {
        position: 'absolute',
        width: '25px',
        top: '0',
        right: '0',
        height: '100%',
        fontWeight: '100',
        textAlign: 'center',
        cursor: 'pointer'
    }
    const __styleCloseBtnText = {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)'
    }
    
    const __styleLayerSettingsBtn = {
        position: 'absolute',
        width: '25px',
        top: '0',
        right: '30px',
        height: '100%',
        fontWeight: '100',
        textAlign: 'center',
        cursor: 'pointer',
    }
    const _identifyFooterToolbar = {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
        backgroundColor: '#f5f5f5',
        padding:'10px',
        textAlign: 'left',
        borderTop: '1px solid #ddd'
    }
    return (
        <Fragment>
            <div id="identifyResults">
                          <div className="panel-header" style={__stylePanelHeader}>
                              <span id="left-panel-header-title">Fetching data...</span>
                              <div id="btnLayerSettings" style={__styleLayerSettingsBtn}>
                              <div style={__styleCloseBtnText} title="Show/Hide fields">
                                <HideEmptyIcon size={16} />
                              </div>
                              </div>
                              <div id="btnCloseRightPanel" style={__styleClosePanelBtn}>
                                  <div style={__styleCloseBtnText} title="Close">X</div>
                              </div>
                              <div>

                              </div>
                          </div>
                          <div id="layerInfoContentDisplay" className="identify-content-scroller">
                                <div id="layer-content-wrapper" style={{margin:'5px'}}>
                                   <div className="skeleton-qht7bjhjdt3"></div>
                                   <div className="skeleton-7mff80nzses"></div>
                                </div>
                          </div>
                          <div id="layerFooterDisplay" style={_identifyFooterToolbar}>
                                <div id="footer-skeleton"></div>
                          </div>
                        </div>
        </Fragment>
    )
}

export default IdentifySkeleton;
