import React, { Component, Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';
import TextField from 'calcite-react/TextField';
import Select from 'calcite-react/Select';
import Menu, { MenuTitle, MenuItem } from 'calcite-react/Menu';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';

import swal from 'sweetalert';
import './add-form.css';
import { _flow_rate_updater, _gis_service } from '../services/gis_service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CrudOperations } from '../services/CRUDoperations.service';

const Entity = 'HydrantFlowTest';

const __stylePanelHeader = {
  position: 'relative',
  height: '45px',
  backgroundColor: '#f5f5f5',
  padding: '15px',
  textAlign: 'left',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderBottom: '1px solid #ddd',
};
const _identifyFooterToolbar = {
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: '#f5f5f5',
  padding: '10px',
  textAlign: 'left',
  borderTop: '1px solid #ddd',
};
const __styleClosePanelBtn = {
  position: 'absolute',
  width: '25px',
  top: '0',
  right: '0',
  height: '45px',
  fontWeight: '100',
  textAlign: 'center',
  cursor: 'pointer',
};
const __styleCloseBtnText = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
};

const _text_style = {
  fontSize: '.8rem',
};

const _text_info = {
  fontSize: '0.7rem',
  fontWeight: '300',
  margin: '0',
};

class AddNewFlowTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      datePickerFocused: false,
      isSubmitting: false,
      inspector: 'ENG',
      flow: 0,
      sp: 0,
      rp: 0,
      comments: '',
      condition: 'Choose',
      operable: 'true',
      paint: 'false',
      marker: 'false',
      oil: 'false',
      drain: 'false',
      flushingYear: '1',
      ownedBy: 'City of Reynoldsburg',
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(item, value) {
    this.setState({
      [item]: value,
    });
  }

  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeWidgetIdentifyWindow = () => {
    swal({
      text: `You are about to cancel recording a new flow test. is this correct?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        document.getElementById('right-bar-wrapper').classList.add('hidden');
        /* const btnIdentify = document.getElementById('mapIdentifyBtn');
                  if(btnIdentify.classList.contains('map-identify-active')){
                      btnIdentify.classList.remove('map-identify-active');
                      document.getElementById("map-view-container").style.cursor = "default";
                  } */
      }
    });
  };

  __convertToBoolean = (val) => {
    return val === 'true' ? true : false;
  };

  _save = async () => {
    if (
      this.state.flow === 0 &&
      this.state.sp === 0 &&
      this.state.rp === 0 &&
      this.state.condition === 'Choose'
    ) {
      swal(
        'Please provide the flow rate, the static pressure, the residual pressure and the hydrant condition'
      );
    } else {
      this.setState({
        isSubmitting: true,
      });
      const __newFlowTest = {
        facilitykey: this.props.FACILITYID,
        inspector: this.state.inspector,
        testdatetime: new Date(this.state.date),
        flow: parseFloat(this.state.flow),
        staticpressure: parseFloat(this.state.sp),
        residualpressure: parseFloat(this.state.rp),
        condition: this.state.condition,
        operhard: false,
        operable: this.__convertToBoolean(this.state.operable),
        paint: this.__convertToBoolean(this.state.paint),
        marker: this.__convertToBoolean(this.state.marker),
        oil: this.__convertToBoolean(this.state.oil),
        drain: this.__convertToBoolean(this.state.drain),
        comment: this.state.comments,
        flushingYear: this.state.flushingYear,
        ownedBy: this.state.ownedBy,
      };

      CrudOperations.Create(Entity, __newFlowTest)
        .then((res) => {
          // update the flow test
          const { hydrantFlayer } = this.props.config.managedFlayerConfig;
          _flow_rate_updater
            .updateHydrantFlowRate(this.props.FACILITYID, hydrantFlayer)
            .then((new_flow_rate) => {
              this.setState({
                isSubmitting: false,
              });
              document
                .getElementById('right-bar-wrapper')
                .classList.add('hidden');
              swal(
                'Flow test saved successfully. You may pan the map around to reflect the new symbol. Thank you'
              );
            })
            .catch((error) =>
              console.log(
                'Error updating flow rate on hydrant feature service :: ',
                error
              )
            );
        })
        .catch((err) => {
          console.log('Error adding new flow test :: ', err);
          swal('There was an error adding this flow test ');
        });
    }
  };

  render() {
    const {
      date,
      inspector,
      flow,
      sp,
      rp,
      comments,
      condition,
      operable,
      paint,
      marker,
      oil,
      drain,
      flushingYear,
      ownedBy,
    } = this.state;
    return (
      <Fragment>
        <div id="layer-field-settings">
          <div className="panel-header" style={__stylePanelHeader}>
            <span id="left-panel-header-title">
              Hydrant: {this.props.FACILITYID}
            </span>
            <p style={_text_info}>Creating a new flow test.</p>
            <div
              id="btnCloseRightPanel"
              style={__styleClosePanelBtn}
              onClick={this.closeWidgetIdentifyWindow}
            >
              <div style={__styleCloseBtnText} title="Close">
                X
              </div>
            </div>
          </div>
          <div
            id="layerInfoContentDisplay"
            className="identify-content-scroller"
          >
            <div style={{ padding: '10px' }}>
              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>Date</FormControlLabel>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(d) => this.setState({ date: d })}
                    /* showTimeSelect */
                  />
                </FormControl>
                <FormControl className="form-controls" style={{ width: '40%' }}>
                  <FormControlLabel htmlFor="inspector">
                    Inspector
                  </FormControlLabel>
                  <Select
                    id="inspector"
                    name="inspector"
                    selectedValue={inspector}
                    onChange={(val) =>
                      this.handleSelectChange('inspector', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="ENG">ENG</MenuItem>
                    <MenuItem value="MFD">MFD</MenuItem>
                    <MenuItem value="WTR">WTR</MenuItem>
                  </Select>
                </FormControl>
              </Form>
              <Form horizontal>
                <FormControl className="form-controls" style={{ width: '32%' }}>
                  <FormControlLabel>Flow (GPM)*</FormControlLabel>
                  <TextField
                    fullWidth
                    name="flow"
                    value={flow}
                    onChange={this.changeHandler}
                  />
                </FormControl>
                <FormControl className="form-controls" style={{ width: '52%' }}>
                  <FormControlLabel>Static pressure (PSI)*</FormControlLabel>
                  <TextField
                    fullWidth
                    name="sp"
                    value={sp}
                    onChange={this.changeHandler}
                  />
                </FormControl>
              </Form>
              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>Residual pressure (PSI)*</FormControlLabel>
                  <TextField
                    fullWidth
                    name="rp"
                    value={rp}
                    onChange={this.changeHandler}
                  />
                </FormControl>
                <FormControl className="form-controls" style={{ width: '30%' }}>
                  <FormControlLabel>Condition*</FormControlLabel>
                  <Select
                    name="condition"
                    selectedValue={condition}
                    onChange={(val) =>
                      this.handleSelectChange('condition', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Choose">Choose...</MenuItem>
                    <MenuItem value="NEW">NEW</MenuItem>
                    <MenuItem value="GOOD">GOOD</MenuItem>
                    <MenuItem value="FAIR">FAIR</MenuItem>
                    <MenuItem value="POOR">POOR</MenuItem>
                    <MenuItem value="FAILED">FAILED</MenuItem>
                  </Select>
                </FormControl>
              </Form>
              <Form horizontal>
                <FormControl className="form-controls" style={{ width: '45%' }}>
                  <FormControlLabel>Operable</FormControlLabel>
                  <Select
                    name="operable"
                    selectedValue={operable}
                    onChange={(val) => this.handleSelectChange('operable', val)}
                    fullWidth
                  >
                    <MenuItem value="false">FALSE</MenuItem>
                    <MenuItem value="true">TRUE</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls" style={{ width: '47%' }}>
                  <FormControlLabel>Flushing Year</FormControlLabel>
                  <Select
                    name="flushingYear"
                    selectedValue={flushingYear}
                    onChange={(val) =>
                      this.handleSelectChange('flushingYear', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </Select>
                </FormControl>
              </Form>
              <Form>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="comments">
                    Owned By
                  </FormControlLabel>
                  <Select
                    name="ownedBy"
                    selectedValue={ownedBy}
                    onChange={(val) => this.handleSelectChange('ownedBy', val)}
                    fullWidth
                  >
                    <MenuItem value="City of Reynoldsburg">
                      City of Reynoldsburg
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Form>
              {/* <Form horizontal>
                <FormControl className="form-controls" style={{ width: '47%' }}>
                  <FormControlLabel>Paint requested</FormControlLabel>
                  <Select
                    name="paint"
                    selectedValue={paint}
                    onChange={(val) => this.handleSelectChange('paint', val)}
                    fullWidth
                  >
                    <MenuItem value="false">FALSE</MenuItem>
                    <MenuItem value="true">TRUE</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls" style={{ width: '45%' }}>
                  <FormControlLabel>Marker requested</FormControlLabel>
                  <Select
                    name="marker"
                    selectedValue={marker}
                    onChange={(val) => this.handleSelectChange('marker', val)}
                    fullWidth
                  >
                    <MenuItem value="false">FALSE</MenuItem>
                    <MenuItem value="true">TRUE</MenuItem>
                  </Select>
                </FormControl>
              </Form>
              <Form horizontal>
                <FormControl className="form-controls" style={{ width: '60%' }}>
                  <FormControlLabel>Lubrication requested</FormControlLabel>
                  <Select
                    name="oil"
                    selectedValue={oil}
                    onChange={(val) => this.handleSelectChange('oil', val)}
                    fullWidth
                  >
                    <MenuItem value="false">FALSE</MenuItem>
                    <MenuItem value="true">TRUE</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls" style={{ width: '32%' }}>
                  <FormControlLabel>Drain</FormControlLabel>
                  <Select
                    name="drain"
                    selectedValue={drain}
                    onChange={(val) => this.handleSelectChange('drain', val)}
                    fullWidth
                  >
                    <MenuItem value="false">FALSE</MenuItem>
                    <MenuItem value="true">TRUE</MenuItem>
                  </Select>
                </FormControl>
              </Form> */}

              <Form>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="comments">
                    COMMENT
                  </FormControlLabel>
                  <TextField
                    id="comments"
                    name="comments"
                    type="textarea"
                    fullWidth
                    value={comments}
                    onChange={this.changeHandler}
                  />
                  <FormHelperText>
                    provide a comment if necessary
                  </FormHelperText>
                </FormControl>
              </Form>

              <p
                style={{
                  color: 'red',
                  textAlign: 'left',
                  margin: '25px 8px',
                  fontSize: '0.8rem',
                }}
              >
                fields with * are required
              </p>
            </div>
          </div>
          <div id="layerFooterDisplay" style={_identifyFooterToolbar}>
            <ButtonGroup>
              <Button disabled={this.state.isSubmitting} onClick={this._save}>
                {this.state.isSubmitting ? 'Submitting.......' : 'Save'}
              </Button>
              <Button onClick={this.closeWidgetIdentifyWindow}>Cancel</Button>
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddNewFlowTest;
