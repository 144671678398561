/**
 * This is an example component used to showcase authentication
 * @type {Class}
 */

// React
import React, { useState } from 'react';

// Components
import TopNavActionsList from 'calcite-react/TopNav/TopNavActionsList';
import ArcgisAccount from 'calcite-react/ArcgisAccount';
import ArcgisAccountMenuItem from 'calcite-react/ArcgisAccount/ArcgisAccountMenuItem';
import Button from 'calcite-react/Button';
import ComboButton from 'calcite-react/ComboButton';
import Menu, { MenuTitle, MenuItem } from 'calcite-react/Menu';
import Drawer from 'calcite-react/Drawer';

import Alert, { AlertTitle, AlertMessage } from 'calcite-react/Alert';
import swal from 'sweetalert';

import { useHistory } from 'react-router-dom';
import { _user_auth } from './services/gis_service';
import UserAccountBtn from './UserAccountBtn';

// Class
const UserAccount = (props) => {
  const __isAuth = _user_auth.isAuthorized();
  const userName = _user_auth.GetAuthUser();

  // onClick={props.signIn}
  const _history = useHistory();

  const __signout = () => {
    alert('Signing you out!');
  };
  const _navigateTo = (url) => {
    window.open(url, '_blank');
  };

  const showDrawerClicked = (drawerDirection) => {
    this.setState({
      drawerDirection,
      active: true,
    });
  };

  const hideDrawer = () => {
    this.setState({
      active: false,
    });
  };

  const loggedInActionsComponent = (
    <TopNavActionsList>
      <UserAccountBtn userName={userName.email} />
{/*      <UserAccountBtn userName={userName.email.split('@')[0]} />  */}
      {/* <ComboButton clear
        label="GIS Reviewer"
      >
        <Menu>
          <MenuItem onClick={() => _navigateTo("https://gis.mansfieldcity.com/app/tap-valve-search.aspx")}>Tap-valve search</MenuItem>
          <MenuItem onClick={() => _navigateTo("https://gis.mansfieldcity.com/app/node-request.aspx")}>Node request</MenuItem>
          <MenuItem onClick={() => _navigateTo("https://rms.mansfieldcity.com/")}>RMS</MenuItem>
          <MenuItem onClick={() => __signout()}>Signout</MenuItem>
        </Menu>
      </ComboButton> */}
    </TopNavActionsList>
  );
  const signedOutActionsComponent = (
    <TopNavActionsList>
      <Button clear onClick={() => _history.push('/account/login')}>
        Sign In
      </Button>
    </TopNavActionsList>
  );

  let outputComponent = __isAuth
    ? loggedInActionsComponent
    : signedOutActionsComponent;

  return <div>{outputComponent}</div>;
  //return <div>{signedInActionsComponent}</div>;
};

export default UserAccount;
