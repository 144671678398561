
import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

// Initial state
const initialState = {
  graphics: []
}

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function deleteGraphic(id) {
    dispatch({
      type: 'DELETE_GRAPHIC',
      payload: id
    });
  }

  function addGraphic(graphic) {
    dispatch({
      type: 'ADD_GRAPHIC',
      payload: graphic
    });
  }
  function updateGraphic(graphic) {
    dispatch({
      type: 'UPDATE_GRAPHIC',
      payload: graphic
    });
  }

  return (<GlobalContext.Provider value={{
    graphics: state.graphics,
    deleteGraphic,
    addGraphic,
    updateGraphic
  }}>
    {children}
  </GlobalContext.Provider>);
}