
import React, { Component, Fragment } from 'react';
import Search from 'calcite-react/Search';
import { loadModules } from "esri-loader";
import { _mapViewAvailable } from '../../utils/map';
import { connect } from 'react-redux';
import ExclamationMarkTriangleIcon from 'calcite-ui-icons-react/ExclamationMarkTriangleIcon';

import { SearchResultsDisplay } from './SearchResultsDisplay';
//import List, { ListItem, ListHeader, ListItemTitle, ListItemSubtitle} from 'calcite-react/List';
import List from 'calcite-react/List';
import Loader from 'calcite-react/Loader';

var layerIds = [];

class AssetSearch extends Component {
    constructor(){
        super()
        this.state = {
            inputValue: '',
            errorMessage: '',
            searchResults: [],
            mapview: _mapViewAvailable()
        }
        this.searchChanged = this.searchChanged.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.onUserAction = this.onUserAction.bind(this)
    }

    componentDidMount() {
        // get layerSearchIds
        loadModules(['esri/request']).then(([esriRequest]) => {
            const options = {
                query: {
                  f: "json"
                },
                responseType: "json"
              };
            esriRequest(this.props.config.utilitySearch.mapServiceUrl, options)
              .then((res) => {
                  const __layers = res.data.layers;
                  __layers.map((l) => {
                      if(l.parentLayerId > -1) {
                          switch (l.name.toLowerCase()) {
                              case 'flow arrow':
                                  break;
                              default:
                                layerIds.push(l.id)
                                  break;
                          }
                      } 
                  })
                  const _inputSearch = document.getElementById('utility-search');
                 _inputSearch.addEventListener('keyup', (evt) => {
                    if(evt.key.toLowerCase() === 'enter') {
                        // perform search
                        this._doSearch();
                    }
                 })
              })
              .catch((err) => {
                document.getElementById('search-result-display').classList.add('hidden');
                document.getElementById('search-error-display').classList.remove('hidden');
                this.setState({errorMessage: err.message});
              })
            
        })
    }

    __getSearchIds = () => {

    }

    clearSearch = () => {
        this.setState({
          inputValue: '',
          errorMessage: '',
          searchResults: []
        })
        if(!document.getElementById('search-result-display').classList.contains('hidden')) 
            document.getElementById('search-result-display').classList.add('hidden');
        
        if(!document.getElementById('search-error-display').classList.contains('hidden'))
            document.getElementById('search-error-display').classList.add('hidden');

        if(!document.getElementById('search-noresult-display').classList.contains('hidden'))
            document.getElementById('search-noresult-display').classList.add('hidden');
    }

    onUserAction = (inputValue, selectedItemVal) => {
        this.setState({
          inputValue: inputValue
        })
    }

    searchChanged = () => {
        // console.log('testing...');
    }

    _doSearch() {
        if(this.state.inputValue !== '') {
            document.getElementById('search-loader').classList.remove('hidden');
            loadModules([
                'esri/tasks/FindTask',
                'esri/tasks/support/FindParameters'
              ]).then(([FindTask, FindParameters]) => {

                const find = new FindTask({
                    url: this.props.config.utilitySearch.mapServiceUrl
                  });
                  // Set parameters to only query the Counties layer by name
                  const params = new FindParameters({
                    layerIds: layerIds,
                    searchFields: this.props.config.utilitySearch.searchFields,
                    searchText: this.state.inputValue,
                    returnGeometry: true,
                    outSpatialReference: _mapViewAvailable().spatialReference,
                  });

                  find.execute(params).then(this.showResults).catch(this.showError);
                  document.getElementById('search-loader').classList.add('hidden');
              });
        }
      }

      showNoResultsFound = () => {
        document.getElementById('search-result-display').classList.add('hidden');
        document.getElementById('search-error-display').classList.add('hidden');
        document.getElementById('search-noresult-display').classList.remove('hidden');
      }

      showResults = (res) => {
          if(res.results.length > 0){
            document.getElementById('search-error-display').classList.add('hidden');
            document.getElementById('search-noresult-display').classList.add('hidden');
            document.getElementById('search-result-display').classList.remove('hidden');
            this.setState({searchResults: res.results});
          } else {
              this.showNoResultsFound();
          }
      }
      showError = (err) => {
          document.getElementById('search-result-display').classList.add('hidden');
          document.getElementById('search-error-display').classList.remove('hidden');
          this.setState({errorMessage: err.message});
      }

    render() {
        return (
            <Fragment>
                <div style={{margin:'10px'}}>
                    <Search placeholder="Search utilities" id="utility-search"
                        inputValue={this.state.inputValue}
                        fullWidth

                        onChange={this.searchChanged}
                        onUserAction={this.onUserAction}
                        onRequestClear={this.clearSearch}
                        />
                </div>
                <div style={resultScrollWrapper}>
                    <div id="search-loader" className="hidden" style={_searchLoader}>
                        <Loader text="Searching assets..." />
                    </div>
                    <div id="search-result-display" className="hidden">
                        <List>
                            {
                                this.state.searchResults.map((f, index) => 
                                (<SearchResultsDisplay key={index} resultObj={f} index={index} />))
                            }
                        </List>
                    </div>
                    <div id="search-error-display" className="hidden" style={{color: "#e40a0a"}}>
                        <span><ExclamationMarkTriangleIcon /></span>
                        <div style={__styleError}>
                            There was an error fetching the search service ::
                            <div>
                                {this.state.errorMessage}
                            </div>
                        </div>
                    </div>
                    <div id="search-noresult-display" className="hidden">
                        <span><ExclamationMarkTriangleIcon /></span>
                        <div style={__styleError}>
                            No result found matching your search
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
};
const __styleError = {
    margin: "10px",
    textAlign: "left"
}
const resultScrollWrapper = {
    position: "absolute",
    top: "120px",
    bottom: "0",
    width: "100%",
    overflow: "auto"
}

const _searchLoader = {
    margin:"15px 25px"
}
const mapStateToProps = (state) => {
    return {
      config: state.config
    };
  };

export default connect(mapStateToProps)(AssetSearch);

