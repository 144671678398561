import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { loadModules } from 'esri-loader';
import { _mapViewAvailable } from '../../utils/map';
import ReactDOM from 'react-dom';
import { saveAs } from 'file-saver/dist/FileSaver';
import FooterParcelsNotification from '../navigation/FooterParcelsNotification';
import { setgraphics } from '../../redux/reducers/graphics';

var SelectedParcelsArray;
var ParcelsMapServer;

export const ParcelsReport = {
  SetSelectedParcels: (parcelsArray) => {
    SelectedParcelsArray = parcelsArray;
  },
  SelectParcelsByGeometry: (geometry) => {
    //console.log(geometry);
    loadModules(['esri/layers/FeatureLayer', 'esri/Graphic']).then(
      ([FeatureLayer, Graphic]) => {
        const flayer = new FeatureLayer({
          // URL to the service
          url:
            'https://gis.reynoldsburg.gov/arcgis/rest/services/WebGIS/Base/MapServer/9',
        });
        const query = flayer.createQuery();
        query.geometry = geometry;
        query.spatialRelationship = 'intersects';
        query.returnGeometry = true;
        query.outFields = ['*'];

        flayer
          .queryFeatures(query)
          .then(ParcelsReport.HighlightSelectedParcels)
          .catch((err) => console.log(`Error :: `, err));
      }
    );
  },
  GenerateBuffer: (geometry, bufferDistance, isSelectParcel = true) => {
    loadModules(['esri/geometry/geometryEngine', 'esri/Graphic']).then(
      ([geometryEngine, Graphic]) => {
        const mapview = _mapViewAvailable();
        const bufferGraphicsLayer = mapview.map.findLayerById(
          'bufferGraphicsLayer'
        );

        bufferGraphicsLayer.removeAll();

        var bufferPolySym = {
          type: 'simple-fill', // autocasts as new SimpleFillSymbol()
          color: [140, 140, 222, 0.5],
          outline: {
            color: [0, 0, 0, 0.5],
            width: 2,
          },
        };
        const parcelBuffer = geometryEngine.buffer(
          geometry,
          bufferDistance,
          'feet'
        );
        bufferGraphicsLayer.add(
          new Graphic({
            geometry: parcelBuffer,
            symbol: bufferPolySym,
          })
        );

        if (isSelectParcel) {
          ParcelsReport.SelectParcelsByGeometry(parcelBuffer);
        }
      }
    );
  },
  HighlightSelectedParcels: (results) => {
    loadModules(['esri/Graphic']).then(([Graphic]) => {
      const __intersect__parcels__features = [];
      const mapview = _mapViewAvailable();
      const parcelsNotificationGLayer = mapview.map.findLayerById(
        'publicNotification'
      );

      parcelsNotificationGLayer.removeAll();

      if (results.features.length > 0) {
        results.features.map((feature) => {
          switch (feature.attributes.PARCELID) {
            case '':
              break;

            default:
              // add to map
              const fill_symbol = {
                type: 'simple-fill',
                outline: { width: 2, color: [197, 0, 255, 1] },
                color: [223, 115, 255, 0.25],
              };

              const __gr = new Graphic({
                geometry: feature.geometry,
                symbol: fill_symbol,
              });
              parcelsNotificationGLayer.add(__gr);
              __intersect__parcels__features.push(feature);
              break;
          }
        });

        SelectedParcelsArray = __intersect__parcels__features;

        ParcelsReport.ShowSelectedParcels();
        setTimeout(() => {
          ReactDOM.render(
            <FooterParcelsNotification
              SelectedParcelsArray={SelectedParcelsArray}
              view={mapview}
            />,
            document.getElementById('parcel-notification')
          );
        }, 100);
      }
    });
  },
  DownloadSelectedParcels: () => {},
  ShowSelectedParcels: () => {
    const __panel = document.getElementById('parcel-notification');
    if (__panel.classList.contains('hidden')) {
      __panel.classList.remove('hidden');
    }
  },
  CloseParcelsTable: () => {
    const __panel = document.getElementById('parcel-notification');
    if (__panel.classList.contains('hidden')) {
    } else {
      __panel.classList.add('hidden');
    }
  },
  DownloadFile: (data, reportName = 'GIS_parcels_alerts_report') => {
    // console.log(data);
    const replacer = (key, value) => (value === null ? 'Not available' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, `${reportName}.csv`);
  },
};

/* export const __reduxStore = {
  setGraphics: (view) => {
    const dispatch = useDispatch();
    const graphicsLayer = view.map.findLayerById("userGraphics");
    console.log(graphicsLayer);
    dispatch(setgraphics(graphicsLayer.graphics.items));
  }
} */
