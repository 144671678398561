import React, { Component, Fragment } from 'react';
import Button, { ButtonGroup } from 'calcite-react/Button';
import swal from 'sweetalert';
import './add-form.css';
import { _flow_rate_updater, _gis_service } from '../services/gis_service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from 'calcite-react/TextField';
import Select from 'calcite-react/Select';
import Menu, { MenuTitle, MenuItem } from 'calcite-react/Menu';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';
import { CrudOperations } from '../services/CRUDoperations.service';

const __stylePanelHeader = {
  position: 'relative',
  height: '45px',
  backgroundColor: '#f5f5f5',
  padding: '15px',
  textAlign: 'left',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  borderBottom: '1px solid #ddd',
};
const _identifyFooterToolbar = {
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  backgroundColor: '#f5f5f5',
  padding: '10px',
  textAlign: 'left',
  borderTop: '1px solid #ddd',
};
const __styleClosePanelBtn = {
  position: 'absolute',
  width: '25px',
  top: '0',
  right: '0',
  height: '45px',
  fontWeight: '100',
  textAlign: 'center',
  cursor: 'pointer',
};
const __styleCloseBtnText = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
};

const _text_style = {
  fontSize: '.8rem',
};

const _text_info = {
  fontSize: '0.7rem',
  fontWeight: '300',
  margin: '0',
};

const Entity = 'WaterValveExercising';

class AddNewValveExercise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      isSubmitting: false,
      opendirection: 'Unknown',
      turnstoclose: 'Unknown',
      operable: 'Yes',
      programyear: 'Unassigned',
      comment: '',
      inspector: 'ENG',
      result: 'Choose',
      location: '',
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(item, value) {
    this.setState({
      [item]: value,
    });
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeWidgetIdentifyWindow = () => {
    swal({
      text: `You are about to cancel recording a new valve exercise. is this correct?`,
      closeOnClickOutside: false,
      buttons: { cancel: 'NO', confirm: 'YES' },
      dangerMode: true,
    }).then((action) => {
      if (action) {
        document.getElementById('right-bar-wrapper').classList.add('hidden');
      }
    });
  };

  __convertToBoolean = (val) => {
    return val === 'Yes' ? true : false;
  };

  showSavedMessage = (message) => {
    this.setState({
      isSubmitting: false,
    });
    document.getElementById('right-bar-wrapper').classList.add('hidden');
    swal(message);
  };
  _save = async () => {
    if (
      this.state.opendirection === 'Unknown' ||
      this.state.turnstoclose === 'Unknown' ||
      this.state.programyear === 'Unassigned' ||
      this.state.result === 'Choose'
    ) {
      swal('Please provide the required fileds');
    } else {
      this.setState({
        isSubmitting: true,
      });

      const _exercise = {
        facilityid: this.props.FACILITYID,
        opendirection: this.state.opendirection,
        turnstoclose: this.state.turnstoclose,
        programyear: this.state.programyear,
        location: this.state.location,
        operable: this.__convertToBoolean(this.state.operable),
        excercisingdatetime: new Date(this.state.date),
        inspector: this.state.inspector,
        result: this.state.result,
        comment: this.state.comment,
      };

      const { valveFlayer } = this.props.config.managedFlayerConfig;

      CrudOperations.Create(Entity, _exercise)
        .then((_result) => {
          _flow_rate_updater
            .updateSystemValve(this.props.FACILITYID, valveFlayer)
            .then((resultValues) => {
              if (this.state.result === 'Failed') {
                // notify the Office of faillure
                const { watervalveexercisingid } = _result.data;
                // notify the Office of faillure
                CrudOperations.NotifyFailureExercise(
                  Entity,
                  watervalveexercisingid
                )
                  .then((noti) => {
                    this.showSavedMessage(
                      'Exercise record saved successfully. A failure notification has been sent to the office. Thank you'
                    );
                  })
                  .catch((notificationError) => {
                    console.log(notificationError);
                    swal(
                      'There was an error sending failure email notification'
                    );
                  });
              } else {
                this.showSavedMessage(
                  'Exercise record saved successfully. Thank you'
                );
              }
            });
        })
        .catch((err) => {
          console.log('Error adding new exercise :: ', err);
          swal(
            'There was an error adding this exercise record. Please try again'
          );
        });
    }
  };
  render() {
    const {
      date,
      opendirection,
      turnstoclose,
      operable,
      programyear,
      comment,
      inspector,
      result,
      location,
    } = this.state;
    return (
      <Fragment>
        <div id="layer-field-settings">
          <div className="panel-header" style={__stylePanelHeader}>
            <span id="left-panel-header-title">
              Valve Exercise: {this.props.FACILITYID}
            </span>
            <p style={_text_info}>Creating a new valve exercise.</p>
            <div
              id="btnCloseRightPanel"
              style={__styleClosePanelBtn}
              onClick={this.closeWidgetIdentifyWindow}
            >
              <div style={__styleCloseBtnText} title="Close">
                X
              </div>
            </div>
          </div>
          <div
            id="layerInfoContentDisplay"
            className="identify-content-scroller"
          >
            <div style={{ padding: '10px' }}>
              <Form horizontal>
                <FormControl className="form-controls">
                  <FormControlLabel>Date</FormControlLabel>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(d) => this.setState({ date: d })}
                    /* showTimeSelect */
                  />
                </FormControl>
                <FormControl className="form-controls" style={{ width: '46%' }}>
                  <FormControlLabel htmlFor="inspector">
                    Inspector
                  </FormControlLabel>
                  <Select
                    id="inspector"
                    name="inspector"
                    selectedValue={inspector}
                    onChange={(val) =>
                      this.handleSelectChange('inspector', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Choose">Choose...</MenuItem>
                    <MenuItem value="ENG">ENG</MenuItem>
                    <MenuItem value="MFD">MFD</MenuItem>
                    <MenuItem value="WTR">WTR</MenuItem>
                  </Select>
                </FormControl>
              </Form>
              <Form horizontal>
                <FormControl className="form-controls" style={{ width: '47%' }}>
                  <FormControlLabel>Open Direction*</FormControlLabel>
                  <Select
                    id="opendirection"
                    name="opendirection"
                    selectedValue={opendirection}
                    onChange={(val) =>
                      this.handleSelectChange('opendirection', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Unknown">Unknown</MenuItem>
                    <MenuItem value="Left">Left</MenuItem>
                    <MenuItem value="Right">Right</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls" style={{ width: '45%' }}>
                  <FormControlLabel htmlFor="inspector">
                    Turns to close*
                  </FormControlLabel>
                  <TextField
                    name="turnstoclose"
                    value={turnstoclose}
                    onChange={this.changeHandler}
                  />
                </FormControl>
              </Form>
              <Form horizontal>
                <FormControl className="form-controls" style={{ width: '40%' }}>
                  <FormControlLabel>Operable</FormControlLabel>
                  <Select
                    id="operable"
                    name="operable"
                    selectedValue={operable}
                    onChange={(val) => this.handleSelectChange('operable', val)}
                    fullWidth
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className="form-controls" style={{ width: '52%' }}>
                  <FormControlLabel htmlFor="inspector">
                    Program Year*
                  </FormControlLabel>
                  <Select
                    id="programyear"
                    name="programyear"
                    selectedValue={programyear}
                    onChange={(val) =>
                      this.handleSelectChange('programyear', val)
                    }
                    fullWidth
                  >
                    <MenuItem value="Unassigned">Unassigned</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </Select>
                </FormControl>
              </Form>

              <Form>
                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="result">Result*</FormControlLabel>
                  <Select
                    id="result"
                    name="result"
                    selectedValue={result}
                    onChange={(val) => this.handleSelectChange('result', val)}
                    fullWidth
                  >
                    <MenuItem value="Choose">Choose...</MenuItem>
                    <MenuItem value="Failed">Failed</MenuItem>
                    <MenuItem value="Passed">Passed</MenuItem>
                  </Select>
                </FormControl>

                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="comments">
                    Comment
                  </FormControlLabel>
                  <TextField
                    id="comments"
                    name="comment"
                    type="textarea"
                    fullWidth
                    value={comment}
                    onChange={this.changeHandler}
                  />
                  {/* <FormHelperText>provide a comment if necessary</FormHelperText> */}
                </FormControl>

                <FormControl className="form-controls">
                  <FormControlLabel htmlFor="location">
                    Location
                  </FormControlLabel>
                  <TextField
                    id="location"
                    name="location"
                    type="textarea"
                    fullWidth
                    value={location}
                    onChange={this.changeHandler}
                  />
                  <FormHelperText>
                    Specify a location if necessary
                  </FormHelperText>
                </FormControl>
              </Form>

              <p
                style={{
                  color: 'red',
                  textAlign: 'left',
                  margin: '25px 8px',
                  fontSize: '0.8rem',
                }}
              >
                fields with * are required
              </p>
            </div>
          </div>
          <div id="layerFooterDisplay" style={_identifyFooterToolbar}>
            <ButtonGroup>
              <Button disabled={this.state.isSubmitting} onClick={this._save}>
                {this.state.isSubmitting
                  ? 'Submitting.......'
                  : 'Save Exercise'}
              </Button>
              <Button onClick={this.closeWidgetIdentifyWindow}>Cancel</Button>
            </ButtonGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddNewValveExercise;
