import React, { Component, Fragment } from 'react';
import { loadModules } from 'esri-loader';
import { GlobalContext } from '../../../redux/context/GlobalState';
import { getAppConfig } from '../../../utils/request';

import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';
import Accordion, {
  AccordionSection,
  AccordionTitle,
  AccordionContent,
} from 'calcite-react/Accordion';
import TextField from 'calcite-react/TextField';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Checkbox from 'calcite-react/Checkbox';
import Card, { CardTitle, CardContent, CardImage } from 'calcite-react/Card';
import LayerZoomToIcon from 'calcite-ui-icons-react/LayerZoomToIcon';
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import PencilIcon from 'calcite-ui-icons-react/PencilIcon';
import TableIcon from 'calcite-ui-icons-react/TableIcon';
import { ParcelsReport } from '../../services/notifications';
import BufferSelectParcels from './BufferSelectParcels';

const DRAWINGS_KEY = 'arcgis-local-drawings';

class DrawingItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      graphic: this.props.drawingObj,
      userGLayer: this.props.graphicsLayer,
      activeSectionIndexes: [-1],
      bufferDistance: 100,
      bufferAndSelectParcels: true,
    };
    this.onAccordionChange = this.onAccordionChange.bind(this);
  }

  onAccordionChange(evt, index) {
    this.state.activeSectionIndexes.includes(index)
      ? this.setState({
          activeSectionIndexes: this.state.activeSectionIndexes.filter(
            (item) => index !== item
          ),
        })
      : this.setState({
          activeSectionIndexes: [...this.state.activeSectionIndexes, index],
        });
  }

  componentDidMount() {}

  __toggleGraphicVisibility(e) {
    this.setState({ isVisible: e.target.checked });

    setTimeout(() => {
      console.log(this.state.graphic);
      //this.props.drawingObj.visible = this.state.isVisible;
      const userGLayer = this.props.mapview.map.findLayerById('userGraphics');
      if (userGLayer) {
        userGLayer.graphics.items.map((g, idx) => {
          console.log(g.uid);
          if (g.uid === this.state.graphic.uid) {
            g.visible = this.state.isVisible;
          }
        });
      }
    }, 30);
  }
  __zoomToGraphic() {
    switch (this.state.graphic.geometry.type) {
      case 'point':
        this.props.mapview.goTo({
          target: this.state.graphic.geometry,
          zoom: 22,
        });
        break;
      default:
        this.props.mapview.goTo({
          target: this.state.graphic.geometry,
        });
        break;
    }
  }

  __deleteGraphic() {
    const gLayer = this.props.mapview.map.findLayerById('userGraphics');
    try {
      if (gLayer) {
        gLayer.graphics.items.map((g, idx) => {
          //console.log(g.uid);
          if (g.uid === this.state.graphic.uid) {
            gLayer.remove(g);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
    //this.props.deleteSingleGraphic(this.state.graphic);

  }

  _saveUserDrawingsItems() {
    const userDrawings = this.state.userGLayer.graphics.items;
    if (userDrawings.length > 0) {
      loadModules(['esri/Graphic']).then(([Graphic]) => {
        if (localStorage.getItem(DRAWINGS_KEY) !== undefined) {
          localStorage.removeItem(DRAWINGS_KEY);
        }
        const rawGraphics = [];
        this.state.userGLayer.graphics.items.map((graphic) => {
          const _gr = new Graphic({
            geometry: graphic.geometry,
            symbol: graphic.symbol,
            attributes: graphic.attributes,
          });
          rawGraphics.push(_gr.toJSON());
        });
        localStorage.setItem(DRAWINGS_KEY, JSON.stringify(rawGraphics));
      });
    } else {
      if (localStorage.getItem(DRAWINGS_KEY)) {
        localStorage.removeItem(DRAWINGS_KEY);
      }
    }
  }

  SelectParcels = (geometry = this.state.graphic.geometry) => {
    ParcelsReport.SelectParcelsByGeometry(geometry);
  };

  /* CreateBuffer = (geometry) => {
    const { geometry } = this.state.graphic;
    ParcelsReport.GenerateBuffer(
      geometry,
      this.state.bufferDistance,
      this.state.bufferAndSelectParcels
    );
  }; */

  render() {
    return (
      <Fragment>
        <Card bar="green" style={{ margin: '5px', flex: '1 1 20%' }}>
          <CardContent>
            
            <div className='text-center'>
              <div className='graphic-item-index'>{this.props.index +1}</div>
            </div>

            <Checkbox
              name="graphics-visibility-controller"
              defaultChecked
              onChange={(e) => this.__toggleGraphicVisibility(e)}
            >
              {' '}
              Visibility{' '}
            </Checkbox>
            <div style={{ marginTop: '15px', marginBottom: '10px' }}>
              <ButtonGroup>
                <Button
                  extraSmall
                  onClick={() => this.__zoomToGraphic()}
                  title="Zoom to"
                >
                  <LayerZoomToIcon />
                </Button>
                <Button
                  extraSmall
                  red
                  onClick={() => this.__deleteGraphic()}
                  title="delete graphic"
                >
                  <TrashIcon />
                </Button>
                <Button
                  extraSmall
                  onClick={() => this.SelectParcels()}
                  title="Select Parcels Intersecting shape"
                >
                  <TableIcon />
                </Button>
              </ButtonGroup>
            </div>

            {/* Parcels Buffering */}
            <calcite-accordion selection-mode="single">
              <calcite-accordion-item item-subtitle="Buffering">
                <BufferSelectParcels graphic={this.state.graphic} />
              </calcite-accordion-item>
            </calcite-accordion>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

export default DrawingItem;
