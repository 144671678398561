
import React, { Component, Fragment } from 'react'
import { _mapView } from '../../utils/map';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Select from 'calcite-react/Select';
import Menu, { MenuTitle, MenuItem } from 'calcite-react/Menu';
import { loadModules } from "esri-loader";
import { _mapViewAvailable } from '../../utils/map';

import { connect } from 'react-redux';

class MapCompare extends Component {

    state = {
        leadingServiceUrl: '',
        trailingServiceUrl: '',
        leadingLayer: null,
        trailingLayer: null,
        layerSwipe: null
    }

    componentDidMount() {
        const view = _mapViewAvailable();
        this.setState({leadingServiceUrl: view.map.basemap.baseLayers.items[0].url});
    }
    componentWillUnmount () {
        this.setState = (state, callback) => {
            return;
        }
        this.__destroySwipe();
    }
    __destroySwipe = () => {
        if(this.state.layerSwipe){
            _mapViewAvailable().map.remove(this.state.leadingLayer);
            _mapViewAvailable().map.remove(this.state.trailingLayer);
            this.state.layerSwipe.destroy();
            this.setState({layerSwipe: null});
            this.setState({leadingLayer: null});
            this.setState({trailingLayer: null});
        }
    }

    __compareImageries = () => {
        this.__destroySwipe();

        loadModules([
            'esri/widgets/Swipe',
            'esri/layers/TileLayer'
          ]).then(([Swipe, TileLayer]) => {
      
            const __ll = new TileLayer({
                url: this.state.leadingServiceUrl
            });

            this.setState({leadingLayer: __ll});

            const __tt = new TileLayer({
                url: this.state.trailingServiceUrl
              });

            this.setState({trailingLayer: __tt});

            _mapViewAvailable().map.add(this.state.leadingLayer, 1);
            _mapViewAvailable().map.add(this.state.trailingLayer, 1);

            const __swipe = new Swipe({
                leadingLayers: [this.state.leadingLayer],
                trailingLayers: [this.state.trailingLayer],
                position: 45,
                view: _mapViewAvailable()
              });

            this.setState({layerSwipe: __swipe});

            _mapViewAvailable().ui.add(this.state.layerSwipe);
          });
        
    }

    setLeadingLayer = (layer) => {
        this.setState({leadingServiceUrl: layer});
    }
    setTrailingLayer = (layer) => {
        this.setState({trailingServiceUrl: layer});
    }

    render() {
        return (
            <Fragment>
            <h4>Compare historic imageries</h4>

            <div style={{margin: '20px 10px'}}>
                <label htmlFor="leadingYear">Select leading imagery</label>
                <Select fullWidth 
                    onChange={(value) => this.setLeadingLayer(value)}
                    selectedValue={this.state.leadingServiceUrl}
                    >
                        {
                            this.props.config.listImageries.map((img, index) => (
                                <MenuItem value={img.url} key={index}>{img.caption}</MenuItem>
                            ))
                        }
                </Select>
                <br />
                <label htmlFor="trailingYear">Select trailing imagery</label>
                <Select fullWidth
                    onChange={(value) => this.setTrailingLayer(value)}
                    selectedValue={this.state.trailingServiceUrl}
                    >
                   {
                        this.props.config.listImageries.map((img, index) => (
                            <MenuItem value={img.url} key={index}>{img.caption}</MenuItem>
                        ))
                    }
                </Select>
            </div>

            <ButtonGroup>
                    <Button onClick={this.__compareImageries}>Compare</Button>
                    <Button id="btn-clear-map-compare" onClick={this.__destroySwipe}>Clear</Button>
            </ButtonGroup>

        </Fragment>
        )
    }
};
const mapStateToProps = (state) => {
    return {
      config: state.config
    };
  };
export default connect(mapStateToProps)(MapCompare);

