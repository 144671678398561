import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Panel, { PanelTitle, PanelText } from 'calcite-react/Panel';

import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Fieldset,
  Legend,
} from 'calcite-react/Form';
import TextField from 'calcite-react/TextField';
import Tabs, {
  TabNav,
  TabTitle,
  TabContents,
  TabSection,
} from 'calcite-react/Tabs';
import { SketchPicker, CompactPicker } from 'react-color';

import Switch from 'calcite-react/Switch';
import Button, { ButtonGroup } from 'calcite-react/Button';
import Checkbox from 'calcite-react/Checkbox';
import Radio from 'calcite-react/Radio';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import LayerSettingsCheckbox from './LayerSettingsCheckbox';
import { _user_auth } from '../services/gis_service';

const USER_THEME_KEY = 'user-local-theme';
const __IDENTIFYSETTINGS = '__identifySettings';
let savedLayersArray = [];

const _scrollableWrapper = {
  position: 'absolute',
  top: '55px',
  bottom: '0',
  width: '100%',
  overflow: 'auto',
};
const _scrollableContentWrapper = {
  margin: '0',
  padding: '0',
};

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerBackground: '#efe9e0',
      headerTextColor: '#ffffff',
      headerLinksColor: '#808080',
      activeTabIndex: 0,
      activateMapOnLoad: true,
      showNullOrEmptyFields: false,
      isAllLayersChecked: true,
      checkboxes: [],
      checkedItems: new Map(),
      __isAuthorized: false,
    };
    this.onTabChange = this.onTabChange.bind(this);
    this.onMapLoadChange = this.onMapLoadChange.bind(this);
    this.onShowOrEmptyFieldsLoadChange =
      this.onShowOrEmptyFieldsLoadChange.bind(this);
    this.onAllLayersChecked = this.onAllLayersChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));

    setTimeout(() => {
      let Keys = [...this.state.checkedItems.entries()]
        .filter(({ 1: v }) => v === true)
        .map(([k]) => k);

      if (Keys.length > 0) {
        if (Keys.length === this.props.config.applicationLayers.length) {
          this.setState({
            isAllLayersChecked: true,
            checkedItems: new Map(),
          });
        } else {
          this.setState({ isAllLayersChecked: false });
        }
      } else {
        this.setState({ isAllLayersChecked: true });
      }
    }, 20);
  }

  componentDidMount() {
    this.getAppLayersCheckboxes();

    savedLayersArray = [];
    const userTheme = localStorage.getItem(USER_THEME_KEY);
    if (userTheme) {
      const _theme = JSON.parse(userTheme);
      this.setState({
        headerBackground: _theme.headerBackgroundColor,
        headerTextColor: _theme.headerTitleTextColor,
        headerLinksColor: _theme.headerNavLinksColor,
      });
    }
    const _identifySettings = localStorage.getItem(__IDENTIFYSETTINGS);
    if (_identifySettings) {
      const __settings = JSON.parse(_identifySettings);
      if (__settings.Layers.length > 0) {
        setTimeout(() => {
          let _inputs = document.querySelectorAll('.layer-input-label');
          __settings.Layers.map((l) => {
            for (let index = 0; index < _inputs.length; index++) {
              const element = _inputs[index];
              if (element.innerText === l) {
                element.click();
              }
            }
          });
        }, 30);
      }
      this.setState({
        activateMapOnLoad: __settings.ActivateMapOnLoad,
        showNullOrEmptyFields: __settings.IsNullOrEmptyFields,
      });
    }
  }

  getAppLayersCheckboxes() {
    let _checkboxes = [];
    this.props.config.applicationLayers.map((l, index) => {
      switch (l.id) {
        case '1703a318545-contours':  //HARDCODED
          break;
        case '1703a0495d2-util':    //HARDCODED  DMM 10/11/2022
          const __isAuthorized = _user_auth.isAuthorized();
          const __AuthorizedUser = _user_auth.GetAuthUser();
          if (__isAuthorized && __AuthorizedUser.accessLevel > 1) {
            _checkboxes.push({
              name: l.title,
              key: l.id,
              label: l.title,
            });
          }
          break;  
        default:
          _checkboxes.push({
            name: l.title,
            key: l.id,
            label: l.title,
          });
          break;
      }
    });

    this.setState({ checkboxes: _checkboxes });
  }

  onAllLayersChecked(evt) {
    this.setState({ isAllLayersChecked: evt.target.checked });
    if (evt.target.checked) {
      this.setState({ checkedItems: new Map() });
    } else {
      let Keys = [...this.state.checkedItems.entries()]
        .filter(({ 1: v }) => v === true)
        .map(([k]) => k);

      if (Keys.length < 1) {
        swal('You must have at least one option selected');
        this.setState({ isAllLayersChecked: true });
      }
    }
  }

  onTabChange(index) {
    this.setState({ activeTabIndex: index });
  }
  onMapLoadChange(evt) {
    this.setState({ activateMapOnLoad: evt.target.checked });
  }
  onShowOrEmptyFieldsLoadChange(evt) {
    this.setState({ showNullOrEmptyFields: evt.target.checked });
  }
  handleBackgroundChangeComplete = (color) => {
    const _header = document.querySelector('.app-header');
    _header.style.backgroundColor = color.hex;
    this.setState({ headerBackground: color.hex });
  };
  handleTextColorChangeComplete = (color) => {
    const _headerTitle = document.querySelector('a.app-title');
    const _headerToggler = document.querySelector('.utility-toggler-wrapper');

    if (_headerTitle || _headerToggler) {
      _headerTitle.style.color = color.hex;
      if (_headerToggler) {
        _headerToggler.style.color = color.hex;
        _headerToggler.style.borderColor = color.hex;
      }
      this.setState({ headerTextColor: color.hex });
    }
  };
  handleLinksColorChangeComplete = (color) => {
    const _header = document.querySelector('a.app-links');
    if (_header) {
      _header.style.color = color.hex;
      this.setState({ headerLinksColor: color.hex });
    }
  };
  _saveMyPreferences = () => {
    let _selectedLayers = [];

    let Keys = [...this.state.checkedItems.entries()]
      .filter(({ 1: v }) => v === true)
      .map(([k]) => k);

    if (Keys.length > 0) {
      _selectedLayers = Keys;
    }

    const IdentifySettings = {
      Layers: _selectedLayers,
      IsNullOrEmptyFields: this.state.showNullOrEmptyFields,
      ActivateMapOnLoad: this.state.activateMapOnLoad,
    };
    //console.log(IdentifySettings);
    const keyExist = localStorage.getItem(__IDENTIFYSETTINGS);
    if (keyExist) {
      localStorage.removeItem(__IDENTIFYSETTINGS);
    }
    localStorage.setItem(__IDENTIFYSETTINGS, JSON.stringify(IdentifySettings));
    swal('Settings saved successfully!');
  };

  _resetToDefault = () => {
    const keyExist = localStorage.getItem(__IDENTIFYSETTINGS);
    if (keyExist) {
      localStorage.removeItem(__IDENTIFYSETTINGS);
    }

    this.setState({
      showNullOrEmptyFields: false,
      activateMapOnLoad: true,
      isAllLayersChecked: true,
      checkedItems: new Map(),
    });

    const identifyBtn = document.getElementById('mapIdentifyBtn');
    if (identifyBtn.classList.contains('map-identify-active')) {
      identifyBtn.click();
    }
    swal('Identify settings were reset successfully!');
  };

  _saveTheme = () => {
    const themeObj = {
      headerBackgroundColor: this.state.headerBackground,
      headerTitleTextColor: this.state.headerTextColor,
      headerNavLinksColor: this.state.headerLinksColor,
    };
    const keyExist = localStorage.getItem(USER_THEME_KEY);
    if (keyExist) {
      localStorage.removeItem(USER_THEME_KEY);
    }
    localStorage.setItem(USER_THEME_KEY, JSON.stringify(themeObj));
    swal('Theme saved successfully!');
  };

  render() {
    return (
      <Fragment>
        <div style={_scrollableWrapper}>
          <div style={_scrollableContentWrapper}>
            <div style={{ marginBottom: '15px' }}>
              <Panel style={{ margin: '0', padding: '0' }}>
                <PanelTitle>Theme</PanelTitle>
                <div style={{ width: '100%', textAlign: 'left' }}>
                  <Tabs
                    onTabChange={this.onTabChange}
                    activeTabIndex={this.state.activeTabIndex}
                  >
                    <TabNav>
                      <TabTitle>Header Background</TabTitle>
                      <TabTitle>Header Text</TabTitle>
                      {/* <TabTitle>Links</TabTitle> */}
                    </TabNav>
                    <TabContents>
                      <TabSection>
                        <PanelText>Background color:</PanelText>
                        <CompactPicker
                          color={this.state.headerBackground}
                          onChangeComplete={this.handleBackgroundChangeComplete}
                        />
                      </TabSection>
                      <TabSection>
                        <PanelText>Header text color:</PanelText>
                        <CompactPicker
                          color={this.state.headerTextColor}
                          onChangeComplete={this.handleTextColorChangeComplete}
                        />
                      </TabSection>
                      {/* <TabSection>
                                        <PanelText>
                                                Links color:
                                                </PanelText>
                                                <CompactPicker
                                                        color={ this.state.headerTextColor }
                                                        onChangeComplete={ this.handleLinksColorChangeComplete }
                                                />
                                    </TabSection> */}
                    </TabContents>
                  </Tabs>
                </div>
                <div style={{ margin: '15px auto' }}>
                  <Button onClick={() => this._saveTheme()}>Save theme</Button>
                </div>
              </Panel>
            </div>
            <Panel style={{ padding: '15px 5px' }}>
              <PanelTitle>Identify</PanelTitle>
              <PanelText>
                <Switch
                  fullWidth
                  labelPosition="before"
                  checked={this.state.activateMapOnLoad}
                  onChange={this.onMapLoadChange}
                >
                  Activate on map load.
                </Switch>
              </PanelText>
              <PanelText>
                <Switch
                  fullWidth
                  labelPosition="before"
                  checked={this.state.showNullOrEmptyFields}
                  onChange={this.onShowOrEmptyFieldsLoadChange}
                >
                  Show null or empty fields.
                </Switch>
              </PanelText>

              <PanelTitle>Layers to identify</PanelTitle>
              <div style={{ width: '100%', textAlign: 'left' }}>
                <FormControl>
                  <label>
                    <input
                      type="checkbox"
                      name="ALL LAYERS"
                      checked={this.state.isAllLayersChecked}
                      onChange={this.onAllLayersChecked}
                    />
                    ALL LAYERS
                  </label>
                  {this.state.checkboxes.map((item) => (
                    <label key={item.key} className="layer-input-label">
                      <LayerSettingsCheckbox
                        name={item.name}
                        checked={this.state.checkedItems.get(item.name)}
                        onChange={this.handleChange}
                      />
                      {item.name}
                    </label>
                  ))}
                </FormControl>
              </div>
              <ButtonGroup>
                <Button green onClick={this._saveMyPreferences}>
                  Save
                </Button>
                <Button
                  onClick={this._resetToDefault}
                  style={{ marginLeft: '5px' }}
                >
                  Reset to Default
                </Button>
              </ButtonGroup>
            </Panel>

            {/* <div style={{marginTop: '15px'}}>
                            <Panel>
                                <PanelTitle>Identify Display</PanelTitle>
                                <div style={{width:'100%', textAlign:'left'}}>
                                <FormControl>
                                    <Fieldset name="fieldDisplayType">
                                        <Legend>Specify the display type:</Legend>
                                        <Radio checked>Field names</Radio>
                                        <Radio>Aliases</Radio>
                                    </Fieldset>
                                </FormControl>
                                </div>
                                <Button onClick={() => console.log('saving layers to identify...')}>Save Display</Button>
                            </Panel>
                        </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};
export default connect(mapStateToProps)(Settings);
