import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ParcelsReport } from '../services/notifications';
import Button, { ButtonGroup } from 'calcite-react/Button';
import DownloadToIcon from 'calcite-ui-icons-react/DownloadToIcon';

const __panelWrapper = {
  height: '355px',
  backgroundColor: '#fff',
};

class FooterParcelsNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parcels: [],
    };
  }

  componentDidMount() {
    //console.log(this.props);
    const { SelectedParcelsArray } = this.props;
    if (SelectedParcelsArray.length > 0) {
      let parcels = [];
      SelectedParcelsArray.map((p) => {
        parcels.push(p.attributes);
      });

      this.setState({ parcels });
      //console.log(this.state.parcels);
    }
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  downloadDataToExcel = () => {
    ParcelsReport.DownloadFile(this.state.parcels, 'GIS_parcels_alerts_report');
  };
  CloseReport = () => {
    ParcelsReport.CloseParcelsTable();
  };

  render() {
    const { SelectedParcelsArray } = this.props;
    const columns = [
      {
        Header: 'PARCELID',
        accessor: 'PID',
      },
      {
        Header: 'ADDRESS',
        accessor: 'Address',
      },
      {
        Header: 'OWNER1',
        accessor: 'Owner1',
      },
      {
        Header: 'OWNER2',
        accessor: 'Owner2',
      },
    ];
    return (
      <Fragment>
        <div style={__panelWrapper}>
          <div className="main-draggable-content">
            <div
              className="center-draggable-header"
              style={{ cursor: 'default !important' }}
            >
              <h3>
                <span></span> Selected Parcels: {SelectedParcelsArray.length}
                <span
                  style={{
                    marginLeft: '15px',
                    visibility:
                      this.props.SelectedParcelsArray.length > 0
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  <Button
                    onClick={this.downloadDataToExcel}
                    icon={<DownloadToIcon size={16} />}
                  >
                    Download
                  </Button>
                </span>
              </h3>
              <div
                className="btn-close-draggable-wrapper"
                title="close"
                onClick={this.CloseReport}
              >
                X
              </div>
            </div>
            <ReactTable
              columns={columns}
              data={this.state.parcels}
              defaultPageSize={5}
              style={{
                height: '300px', // This will force the table body to overflow and scroll, since there is not enough room
              }}
            ></ReactTable>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FooterParcelsNotification;
